import * as React from "react"

import { Button,
    Container,
    Header,
    Input,
    InputOnChangeData,
    List,
    Segment,
    Table,
    GridColumn,
    Grid,
    Icon,
} from "semantic-ui-react";

import { NumberInput } from "../../../inputs/NumberInput"
import { GeometryMetaData, Point } from "react-authentication"

import GeometryDrawer from "./GeometryDrawer"

const headerStyle = {
    marginTop: '20px',
}

const segmentStyle = {
    marginBottom: '50px',
}

interface GeometryBlueprintsEditorIncomingProps {
    //Add a function to update the simulation
    uploadGeometryItem: (geom: GeometryMetaData) => any;

    //Pass in the geometry data from App State
    geomData: GeometryMetaData;
}

interface GeometryBlueprintsEditorState {

    //Store the edit version
    editCopy?: GeometryMetaData

    gridSpacing: number

    highlightedPoint: number

}

// const defaultCreatedGeometry: GeometryMetaData = {
//     name: "Blank",
//     id: 0,
//     notes: "",
//     blueprints: {points: [{x: 0, y: 0}, {x: 1, y: 0}, {x: 1, y: 1}, {x: 0, y: 1}] as Point[], res_long: 0.1, res_radial: 0.01, slices: 10} as Blueprints
// }

/**
 * This component is in charge up synchronizing GeometryBlueprintsEditor with application state and the GeometryDrawer
 */
class GeometryBlueprintsEditor extends React.Component<GeometryBlueprintsEditorIncomingProps, GeometryBlueprintsEditorState> {
    state = { editCopy: undefined, gridSpacing: 0.25, highlightedPoint: -1 }

    constructor(props: GeometryBlueprintsEditorIncomingProps) {
        super(props)

        this.state.gridSpacing = this.calculateInitalGridSpacing();
    }


    //Add a button to submit case
    saveChanges = () => {
        if(this.state.editCopy !== undefined) {
            // let submitCopy = JSON.parse(JSON.stringify(this.props.geomData));
            let submitCopy = JSON.parse(JSON.stringify(this.state.editCopy!));

            //TODO: Need to clear the inlets for any simulation that uses this geometry!

            this.props.uploadGeometryItem(submitCopy);
        }

        //And cancel (basically set the editCopy back to undefined)
        this.cancelChanges()
    }

    updateBlueprintsPoint = (newBlueprint: Point, index: number) => {
        //Get copy from local state
        let { editCopy } = this.state

        if (editCopy !== undefined) {
            (editCopy as unknown as GeometryMetaData).blueprints!.points[index] = newBlueprint
        }

        this.setState( {editCopy: editCopy} )
    }

    updateBlueprintsResLong = (newValue: number) => {
        //Get copy from local state
        let { editCopy } = this.state

        if (editCopy !== undefined) {
            (editCopy as unknown as GeometryMetaData).blueprints!.res_long = newValue
        }

        this.setState( {editCopy: editCopy} )
    }

    updateBlueprintsResRadial = (newValue: number) => {
        //Get copy from local state
        let { editCopy } = this.state

        if (editCopy !== undefined) {
            (editCopy as unknown as GeometryMetaData).blueprints!.res_radial = newValue
        }

        this.setState( {editCopy: editCopy} )
    }

    updateBlueprintsNumberSlices = (newValue: number) => {
        //Get copy from local state
        let { editCopy } = this.state

        if (editCopy !== undefined) {
            (editCopy as unknown as GeometryMetaData).blueprints!.slices = newValue
        }

        this.setState( {editCopy: editCopy} )
    }

    calculateInitalGridSpacing = () => {

        let myPoints = JSON.parse(JSON.stringify(this.props.geomData!.blueprints!.points));


        let Xs:number[] = myPoints.map((value: Point) => {return  value.x});
        let Ys:number[] = myPoints.map((value: Point) => {return  -value.y});


        let largestX:number = Math.max(...Xs);
        let smallestX:number = Math.min(...Xs);
        let largestY:number = Math.max(...Ys);
        let smallestY:number = Math.min(...Ys);

        let absSumOfY:number = Math.abs(largestY) + Math.abs(smallestY);
        let absSumOfX:number = Math.abs(largestX) + Math.abs(smallestX);

        let newGridSpacing = ((absSumOfY > absSumOfX)?absSumOfY:absSumOfX)/10;
        return parseFloat(newGridSpacing.toFixed(2)); //will round after two decimal places.
    }

    updateGridSpacing = (newSpacing: number) => {
        // Need to make sure this is a positive number
        // TODO: make more intelligent with units in future
        if (newSpacing < 0.001 || isNaN(newSpacing)) {
            newSpacing = 0.001
        }
        this.setState( {gridSpacing: newSpacing} );
    }

    setHighlightedPoint = (index: number) => {
        this.setState( {highlightedPoint: index} );
    }

    cancelChanges = () => {
        this.setState({editCopy:undefined})
    }

    enterEdit = () => {
        //Set the edit mode
        this.setState({editCopy: JSON.parse(JSON.stringify(this.props.geomData))})
    }

    // if no index is supplied it just adds it to the end
    addPoint = (index?: number) => {
        // Get a copy of the editState
        let { editCopy } = JSON.parse(JSON.stringify(this.state));

        // Get a copy of the last point and next point if available
        if (editCopy !== undefined) {
            let lastPoint = {x: 0, y: 0};
            let nextPoint = {x: 0, y: 0};
            let newPoint  = {x: 0, y: 0};
            if ((editCopy as GeometryMetaData).blueprints!.points.length > 0) { // Check if there are any points
                if (index !== undefined && index < ((editCopy as GeometryMetaData).blueprints!.points.length - 1)) { // See if the point has two neighbors
                    lastPoint = JSON.parse(JSON.stringify((editCopy as GeometryMetaData).blueprints!.points[index]));
                    nextPoint = JSON.parse(JSON.stringify((editCopy as GeometryMetaData).blueprints!.points[index + 1]));
                } else { // Only has previous point or no index specified so get last point
                    lastPoint = JSON.parse(JSON.stringify((editCopy as GeometryMetaData).blueprints!.points.slice(-1).pop()));
                    nextPoint = JSON.parse(JSON.stringify(lastPoint));
                    nextPoint.y = nextPoint.y + 2*this.state.gridSpacing;
                }
                if (lastPoint !== undefined && nextPoint !== undefined) {
                    newPoint.x = (lastPoint.x + nextPoint.x) / 2.0;
                    newPoint.y = (lastPoint.y + nextPoint.y) / 2.0;
                }
            }
            if (index !== undefined && index < ((editCopy as GeometryMetaData).blueprints!.points.length - 1)) {
                (editCopy as GeometryMetaData).blueprints!.points.splice(index + 1, 0, newPoint);
            } else {
                (editCopy as GeometryMetaData).blueprints!.points.push(newPoint);
            }
        }
        this.setState( {editCopy: editCopy} );
    }

    removePoint = (index: number) => {
        // Get a copy of the editState
        let { editCopy } = JSON.parse(JSON.stringify(this.state))

        // Remove point at the index
        if (editCopy !== undefined) {
            (editCopy as GeometryMetaData).blueprints!.points.splice(index, 1)
        }

        this.setState( {editCopy: editCopy} )
    }


    render(): React.ReactNode {

        // Get the application state deep copy
        let geom = JSON.parse(JSON.stringify(this.props.geomData))

        // if a local edit copy exists, use it instead
        if (this.state.editCopy !== undefined)
            geom = JSON.parse(JSON.stringify(this.state.editCopy!))

        //See if this is editable
        const isEditable = this.state.editCopy !== undefined;

        // const panes = [
        //     { menuItem: 'Tab 1', pane: ( <Tab.Pane key='tab1'>
        //                         {(geom.blueprints) && <GeometryDrawer blueprints={geom.blueprints.points}
        //                                                gridSpacing={this.state.gridSpacing}
        //                                                blueprintUpdater={this.updateBlueprintsPoint}
        //                                                isEditable={isEditable}
        //                                                setHighlightedPoint={this.setHighlightedPoint}
        //                                     />}
        //                                  </Tab.Pane>
        //         )},
        //     { menuItem: 'Tab 2', pane: ( <Tab.Pane key='tab2'><div>{this.props.children}</div></Tab.Pane> )},
        // ]

        //Now return the table
        return (
            <Container>
                <Header as='h2' style={headerStyle}>Geometry Creator</Header>

                <Grid columns={2} stackable>
                    <GridColumn width={"eight"}>
                        <Segment style={segmentStyle}>
                            <List>
                                <List.Item>
                                    <Input label='Units'
                                           fluid
                                           value={'meters'}
                                           disabled={!isEditable}
                                        // onChange={(v, data:InputOnChangeData) => this.updateBlueprintsPoint({ x: parseFloat(data.value),
                                        //                                                                 y: point.y },
                                        //                                                                 index)
                                        // }
                                    />
                                </List.Item>
                                <List.Item>
                                    <NumberInput label='Grid Spacing'
                                                 fluid
                                                 units='m'
                                                 min={0.01}
                                                 step={0.01}
                                                 value={this.state.gridSpacing}
                                                 // disabled={!isEditable}
                                                 onChange={(v, data:InputOnChangeData) => this.updateGridSpacing(parseFloat(data.value))}
                                    />
                                </List.Item>
                                <List.Item>
                                    <NumberInput label='Vertical Resolution'
                                                 fluid
                                                 units='m'
                                                 min={0.01}
                                                 step={0.01}
                                                 value={geom.blueprints.res_long}
                                                 disabled={!isEditable}
                                                 onChange={(v, data:InputOnChangeData) => this.updateBlueprintsResLong(parseFloat(data.value))}
                                    />
                                </List.Item>
                                <List.Item>
                                    <NumberInput label='Radial Resolution'
                                                 fluid
                                                 units='m'
                                                 min={0.01}
                                                 step={0.01}
                                                 value={geom.blueprints.res_radial}
                                                 disabled={!isEditable}
                                                 onChange={(v, data:InputOnChangeData) => this.updateBlueprintsResRadial(parseFloat(data.value))}
                                    />
                                </List.Item>
                                <List.Item>
                                    <NumberInput label='Number of Slices'
                                                 fluid
                                                 min={5}
                                                 step={1}
                                                 value={geom.blueprints.slices}
                                                 disabled={!isEditable}
                                                 onChange={(v, data:InputOnChangeData) => this.updateBlueprintsNumberSlices(parseFloat(data.value))}
                                    />
                                </List.Item>
                            </List>
                            <Table celled>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell width={2}>{'Point'}</Table.HeaderCell>
                                        <Table.HeaderCell>{'x (m)'}</Table.HeaderCell>
                                        <Table.HeaderCell>{'y (m)'}</Table.HeaderCell>
                                        {isEditable &&
                                        <Table.HeaderCell width={1}/>
                                        }
                                        {isEditable &&
                                        <Table.HeaderCell width={1}/>
                                        }
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {/*Add in component*/}
                                    {geom.blueprints.points.map((point: Point, index: number) => {
                                        //Return a new table row
                                        return (
                                            <Table.Row key={index} active={index === this.state.highlightedPoint}>
                                                <Table.Cell>{index + 1}</Table.Cell>
                                                <Table.Cell>
                                                    <NumberInput
                                                        fluid
                                                        min={0}
                                                        step={0.1}
                                                        value={point.x || 0}
                                                        disabled={!isEditable}
                                                        onChange={(v, data:InputOnChangeData) => this.updateBlueprintsPoint({ x: parseFloat(data.value),
                                                                y: point.y },
                                                            index)
                                                        }
                                                    />
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <NumberInput
                                                        fluid
                                                        min={0}
                                                        step={0.1}
                                                        value={point.y || 0}
                                                        disabled={!isEditable}
                                                        onChange={(v, data:InputOnChangeData) => this.updateBlueprintsPoint({ x: point.x,
                                                                y: parseFloat(data.value) },
                                                            index)
                                                        }
                                                    />
                                                </Table.Cell>
                                                {isEditable &&
                                                <Table.Cell>
                                                    <Icon
                                                        style={{cursor: 'pointer'}}
                                                        name='cancel'
                                                        onClick={() => this.removePoint(index)}
                                                    />
                                                </Table.Cell>
                                                }
                                                {isEditable &&
                                                <Table.Cell>
                                                    <Icon
                                                        style={{cursor: 'pointer'}}
                                                        name='plus'
                                                        onClick={() => this.addPoint(index)}
                                                    />
                                                </Table.Cell>
                                                }
                                            </Table.Row>
                                        );
                                    })}

                                </Table.Body>
                            </Table>
                            {/*Add a button bar to switch between the modes*/}
                            <Button.Group>
                                {isEditable &&
                                <Button secondary onClick={this.cancelChanges}>Cancel</Button>
                                }
                                {isEditable &&
                                <Button primary onClick={this.saveChanges}>Save Changes</Button>
                                }
                                {/* {isEditable &&
                                <Button onClick={() => this.addPoint()}>Add Point</Button>
                                } */}
                                {!isEditable &&
                                <Button  onClick={this.enterEdit}>Edit</Button>
                                }
                            </Button.Group>
                        </Segment>
                    </GridColumn>

                    <GridColumn width={"eight"}>

                        <Header as='h3'>2D Profile Editor</Header>
                        {(geom.blueprints) &&
                        <GeometryDrawer blueprints={geom.blueprints.points}
                                        gridSpacing={this.state.gridSpacing}
                                        blueprintUpdater={this.updateBlueprintsPoint}
                                        isEditable={isEditable}
                                        setHighlightedPoint={this.setHighlightedPoint}
                        />}

                        {this.props.children}

                    </GridColumn>
                </Grid>

            </Container>
        )

    }

}

export default GeometryBlueprintsEditor
