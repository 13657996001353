import {SimulationPageProps} from "../SimulationPageProps";
import * as React from "react";
import {Container, Grid, Header, Icon, Input, InputOnChangeData, Label, Segment, Table} from "semantic-ui-react";

interface Props extends SimulationPageProps{

}

//Keep a very simple state for the name
interface SummaryState {
    nameEdit?:string
}

class SimulationSummary extends React.Component<Props, SummaryState> {
    state = {nameEdit: undefined }

    // A constructor is called to make changes to null arrays
    constructor(props: Props) {
        super(props);

        // This block changes any null arrays to empty arrays
        let submitCopy = this.props.sim
        //console.log("In constructor")
        const inletExists = ((!Array.isArray(submitCopy.parameters.inlets)) ? 0 : 1)
        if (inletExists == 0){
            {/* Change the null array to an empty array */}
            submitCopy.parameters.inlets = []
            //console.log("Attempting to change null to empty")

            this.props.updateSimulation(submitCopy)
        } else {

            if (this.props.sim.parameters.inlets.length > 0) {
                //console.log("There are ", this.props.sim.parameters.inlets.length, " inlets")
                let changed = 0
                for (let i = 0; i < submitCopy.parameters.inlets.length; i++) {
                    //console.log("Checking inlet #", i)
                    const facesExist = (
                        (!Array.isArray(submitCopy.parameters.inlets[i].selection.faces)) ? 0 : 1)
                    if (facesExist == 0) {
                        {/* Change the null array to an empty array */}
                        submitCopy.parameters.inlets[i].selection.faces = []
                        changed = 1
                    }
                }
                // Only update the simulation if a null array was changed
                if (changed == 1) {
                    //console.log("An inlet was null, updating to fix")
                    this.props.updateSimulation(submitCopy)
                }
            }

        }
    }

    enterEdit =() =>{
        //Set the edit mode
        this.setState({nameEdit:this.props.sim.name});


    }

    saveNameChanges = () =>{
        if(this.state.nameEdit != undefined) {
            let submitCopy = this.props.sim
            submitCopy.name = this.state.nameEdit!;

            this.props.updateSimulation(submitCopy)
        }
        //And cancel
        this.setState({nameEdit:undefined})
    }

    render(): React.ReactNode {
        {/* Check for no inlets so we can display a message later */}
        const inletExists = (((!Array.isArray(this.props.sim.parameters.inlets))
            || (this.props.sim.parameters.inlets.length == 0)) ? 0 : 1)
        const noFaces = <i>No faces selected</i>
        const noComposition = <i>No species composition recorded</i>

        // Too many variables use this
        const monPara = (this.props.sim.parameters.monitorpara)

        // Conversion to usable name
        let variableName = monPara.selVar
        let sanitizedLineName = "";
        switch(variableName) {
            case "CCVelocity":
                sanitizedLineName = "Velocity";
                break;
            case "pressurePS":
                sanitizedLineName = "Pressure";
                break;
            default:
                sanitizedLineName = variableName;
        }

        return (
            <div>
            <Container style={{ padding: '5em 0em' }}>
                {/*If we are not in edit mode*/}
                {!this.state.nameEdit &&
                <Header as='h1'>
                    {this.props.sim.name}
                    <Label>
                        <Icon
                            onClick={this.enterEdit}
                            name='pencil'
                        />
                    </Label>

                </Header>
                }
                {/*And if we are in edit mode*/}
                {this.state.nameEdit &&
                <Header as='h1'>
                    <Input style={{width:"100%"}} value={this.state.nameEdit}
                           onChange={(event: React.ChangeEvent<HTMLInputElement>,
                                      data: InputOnChangeData) => this.setState({nameEdit: data.value})}
                           icon={<Icon name='check' inverted circular link  onClick={this.saveNameChanges}/>}

                    />

                </Header>
                }


                {/* This will print the whole json file to the summary page */}
                {/* JSON.stringify(this.props.sim) */}
                <Segment>
                    <Grid columns={2} stackable>
                    <Grid.Column>
                    <Segment>
                    <Header as='h3' dividing>Geometry Data</Header>
                    <Label>ID: </Label> {this.props.sim.parameters.geometry.data.id} <br/>
                    <Label>Name: </Label> {this.props.sim.parameters.geometry.data.name} <br/>
                    <Label>Notes: </Label> {this.props.sim.parameters.geometry.data.notes} <br/>
                    <Label>Upward Direction: </Label> {this.props.sim.parameters.geometry.upwardDirection} <br/>
                    <Label>Wind Direction: </Label> {this.props.sim.parameters.geometry.windDirection} <br/>
                    <Label>Wind Direction Ratio: </Label> {this.props.sim.parameters.geometry.windDirScaleRatio} <br/>
                    <Label>Elevation Direction Ratio: </Label> {this.props.sim.parameters.geometry.elevatedDirScaleRatio} <br/>
                    <Label>Transverse Direction Ratio: </Label> {this.props.sim.parameters.geometry.transvereDirScaleRatio} <br/>
                    </Segment>
                    </Grid.Column>

                    <Grid.Column>
                    <Segment>
                    <Header as='h3' dividing>Environment</Header>
                    <Label>Wind Speed (m/s): </Label> {this.props.sim.parameters.environment.windSpeed} <br/>
                    <Label>Ambient Temperature (K): </Label> {this.props.sim.parameters.environment.ambientTemperature} <br/>
                    <Label>Barometric Pressure (Pa): </Label> {this.props.sim.parameters.environment.barometerPressure} <br/>
                    <Label>Ambient Humidity (%): </Label> {this.props.sim.parameters.environment.ambientAirHumidity} <br/>
                    </Segment>
                    </Grid.Column>
                    </Grid>

                    <Segment>
                    <Header as='h3' dividing>Inlets</Header>
                    <Grid columns={2} stackable>
                    {((inletExists != 1) ? <i>No inlets created</i> :this.props.sim.parameters.inlets.map((inlet, index) => (
                            <Grid.Column key = {index}>
                            <Header as='h4' attached='top'> {inlet.name ? inlet.name : <i>Unnamed Inlet #{index+1}</i>} </Header>
                            <Segment attached>
                                <Grid columns={2} stackable>
                                    <Grid.Column>
                                        <Label>Flow Rate (kg/s): </Label> {JSON.stringify(inlet.flowRate)}
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Label>Temperature (K): </Label> {JSON.stringify(inlet.temperature)}
                                    </Grid.Column>
                                </Grid>
                                {/* This block outputs unknown species types that add up to 1; i.e. {"CHO2":0.5,"TiAl3":0.5}*/}
                                <Table celled striped collapsing compact='very'>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell colSpan='2'>Composition (mass fraction)</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {(JSON.stringify(inlet.composition)==="{}") ? noComposition :
                                            Object.entries(inlet.composition).map(([elementName, value]) => (
                                                <Table.Row key={elementName}>
                                                    <Table.Cell collapsing><b>{elementName}:</b></Table.Cell>
                                                    <Table.Cell>{value.toFixed(2).toString()}</Table.Cell>
                                                </Table.Row>
                                            ))
                                        }
                                    </Table.Body>
                                </Table>

                                {/* I don't know what goes in this block. It always seems to output "" */}
                                {/*<Label>Composition Type: </Label> {inlet.compositionType} <br/>*/}

                                {/* We are not showing the selected faces at this time (ctrl-/ will uncomment */}
                                {/*/!* Loop over all the selected faces *!/*/}
                                {/*<Header as={'h5'}>Selected Faces:</Header>*/}
                                {/* Loop over all the selected faces */}
                                {/*/!* Check for null array or empty array of faces before displaying *!/*/}
                                {/*{(((!Array.isArray(inlet.selection.faces)) || (inlet.selection.faces.length==0))*/}
                                {/*    ? noFaces : inlet.selection.faces.map((face, i) =>(*/}
                                {/*    <span key={i}>*/}
                                {/*            {JSON.stringify(face)}{(i!=inlet.selection.faces.length-1)? ", " : ""}*/}
                                {/*    </span>*/}
                                {/*)))}*/}
                            </Segment>
                            </Grid.Column>
                    )))}
                    </Grid>
                    </Segment>

                    <Grid columns={2} stackable>
                        <Grid.Column>
                            <Segment>
                                <Header as='h3' dividing>Model Parameters</Header>
                                <Label>Wind Direction Mesh Size: </Label>
                                {this.props.sim.parameters.modelnumpara.windDirScale}<br/>
                                <Label>Elevation Direction Mesh Size: </Label>
                                {this.props.sim.parameters.modelnumpara.elevatedDirScale} <br/>
                                <Label>Transverse Direction Mesh Size: </Label>
                                {this.props.sim.parameters.modelnumpara.transvereDirScale} <br/>
                                <Label>Simulation Convergence Time (s): </Label>
                                {this.props.sim.parameters.modelnumpara.simTimeConverge} <br/>
                                <Label>Saving Data Frequency (s): </Label>
                                {this.props.sim.parameters.modelnumpara.saveDataFreq} <br/>
                            </Segment>
                        </Grid.Column>
                        <Grid.Column>
                            <Segment>
                                <Header as={'h3'} dividing>Monitor Parameters</Header>
                                <Segment>
                                    <Header as={'h5'}>Selected Location</Header>
                                    <Grid columns={2} stackable>
                                        <Grid.Column>
                                            <Label>Beginning (x,y,z):</Label>
                                            ({monPara.beginX},{monPara.beginY},{monPara.beginZ})<br/>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Label>Ending (x,y,z): </Label>
                                            ({monPara.endX},{monPara.endY},{monPara.endZ})<br/>
                                        </Grid.Column>

                                    </Grid>
                                </Segment>
                                <Label>Selected Variable:</Label>
                                {sanitizedLineName}<br/>
                            </Segment>
                        </Grid.Column>
                    </Grid>
                </Segment>
            </Container>
            </div>
        );
    }


}

export default SimulationSummary