import * as React from "react";
import {
    Button,
    DropdownProps,
    Form,
    Icon,
    Image,
    Modal,
} from "semantic-ui-react";
import {Option, ResourceInfo, ResourceSpec} from "../../../models/ResourceInfo";
import ApplicationState from "../../../state/ApplicationState";
import {ThunkDispatch} from "redux-thunk";
import {errorResponse} from "action-alert";
import {connect} from "react-redux";
import {SimulationData} from "../../../models/Simulation";
import {NewSolveData, SolveData} from "../../../models/Solve";
import {solveActions} from "../../../actions/solve.actions";
import {RouteComponentProps, withRouter} from "react-router";


interface LinkProps extends RouteComponentProps<any>{
    //Pass in the simulation
    sim:SimulationData;

    //Store the activeResource ResourceInfo
    activeResource?:ResourceInfo
}

interface DispatchProps {
    errorResponse:(error:any) =>{}
    postNewSolve:(solve:NewSolveData, callBack?:(solve:SolveData) => any) =>any;

}


interface LocalState {
    name: string

    //Store the resource to update
    spec: ResourceSpec


}

/**
 * This card shows the animal details
 */
class NewSolveModal extends React.Component<LinkProps&DispatchProps, LocalState> {

    constructor(props:LinkProps&DispatchProps) {
        super(props);
        this.state = {
            name:"",
            spec:{
                resourceId:this.props.activeResource? this.props.activeResource.resourceId:"",
                selections:{} as { [option: string]: any; }
            }
        } ;
    }


    postNewSolve = () => {
        //Create the new solve
        const newSolve:NewSolveData = {
            simId:this.props.sim.id,
            name:this.state.name,
            resource:this.state.spec

        }


        //Add to the list
        this.props.postNewSolve(newSolve, (sol:SolveData) =>{
            this.props.history.push(`/simulation/${sol.simId}/solve/${sol.id}`)
        });




    }

    OptionComponent = (name: string, opt:Option) =>{
        //Build the form based upon the state
        switch(opt.type){
            case "int":
            case "float":
                return (
                    <Form.Field
                        label={name}
                        control='input'
                        type='number'
                        value={this.state.spec.selections[name]}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            //convert to an int
                            let value = 0.0;

                            //See if we should parse as an int or value
                            if(opt.type === "int") {
                                value = parseInt(event.target.value)
                            }else {
                                value = parseFloat(event.target.value)
                            }

                            //See if there max/min
                            if(opt.maxValue)
                                value = Math.min(value, opt.maxValue)
                            if(opt.minValue)
                                value = Math.max(value, opt.minValue)

                            //Make a copy of the spec
                            let selections = {...this.state.spec.selections} as { [option: string]: any; }
                            selections[name]=value;

                            //Now store it
                            this.setState({
                                ...this.state,
                                spec:{
                                    ...this.state.spec,
                                    selections
                                }
                                }
                            )
                        }
                        }

                    />
                );
            case "string":
                //If it a drop down
                if(opt.selection) {
                    return (
                        <Form.Select
                            label={name}
                            value={this.state.spec.selections[name]}
                            options={opt.selection.map(opt =>{
                                return{
                                    key:opt,
                                    value:opt,
                                    text:opt
                                }
                            })}
                            onChange={({}, data:DropdownProps) => {

                                //Make a copy of the spec
                                let selections = {...this.state.spec.selections} as { [option: string]: any; }
                                selections[name] = data.value

                                //Now store it
                                this.setState({
                                        ...this.state,
                                        spec: {
                                            ...this.state.spec,
                                            selections
                                        }
                                    }
                                )
                            }
                            }

                        />
                    );

                }else{
                    //Just free flow
                    return (
                        <Form.Field
                            label={name}
                            control='input'
                            type='text'
                            value={this.state.spec.selections[name]}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {

                                //Make a copy of the spec
                                let selections = {...this.state.spec.selections} as { [option: string]: any; }
                                selections[name] = event.target.value;

                                //Now store it
                                this.setState({
                                        ...this.state,
                                        spec: {
                                            ...this.state.spec,
                                            selections
                                        }
                                    }
                                )
                            }
                            }

                        />
                    );
                }


        }




    }


    //Get the modal component
    getModalPart(){
        if(this.props.activeResource){
            return(
                <>
                    <Modal.Header>{this.props.activeResource.name}</Modal.Header>
                    <Modal.Content image>
                    <Image wrapped size='medium' src={this.props.activeResource.imgUrl}/>
                        <Modal.Description>
                            <p>{this.props.activeResource.description}</p>

                            <Form>
                                <Form.Field
                                    label="Solve Name"
                                    control='input'
                                    type='text'
                                    value={this.state.name}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        this.setState({
                                            name: event.target.value
                                        })

                                    }
                                    }

                                />

                                {/*Now add everything else they are asking for*/}
                                {Object.keys(this.props.activeResource.options).map(paramKey =>{
                                    if(this.props.activeResource) {
                                        return this.OptionComponent(paramKey, this.props.activeResource.options[paramKey]);
                                    }else {
                                        return undefined;
                                    }
                                })}

                            </Form>


                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            onClick={this.postNewSolve}
                            primary
                        >
                            <Icon name='add' />
                        </Button>
                    </Modal.Actions>
                </>
            );

        }else{
            return null
        }

    }




    render(): React.ReactNode {
        return (
            <Modal trigger={
                <Button disabled={!this.props.activeResource}>New Solve</Button>

            }>
                {this.getModalPart()}

            </Modal>
        );
    }


}

/**
 * Map from the global state to things we need here
 * @param state
 * @param myProps
 * @returns {{authentication: WebAuthentication}}
 */
function mapStateToProps(state:ApplicationState,myProps:LinkProps ):LinkProps {
    return {
        ...myProps
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch<any,any, any>): DispatchProps {
    return {
        errorResponse:(error:any) => dispatch(errorResponse(error)),
        postNewSolve:(solve:NewSolveData,callBack?:(solve:SolveData) => {}) => dispatch(solveActions.addSolve(solve,callBack))
    };

}


const WithoutRotuer =connect (
    mapStateToProps,
    mapDispatchToProps
)(NewSolveModal);


//Wrap with a withRouter so we get the current location
export default withRouter((props:LinkProps) => <WithoutRotuer {...props}/>);

