import { error } from 'action-alert';
import {Action, Dispatch} from 'redux';
import {ThunkAction} from 'redux-thunk';
import {solveService} from "../services/solve.service";
import {NewSolveData, SolveData} from "../models/Solve";


export const solveConstants = {
    UPDATE_SOLVES: 'UPDATE_SOLVES',
    ADD_SOLVE: 'ADD_SOLVE'


};

export const solveActions = {
    updateSolvesForSim,
    addSolve
};


function updateSolvesForSim(simId:number): ThunkAction<any, any,any, any> {
    //Return a function that will be called by dispatch
    return (dispatch:Dispatch<Action>) => {

        //Ask the user service to login
        solveService.getSolvesForSim(simId)
            .then(
                //If successful a user will be returned
                solList  => {
                    //dispatch a login success
                    dispatch({
                        type: solveConstants.UPDATE_SOLVES,
                        payload: {
                            simId: simId,
                            list:solList
                        }
                    });


                },
                //If there was an error, dispatch a login failure and alert the user why
                errorResponse => {

                    //Dispatch the error
                    try {
                        dispatch(error(errorResponse.response.data.message));
                    }catch(e){
                        dispatch(error(errorResponse.toString()));

                    }

                }
            );
    };

}
function addSolve(solve:NewSolveData, callBack?:(solve:SolveData) => {}): ThunkAction<any, any,any, any> {
    //Return a function that will be called by dispatch
    return (dispatch:Dispatch<Action>) => {

        //Ask the user service to login
        solveService.postNewSolve(solve)
            .then(
                //If successful a user will be returned
                solList  => {
                    //dispatch a login success
                    dispatch({
                        type: solveConstants.ADD_SOLVE,
                        payload: solList
                    });

                    //now call back if there is a call back
                    if(callBack){
                        callBack(solList);
                    }


                },
                //If there was an error, dispatch a login failure and alert the user why
                errorResponse => {

                    //Dispatch the error
                    try {
                        dispatch(error(errorResponse.response.data.message));
                    }catch(e){
                        dispatch(error(errorResponse.toString()));

                    }

                }
            );
    };

}


