import React, {Component} from "react";
import {RouteComponentProps} from "react-router";
//import VisusViewer from 'visus-viewer-component';
import {Button, ButtonProps, Icon, Input,
    InputOnChangeData, InputProps, Label,
    Table, Dropdown, Form, Popup,
    Message, DropdownProps} from "semantic-ui-react";
import {Composition, InletSpec, Selection} from "../../models/Simulation";
import {useLayoutEffect} from "react";

//Define the expected props
interface IncomingProps extends InputProps{

    table:{ [form: string]: number; }

    paramLabel:string
    valueLabel:string
    type:string
    //We don't keep state so we need a function to update
    updateTable: (newTable:{ [form: string]: number; }) => any;

    //Store if we allow updates
    editable:boolean;

    //See if we can add to the table
    expandable:boolean;
}

//Define the expected props
interface TblState extends InputProps{
    newParam?:string;
    addFuel : boolean;
}

const fuelData = [
    {
        key: 'CH4',
        text: 'CH4',
        value: 'CH4'
    },
    {
        key: 'C2H6',
        text: 'C2H6',
        value: 'C2H6'
    },
    {
        key: 'C3H8',
        text: 'C3H8',
        value: 'C3H8'
    },
    {
        key: 'C3H6',
        text: 'C3H6',
        value: 'C3H6'
    }
]

const defaultMassFrac = 0.05

class LimitedInputTable extends React.Component<IncomingProps, TblState> {
    state ={
        fuelValue:"",
        newParam:"",
        addFuel:true,
    }


    //Extract the label and units

    //Define a function to update the table when thigns change
    updateValue = (name:string, value:number) =>{
        //Update the table
        let tblCopy = this.props.table;
        //Update that value
        tblCopy[name] = value;

        //console.log({name})
        for(let i=0; i<fuelData.length; i++){
            if(name == fuelData[i].key)
                this.setState({addFuel:false})
        }

        //Now push it back
        this.props.updateTable(tblCopy);
    }

    //Define a function to update the table when thigns change
    removeValue = (name:string) =>{
        //Update the table
        let tblCopy = this.props.table;
        //Update that value
        delete tblCopy[name]

        for(let i=0; i<fuelData.length; i++){
            if(name == fuelData[i].key)
                this.setState({addFuel:true})
        }

        //Now push it back
        this.props.updateTable(tblCopy);
    }

    handleDropdown = (e:any, {value}:any) =>{
        if(value===undefined)
            console.log("undefined");
        else {
            this.setState({newParam: value})
        }
    }
    addFuelButton = () =>{
        let update = true;
        Object.keys(this.props.table).map(key => {
            for(let i=0; i<fuelData.length; i++){
                if(key == fuelData[i].key){
                    this.setState({newParam: "", addFuel: false})
                    update = false
                }
            }
        })
        if(update) {
            this.updateValue(this.state.newParam, defaultMassFrac)
        }
    }

    addFromButton = (value:any) =>{
        console.log(value)
        // This helps us blank out the fuel inlets button
        Object.keys(this.props.table).map(key => {
            for(let i=0; i<fuelData.length; i++){
                if(key == fuelData[i].key){
                    this.setState({addFuel: false})
                }
            }
        })

        this.setState({newParam: value})
        this.updateValue(value, 0.05)
    }

    render(): React.ReactNode {
        return (
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell colSpan={'1'}>{this.props.paramLabel}</Table.HeaderCell>
                        <Table.HeaderCell colSpan={'2'}>{this.props.valueLabel}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {/*Add in component*/}
                    {Object.keys(this.props.table).map(key => {
                        //Return a new table row
                        return (
                            <Table.Row key={key}>
                                <Table.Cell>{key}</Table.Cell>
                                <Table.Cell>
                                    <Input
                                        fluid
                                        disabled={!this.props.editable}
                                        type='number'
                                        value={this.props.table[key]}
                                        onChange={
                                            (event: React.ChangeEvent<HTMLInputElement>,
                                                data: InputOnChangeData) => this.updateValue(key, parseFloat(data.value))
                                        }/>


                                </Table.Cell>
                                {this.props.editable &&  this.props.expandable &&
                                <Table.Cell>
                                    <Popup
                                        trigger={
                                            <Icon
                                                name='cancel'
                                                onClick={() => this.removeValue(key)}
                                            />
                                        }
                                        content={'Remove current fuel source.'}
                                    />
                                </Table.Cell>
                                }
                            </Table.Row>
                        );
                    })}

                </Table.Body>
                {this.props.editable && this.props.expandable && "fuel"== this.props.type &&
                <Table.Footer>
                    <Table.Row>
                        <Table.Cell colSpan='3'>
                            <Dropdown
                                placeholder={"Select fuel source"}
                                inline
                                selection
                                options={fuelData}
                                onChange={this.handleDropdown}
                            />{' '}
                            <Popup
                                trigger={
                                    <Button
                                        icon={'add'}
                                        onClick={this.addFuelButton}
                                        disabled={!this.state.addFuel}
                                    />
                                }
                                content={'Only one type of fuel source can be present at once.'}
                            />
                        </Table.Cell>

                    </Table.Row>
                    <Table.Row>
                        <Table.Cell colSpan='1'>
                            <Button
                                label={'Add CO2'}
                                icon={'add'}
                                onClick={() => this.addFromButton("CO2")}
                            />
                        </Table.Cell>
                        <Table.Cell colSpan={'1'}>
                            <Button
                                label={'Add N2'}
                                icon={'add'}
                                value={"N2"}
                                onClick={() => this.addFromButton("N2")}
                            />
                        </Table.Cell>
                    </Table.Row>
                </Table.Footer>
                }
                {this.props.editable && this.props.expandable &&
                    (this.props.type=="air" || this.props.type=="pilot") &&
                <Table.Footer>
                    <Table.Row>
                        <Table.HeaderCell colSpan='3'>
                            <Input
                                action={{
                                    icon: 'add',
                                    onClick:() => this.updateValue(this.state.newParam, defaultMassFrac)
                                }}
                                value={this.state.newParam}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) =>
                                    this.setState({newParam:data.value})}
                                placeholder={this.props.paramLabel}
                            />
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
                }
                {this.props.editable && this.props.expandable &&
                    (this.props.type=="steam" || this.props.type=="detailedInlet") &&
                <Table.Footer>
                    <Table.Row columns={2}>
                        <Table.Cell colSpan={1}>
                            <Popup
                                size={'small'}
                                position={'bottom left'}
                                trigger={
                                    <Button
                                        label={'Add H2O'}
                                        icon={'add'}
                                        value={"H2O"}
                                        onClick={() => this.addFromButton("H2O")}
                                    />
                                }
                            >
                                Clicking this button while you already have H20 in your species list will set your
                                mass fraction of H20 to {defaultMassFrac}
                            </Popup>
                        </Table.Cell>
                    </Table.Row>
                </Table.Footer>
                }
            </Table>
        )
    }
}

export default LimitedInputTable;