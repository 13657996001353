import axios from 'axios';
import {TemplateData} from "../models/Template";
import {SimulationData} from "../models/Simulation";
import {ServerResponseStatus, authHeader} from "react-authentication";
import { apiConf } from "./apiConf"

export const simulationService = {
    newSimulationFromTemplate,
    getSimulation,
    getSimulations,
    updateSimulation,
    deleteSimulation,
    duplicateSimulation
    // register,
    // getAll,
    // getById,
    // update,
    // delete: _delete
};

// Create a default axios instance with the api
const apiServer = axios.create({
    baseURL: apiConf.serverURL
});


/**
 * get all possible achievements
 * @param username
 * @param password
 * @returns
 */
function newSimulationFromTemplate(templateId:string) : Promise<SimulationData> {

    //Get the headers
    const headers =authHeader();

    //Now make a post request and get a promise back
    const responsePromise = apiServer.post(`/simulations/template/${templateId}`, {}, {headers:headers});


    //We need to do some work here
    return responsePromise.then(response =>
        {//When the request returns
            //Get the user
            const data = <SimulationData>response.data;


            //Return just the user
            return data;
        }
    );
}
/**
 * get all possible achievements
 * @param username
 * @param password
 * @returns
 */
function duplicateSimulation(simId:number) : Promise<SimulationData> {

    //Get the headers
    const headers =authHeader();

    //Now make a post request and get a promise back
    const responsePromise = apiServer.post(`/simulations/duplicate/${simId}`, {}, {headers:headers});


    //We need to do some work here
    return responsePromise.then(response =>
        {//When the request returns
            //Get the user
            const data = <SimulationData>response.data;


            //Return just the user
            return data;
        }
    );
}

/**
 * get all possible achievements
 * @param username
 * @param password
 * @returns
 */
function deleteSimulation(simId:number) : Promise<ServerResponseStatus> {

    //Get the headers
    const headers =authHeader();

    //Now make a post request and get a promise back
    const responsePromise = apiServer.delete(`/simulations/${simId}`,  {headers:headers});


    //We need to do some work here
    return responsePromise.then(response =>
        {//When the request returns
            //Get the user
            const data = <ServerResponseStatus>response.data;


            //Return just the user
            return data;
        }
    );
}


function updateSimulation(sim: SimulationData) : Promise<SimulationData> {

    //Get the headers
    const headers =authHeader();

    //Now make a post request and get a promise back
    const responsePromise = apiServer.put(`/simulations`, sim, {headers:headers});


    //We need to do some work here
    return responsePromise.then(response =>
        {//When the request returns
            //Get the user
            const data = <SimulationData>response.data;


            //Return just the user
            return data;
        }
    );
}


/**
 * get all possible achievements
 * @param username
 * @param password
 * @returns
 */
function getSimulation(simulationId:number) : Promise<SimulationData> {

    //Get the headers
    const headers =authHeader();

    //Now make a post request and get a promise back
    const responsePromise = apiServer.get(`/simulations/${simulationId}`,  {headers:headers});


    //We need to do some work here
    return responsePromise.then(response =>
        {//When the request returns
            //Get the user
            const data = <SimulationData>response.data;


            //Return just the user
            return data;
        }
    );
}
/**
 * get all possible achievements
 * @param username
 * @param password
 * @returns
 */
function getSimulations() : Promise<SimulationData[]> {

    //Get the headers
    const headers =authHeader();

    //Now make a post request and get a promise back
    const responsePromise = apiServer.get(`/simulations/`,  {headers:headers});


    //We need to do some work here
    return responsePromise.then(response =>
        {//When the request returns
            //Get the user
            const data = <SimulationData[]>response.data;

            //Return just the user
            return data;
        }
    );
}