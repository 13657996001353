import React from 'react';

//Import custom styling
import {
    Button,
    Form
} from "semantic-ui-react";
import {GeometryMetaData, Point, Blueprints} from "react-authentication";
import ApplicationState from "../../../state/ApplicationState";
import {connect} from "react-redux";

//Define the expected props
interface IncomingProps {
}

interface LinkProps {
    createNewGeometry: (data: GeometryMetaData) => any;
}


interface LocalState {
    geomData: GeometryMetaData
}

const defaultCreatedGeometry: GeometryMetaData = {
    name: "",
    id: 0,
    notes: "",
    deletable: true,
    blueprints: {
        points: [{x: 0, y: 0}, {x: 1, y: 0}, {x: 1, y: 1}, {x: 0, y: 1}] as Point[],
        res_long: 0.1,
        res_radial: 0.1,
        slices: 10
    } as Blueprints
}

class NewUserCreatedGeometry extends React.Component<IncomingProps & LinkProps, LocalState> {
    state = {
        geomData: defaultCreatedGeometry,
        files: undefined
    }

    handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        this.props.createNewGeometry(this.state.geomData)
        event.preventDefault();
    }

    render(): React.ReactNode {
        //Allow allow to submit if there is a name and blob
        const allowedToSubmit = this.state.geomData.name.length > 0;

        //Return the list
        return (
            <Form onSubmit={this.handleSubmit}>
                <Form.Input
                    fluid
                    label='Geometry Name'
                    placeholder='Geometry Name'
                    value={this.state.geomData.name}
                    onChange={(event: React.FormEvent<HTMLInputElement>) => {
                        this.setState({
                            ...this.state,
                            geomData: {
                                ...this.state.geomData,
                                name: event.currentTarget.value
                            }
                        })
                    }}
                />
                <Form.TextArea
                    fluid
                    label='Geometry Notes'
                    placeholder='Please describe the geometry...'
                    value={this.state.geomData.notes}
                    onChange={(event: React.FormEvent<HTMLTextAreaElement>) => {
                        this.setState({
                            ...this.state,
                            geomData: {
                                ...this.state.geomData,
                                notes: event.currentTarget.value
                            }
                        })
                    }}
                />
                <Form.Field disabled={!allowedToSubmit}
                            control={Button}
                >Create</Form.Field>

            </Form>
        );

    }

}

/**
 * Map from the global state to things we need here
 * @param state
 * @returns {{authentication: WebAuthentication}}
 */
function mapStateToProps(state: ApplicationState): IncomingProps {
    return {};
}


export default connect(
    mapStateToProps,
)(NewUserCreatedGeometry);
