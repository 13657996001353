import React from 'react';

// import routes
import {Icon} from 'semantic-ui-react';
import { Route, RouteProps} from "react-router";

//Import Pages
import ApplicationState from "../../state/ApplicationState";
import {ThunkDispatch} from "redux-thunk";
import {connect} from "react-redux";
import {globalMenuItems} from "./GlobalMenuItems";
import SimulationRouter from "./simulation/SimulationRouter";
import SimulationList from "./simulation_list/SimulationList";
import Welcome from "./Welcome";
import GeometryList from "./geometry_list/GeometryList";
import AddGeometry from "./geometry_list/AddGeometry";
import GeometryCreatorRouter from "./geometry_list/geometryCreator/GeometryCreatorRouter"
import {ResponsiveNavBar, SidebarState, sidebarActions, SideBarMenu, MenuMode, MobileMenuMode} from "rei-react-ui";
import HelpViewer from "../static-pages/HelpViewer";
import {MyPreferences} from "react-authentication";
import {Dropdown, Menu} from "semantic-ui-react";


//Setup up path props to get the current path
interface AppProps extends RouteProps{

}

//Setup up path props to get the current path
interface LinkProps {
    sideBarState:SidebarState;
}

//Setup up path props to get the current path
interface DispatchProps {
    //And the actions that must be done
    toggleSideMenuView: () => any;

}

class App extends React.Component<AppProps&DispatchProps&LinkProps> {
    state = {menuFixed:false}
    /**
     * Gets called once when the page loads
     */
    componentDidMount(){

    };


    //Return the real div
    /**
     * Re-render every time this is called
     * @returns {*}
     */
    render() {
        //Get the current url
        //const url = this.props.location? this.props.location.pathname: "";

        //Build the sidebar menu, only if there are items there
        let sideBarIcon = undefined;
        if (this.props.sideBarState.sideItemList.length > 0) {
            sideBarIcon = [{
                name: <Icon name='bars'/>,
                onClick: () => this.props.toggleSideMenuView()
            }];
        }
        return (
            <div className="App" style={{height: "100vh"}}>
                {/*Add a side bar for nav*/}
                <SideBarMenu>
                    {/* This component was written to hold at least 2 children. Why? */}
                    <></>
                    {/*Add a responsive nav bar*/}
                    <ResponsiveNavBar
                        ///*Define a desktop header*/}
                        desktopHeader={undefined}
                        desktopMode={MenuMode.None}
                        mobileMode={MobileMenuMode.DROP}

                        //Add the control for the left hand side
                        itemsLeft={sideBarIcon}

                        ///*Now for the menu items*/}
                        items={globalMenuItems}

                        //Add in fixed items
                        itemsRight={[
                            {//Show the help icon
                                to: '/help',
                                name: <Icon name={'help circle'} size='large'/>,
                                tooltip: 'Help'
                            },
                            {
                                // Error occurs if you don't have a name variable
                                name: " ",
                                icon: <Icon name={'user circle'} size='large'/>,
                                subItems: [
                                    {//show the user prefs
                                        name: "Preferences",
                                        to: '/preferences',
                                        icon: <Icon name='settings'/>
                                    },
                                    {//log out
                                        name: "Log Out",
                                        to: '/login',
                                        icon: <Icon name='log out'/>
                                    }
                                ]
                            }
                        ]}
                    >

                        {/*The menu is over is load in based upon the router*/}
                        {/*Allow the stuff in the div to scroll to fit*/}
                        <div style={{height: "100%", overflow: "auto"}}>
                            {/*Give these full page*/}
                            <Route exact key={'welcome'} path="/" component={Welcome}/>
                            <Route exact key={'simulation'} path="/simulation" component={SimulationList}/>
                            <Route exact key={'geometry'} path="/geometry" component={GeometryList}/>
                            <Route exact key={'add'} path="/add" component={AddGeometry}/>
                            <Route exact key={'help'} path="/help" component={HelpViewer}/>

                            <Route key={'simulationEdit'} path="/simulation/:simId" component={SimulationRouter}/>
                            <Route key={'geometryEdit'} path="/geometry/:geomId" component={GeometryCreatorRouter}/>
                            <Route key={'preferences'} path="/preferences" component={MyPreferences}/>

                        </div>
                    </ResponsiveNavBar>
                </SideBarMenu>
            </div>
        );
    }
}

/**
 * Map from the global state to things we need here
 * @param state
 * @returns {{authentication: WebAuthentication}}
 */
function mapStateToProps(state:ApplicationState,myProps:AppProps ):AppProps&LinkProps {
    return {
        ...myProps,
        sideBarState:state.sidebar,
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch<any,any, any>):DispatchProps {
    return {
        toggleSideMenuView:() =>  dispatch(sidebarActions.toggleSideMenuView())
    };

}
export default connect (
    mapStateToProps,
    mapDispatchToProps
)(App);
