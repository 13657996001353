import React from 'react';
import {Route, RouteComponentProps} from 'react-router-dom';

import {connect} from "react-redux";
import ApplicationState from "../../../state/ApplicationState";
import {SideMenuItem, sidebarActions} from "rei-react-ui";
import {Dimmer, Icon, Image, Loader} from "semantic-ui-react";
import logoImage from '../../../assets/logo.png';
import Welcome from "../Welcome";
import CalcList from "../simulation_list/SimulationList";
import {DataSelectorAndViewer} from "../DataSelectorAndViewer";
import {ThunkDispatch} from "redux-thunk";
import SimulationSummary from "./pages/SimulationSummary";
import {SimulationData} from "../../../models/Simulation";
import {simActions} from "../../../actions/simulations.actions";
import Environment from "./pages/Environment";
import ModelNumPara from "./pages/ModelNumPara";
import Inlets from "./pages/Inlets";
import GeometryPage from "./pages/GeometryPage";
import SolveSummary from "../solve/SolveSummary";
import SolveRouter from "../solve/SolvePage";

/**
 * This private route renders what was specified or skips it if the user does not have authority
 * @param Component
 * @param rest
 * @returns {*}
 * @constructor
 */


//Define a private route interface for props
interface LinkProps {
    //Send it the activeResource sim id
    simId: number;

    //Store the basePath
    basePath:string;

}

//Define a private route interface for props
interface StateProps {
    //Send it the activeResource sim id
    simData?: SimulationData;

    //Waiting for sim data update
    waitingForUpdate?:boolean;
}

//Define a private route interface for props
interface DispatchProps {
    //And the actions that must be done
    updateSideMenu: (sideMenu:SideMenuItem[]) => any;
    getSimulation: (simId:number) => any;
    updateSimulation: (simId:SimulationData) => any;

}




/**
 * This card shows the animal details
 */
class SimulationEditor extends React.Component<LinkProps&StateProps&DispatchProps> {
    componentDidMount(): void {
        //Build
        const {basePath, simId} = this.props;

        //Now build the base url
        const baseUrl = basePath + "/" + simId

        this.props.updateSideMenu(
            [
                {
                    icon: <Image size='tiny' src={logoImage}/>
                },
                {
                    name: "Summary",
                    to: `${baseUrl}/` ,
                    icon: <Icon name='list alternate outline' inverted/>
                },
                {
                    name: "Geometry",
                    to: `${baseUrl}/geometry` ,
                    icon: <Icon name='th' inverted/>
                },
                {
                    name: "Environment",
                    to: `${baseUrl}/environment` ,
                    icon: <Icon name='cloud' inverted/>
                },
                {
                  name:"Model Setup",
                  to:`${baseUrl}/modelnumpara`,
                    icon:<Icon name='modx' inverted/>
                },
                {
                    name: "Inlets",
                    to: `${baseUrl}/inlets` ,
                    icon: <Icon name='dashboard' inverted/>
                },
                {
                    name: "Solve",
                    to: `${baseUrl}/solve` ,
                    activeMatch:`${baseUrl}/solve.*`,
                    icon: <Icon name='calculator' inverted/>
                },
            ] as SideMenuItem[]

        );
        //Put in the code to download the item
        this.props.getSimulation(simId);

    };

    componentWillUnmount(): void {
        //Set the side params to empty
        this.props.updateSideMenu([]);

    }

    //Add function to update simulation
    updateSimulation = (sim:SimulationData) => {this.props.updateSimulation(sim)}

    //Check to see if any of the routes are excluded
    render() {
        //Build
        const {basePath, simId, simData} = this.props;

        //Now build the base url
        const baseUrl = basePath + "/" + simId


        //Determine if we are loading
        const loading = simData === undefined || this.props.waitingForUpdate;


        return (
            <div>
                <Dimmer active={loading } inverted>
                    <Loader inverted content='Loading' />
                </Dimmer>

                {simData && <>
                    <Route key={'summary'}
                           exact
                           path={`${baseUrl}/`}
                           render={(props: RouteComponentProps<any>) => {
                               return <SimulationSummary {...props} updateSimulation={this.updateSimulation}
                                                         sim={simData}/>
                           }}
                    />
                    <Route key={'geometry'}
                           exact
                           path={`${baseUrl}/geometry`}
                           render={(props: RouteComponentProps<any>) => {
                               return <GeometryPage {...props} updateSimulation={this.updateSimulation} sim={simData}/>
                           }}
                    />
                    <Route key={'environment'}
                           exact
                           path={`${baseUrl}/environment`}
                           render={(props: RouteComponentProps<any>) => {
                               return <Environment {...props} updateSimulation={this.updateSimulation} sim={simData}/>
                           }}
                    />
                    <Route key={'modelnumpara'}
                           exact
                           path={`${baseUrl}/modelnumpara`}
                           render={(props: RouteComponentProps<any>) => {
                               return <ModelNumPara {...props} updateSimulation={this.updateSimulation} sim={simData}/>
                           }}
                    />
                    <Route key={'inlets'}
                           exact
                           path={`${baseUrl}/inlets`}
                           render={(props: RouteComponentProps<any>) => {
                               return <Inlets {...props} updateSimulation={this.updateSimulation} sim={simData}/>
                           }}
                    />
                    <Route key={'solve'}
                           exact
                           path={`${baseUrl}/solve`}
                           render={(props: RouteComponentProps<any>) => {
                               return <SolveSummary {...props}
                                                    updateSimulation={this.updateSimulation}
                                                    sim={simData}
                                                    baseUrl={`${baseUrl}/solve`}
                               />
                           }}
                    />
                    <Route key={'solveID'}
                           exact
                           path={`${baseUrl}/solve/:solId`}
                           render={(props: RouteComponentProps<any>) => {
                               return <SolveRouter {...props} />
                           }}
                    />
                    <Route key='welcome' exact path={`${baseUrl}/welcome`} component={Welcome}/>
                    <Route key='calc' exact path={`${baseUrl}/calc`} component={CalcList}/>
                    <Route key={'viewer'} path={`${baseUrl}/viewer/:dataset`} component={DataSelectorAndViewer}/>
                </>
                }
            </div>
        )

    }

}


/**
 * Map from the global state to things we need here
 * @param state
 * @returns {{authentication: WebAuthentication}}
 */
function mapStateToProps(state:ApplicationState,myProps:LinkProps ):LinkProps&StateProps {
    return {
        ...myProps,
        simData:state.simulations.simulations[myProps.simId],
        waitingForUpdate:state.simulations.loading
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch<any,any, any>): DispatchProps {
    return {
        updateSideMenu:(sideMenu:SideMenuItem[]) => dispatch(sidebarActions.setSideBarMenuItems(sideMenu)),
        getSimulation:(simId:number) => dispatch(simActions.getSimulation(simId)),
        updateSimulation:(sim:SimulationData) => dispatch(simActions.updateSimulation(sim))

    };

}

export default connect (
    mapStateToProps,
    mapDispatchToProps
)(SimulationEditor);

