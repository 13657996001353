import React from 'react';
import {connect} from "react-redux";
import {Dispatch} from "redux";
import {ThunkDispatch} from "redux-thunk";


//App state and actions
import ApplicationState from "../../../state/ApplicationState";

//Import other components
import GeometryListItem from "./GeometryListItem";

//Needed models
import {Button, Card, Container, Input, Modal} from "semantic-ui-react";
import {GeometryMetaData} from "react-authentication";
import {geometryActions} from "../../../actions/geometry.actions";
import AddGeometry from "./AddGeometry";
import NewUserCreatedGeometry from "./NewUserCreatedGeometry"

import {RouteComponentProps, withRouter} from "react-router";

//Define the expected props from the router
interface LinkProps extends RouteComponentProps<any> {

}

//Define the expected props coming from application state
interface IncomingProps {
    //Define the props we expect
    geometries: { [id: number]: GeometryMetaData; };
    uploading?: boolean;
    creatingNewGeom?: boolean;
}


interface DispatchProps {
    //And the actions that must be done
    updateGeometryList: () => any;
    removeGeometry: (geom:GeometryMetaData) => any;
    uploadGeometry:(data: GeometryMetaData, units:string, file: File) => {};
    uploadNewUserCreatedGeometry:(data: GeometryMetaData, newGeomFunc?: (newGeomId: number) => any) => any;
}

// Component state
interface ComponentState {
    //Add a control to open/close modal
    uploadNewModalOpen: boolean;
    createNewModalOpen: boolean;
}


class GeometryList extends React.Component<LinkProps&IncomingProps&DispatchProps, ComponentState> {

    state = {uploadNewModalOpen: false, createNewModalOpen: false}

    /**
     * Gets called once when the page loads
     */
    componentDidMount(){
        //Request the Simulation list
        this.props.updateGeometryList()
    };

    /**
     * Function to build a list of Simulations
     */
    getListofSimulations() : JSX.Element[]{
        //Go through the list
        return Object.keys(this.props.geometries).map((keyString: string) => {
            return <GeometryListItem onClick={() => {}}
                                     geom={this.props.geometries[parseInt(keyString)]}
                                     removeSim={
                                         (data?: GeometryMetaData) => {
                                             if (data) {
                                                 this.props.removeGeometry(data)
                                             }
                                         }
                                     }
            />

        });

    }

    // Function to upload new STL
    uploadNewGeom = (data: GeometryMetaData, units:string, file: File) => {
        this.props.uploadGeometry(data,units,  file);

        //Also close the upload
        this.setState({uploadNewModalOpen: false})
    }

    newGeomFunc = (newGeomId: number) => {
        this.props.history.push("/geometry/" + newGeomId)
    }

    // Function to upload new blueprints
    uploadNewUserCreatedGeom = (data: GeometryMetaData) => {
        this.props.uploadNewUserCreatedGeometry(data, this.newGeomFunc);

        //Also close the upload
        this.setState({createNewModalOpen: false})
    }


    /**
     * Re-render eveyr time this is called
     * @returns {*}
     */
    render() {
        return (
            <Container style={{ padding: '5em 0em'}}>
                <div className="ui stackable grid">
                    <div className="left floated left aligned six wide column">
                        <h3 className="ui header">Geometry List</h3>
                    </div>
                    <div className="right floated right aligned six wide column">
                        <Modal
                            trigger={<Button icon='add' onClick={() => this.setState({createNewModalOpen:true})}/>}
                            open={this.state.createNewModalOpen}
                            onClose={() => this.setState({createNewModalOpen: false})}
                        >
                            <Modal.Content>
                                <NewUserCreatedGeometry createNewGeometry={this.uploadNewUserCreatedGeom}/>
                            </Modal.Content>

                        </Modal>
                        <Modal
                            trigger={<Button icon='upload' onClick={() => this.setState({uploadNewModalOpen:true})}/>}
                            open={this.state.uploadNewModalOpen}
                            onClose={() => this.setState({uploadNewModalOpen:false})}
                        >
                            <Modal.Content>
                                <AddGeometry uploadGeometry={this.uploadNewGeom}/>
                            </Modal.Content>

                        </Modal>
                    </div>
                </div>

                {/*And the list*/}
                <Card.Group>
                    {this.getListofSimulations()}
                    {this.props.uploading &&
                        <GeometryListItem onClick={undefined}
                                          geom={undefined}
                                          removeSim={undefined}

                        />
                    }
                    {this.props.creatingNewGeom &&
                    <GeometryListItem onClick={undefined}
                                      geom={undefined}
                                      removeSim={undefined}

                    />
                    }
                </Card.Group>
            </Container>

        );
    }

}

/**
 * Map from the global state to things we need here
 * @param state
 * @param myProps
 * @returns {{authentication: WebAuthentication}}
 */
function mapStateToProps(state: ApplicationState, myProps: LinkProps): LinkProps&IncomingProps {
    return {
        ...myProps,
        geometries: state.geometry.geometryList,
        uploading: state.geometry.uploadingGeom,
        creatingNewGeom: state.geometry.loading,
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch<any, any, any>): DispatchProps {
    return {
        updateGeometryList:() => dispatch(geometryActions.updateGeometryList()),
        removeGeometry:(geom:GeometryMetaData) => dispatch(geometryActions.removeGeom(geom)),
        uploadGeometry:(data: GeometryMetaData,units:string,  file: File) => dispatch(geometryActions.uploadGeometry(data,units, file)),
        uploadNewUserCreatedGeometry:(data: GeometryMetaData, newGeomFunc?: (newGeomId: number) => any) => dispatch(geometryActions.uploadGeometryItem(data, newGeomFunc)),
    };
}

const GeometryListWithOutRouter = connect (
    mapStateToProps,
    mapDispatchToProps
)(GeometryList);

export default withRouter(props => <GeometryListWithOutRouter {...props}/>);
