import React from 'react';
import {Container, Header} from "semantic-ui-react";

import TemplateList from "./template/TemplateList";


const Welcome = () =>{
    return (
    <div>
        <Container text style={{paddingTop: '15px'}}>
            <Header as='h1' dividing textAlign={'center'}>
                Welcome to Flares Simulation
            </Header>
            <p style={{lineHeight: '1.8'}}>
                Use the buttons located at the top of the page to do the following:
                <ul>
                    <li> <i className="plus square outline large icon"/>Begin a new simulation </li>
                    <li> <i className="list alternate outline large icon"/>List your previous existing simulations </li>
                    <li> <a href="/geometry"><i className="th large large icon"/></a>List your currently saved geometries </li>
                    <li> <a href="/help"><i className="help circle large icon"/></a> View a detailed help manual</li>
                    <li> <i className="user circle large icon"/> View your <a href="/preferences">preferences</a> or log out</li>
                </ul>
            </p>
        </Container>
    </div>
    );


};

export default Welcome;
