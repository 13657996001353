import React from 'react';

//Import custom styling
import {
    Button, DropdownProps,
    Form
} from "semantic-ui-react";
import {GeometryMetaData} from "react-authentication";
import ApplicationState from "../../../state/ApplicationState";
import {connect} from "react-redux";

//Define the expected props
interface IncomingProps {
}

interface LinkProps {
    uploadGeometry: (data: GeometryMetaData, units: string, file: File) => any;
}


interface LocalState {
    geomData: GeometryMetaData;
    files?: FileList;
    //Store the units
    units: string;

}


class AddGeometry extends React.Component<IncomingProps & LinkProps, LocalState> {
    state = {
        geomData: {
            id: 0,
            name: "",
            notes: ""
        }
        ,
        files: undefined,
        units: "m"
    }

    handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {

        //Get the files
        const files = this.state.files;

        //Now upload the data
        if (files) {
            this.props.uploadGeometry(this.state.geomData, this.state.units, files[0])
        }

        event.preventDefault();
    }

    render(): React.ReactNode {
        //Allow allow to submit if there is a name and blob
        const allowedToSubmit = this.state.geomData.name.length > 0 && this.state.files !== undefined;

        //Return the list
        return (
            <Form
                onSubmit={this.handleSubmit}
            >
                <Form.Input
                    fluid
                    label='Geometry Name'
                    placeholder='Geometry Name'
                    value={this.state.geomData.name}
                    onChange={(event: React.FormEvent<HTMLInputElement>) => {
                        this.setState({
                            ...this.state,
                            geomData: {
                                ...this.state.geomData,
                                name: event.currentTarget.value
                            }
                        })
                    }}
                />
                <Form.TextArea
                    fluid
                    label='Geometry Notes'
                    placeholder='Please describe the geometry...'
                    value={this.state.geomData.notes}
                    onChange={(event: React.FormEvent<HTMLTextAreaElement>) => {
                        this.setState({
                            ...this.state,
                            geomData: {
                                ...this.state.geomData,
                                notes: event.currentTarget.value
                            }
                        })
                    }}
                />
                <Form.Input
                    type='file'
                    fluid
                    onChange={(event: React.FormEvent<HTMLInputElement>) => {
                        this.setState({
                            ...this.state,
                            files: event.currentTarget.files ? event.currentTarget.files : undefined
                        })
                    }}
                />
                <Form.Select
                    type='file'
                    fluid
                    options={[
                        {key: 'm', text: "meters", value: "m"},
                        {key: 'mm', text: "milimeters", value: "mm"},
                    ]}
                    value={this.state.units}
                    onChange={(event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
                        this.setState({
                            ...this.state,
                            units: data.value ? data.value.toString() : "m"
                        })

                    }}

                />
                <Form.Field disabled={!allowedToSubmit}
                            control={Button}
                >Upload</Form.Field>

            </Form>
        );

    }


};

/**
 * Map from the global state to things we need here
 * @param state
 * @returns {{authentication: WebAuthentication}}
 */
function mapStateToProps(state: ApplicationState): IncomingProps {
    return {};
}


export default connect(
    mapStateToProps,
)(AddGeometry);
;
