import React from 'react';

//Import custom styling
import {Link} from "react-router-dom";
import {Button, Header, Modal, Popup} from "semantic-ui-react";
import {SimulationData} from "../../../models/Simulation";

//Define the expected props
interface IncomingProps{
    //Define the props we expect
    simulation: SimulationData;

    //We also have the router maps
    linkToRoute: string;

    //Provide function to delete
    removeSimulation: (calc:SimulationData) => any

    //Provide function to delete
    duplicateSimulation: (calc:SimulationData) => any


    //Provide a hook for another action on click
    onClick: () => any;
}



const SimulationListItem = (props:IncomingProps) => {

    //Return the list
    return (
        <div className="CalcListItem item" >
            {/*Add a button to remove the item*/}
            <div className="right floated content ">
                {/*Allow the item to be copied*/}
                {/*<Header>{JSON.stringify(props.simulation)}</Header>*/}
                <Popup
                    trigger={
                        <i
                            style={{ padding: '0em 1em' }}
                        >
                            {props.simulation.id}
                        </i>
                    }
                >
                    <Popup.Content>
                        Simulation ID
                    </Popup.Content>
                </Popup>
                <Modal trigger={
                    <Button circular icon='copy outline' />
                } basic size='small'>
                    <Header icon='copy outline' content='Duplicate Simulation?' />
                    <Modal.Content>
                        <p>
                            Would you like to make a copy of {props.simulation.name}?
                        </p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button circular icon='copy outline' onClick={() => props.duplicateSimulation(props.simulation)}/>
                    </Modal.Actions>
                </Modal>

                {/*Setup a modal section to open when the button is pushed*/}
                <Modal trigger={
                        <Button circular icon='trash alternate outline' />
                    } basic size='small'>
                    <Header icon='trash alternate outline' content='Delete Simulation?' />
                    <Modal.Content>
                        <p>
                            Are you sure you can to delete the simulation {props.simulation.name}?
                        </p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button circular icon='trash alternate outline' onClick={() => props.removeSimulation(props.simulation)} />
                    </Modal.Actions>
                </Modal>



            </div>

            <Link onClick={props.onClick} to={props.linkToRoute}><div className="header">{props.simulation.name}</div></Link>
        </div>
    );


};

export default SimulationListItem;