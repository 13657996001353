import React from 'react';
import { RouteComponentProps} from 'react-router-dom';
import {withRouter} from 'react-router-dom';

import {connect} from "react-redux";
import ApplicationState from "../../../state/ApplicationState";
import {SolveData} from "../../../models/Solve";
import {solveService} from "../../../services/solve.service";
import TimeStepDisplay from "./log/TimeStepDisplay";
import MinMaxDisplay from "./log/MinMaxDisplay"
import LinePointsDisplay from "./log/LinePointsDisplay"
import {PNModal} from "react-authentication";
import { Button, Container, Divider, Header, Modal } from 'semantic-ui-react'

/**
 * This private route renders what was specified or skips it if the user does not have authority
 * @param Component
 * @param rest
 * @returns {*}
 * @constructor
 */


//Define a private route interface for props
interface LinkProps extends RouteComponentProps<any> {

}

//Define a private route interface for props
interface State {
    solve?: SolveData
    error?: string
}



/**
 * This card shows the animal details
 */
class SolvePage extends React.Component<LinkProps, State> {
    state = {solId:-1, solve:undefined, error:undefined, logData:{}}


    //Store the solvId
    private solId:number =-1;

    componentWillMount(): void {
        //Get the simulation id from the the url
        const solIdAny = this.props.match.params.solId;
        //Convert to an int
        const solId = parseInt(solIdAny.toString());

        //Store it
        this.solId = solId;

        //Now load the solve
        //If the user is logged in get the logged in
        let solveDataPromise = solveService.getSolve(solId)

        //When it comes back use it
        solveDataPromise.then(
            //If successful html will be returned
            solve => {
                //Update the state
                this.setState({solve:solve})
            },
            //If there was an error, show to the user
            errorResponse => {
                this.setState({error:errorResponse})
            }

        );



    };




    //Check to see if any of the routes are excluded
    render(): React.ReactNode {
        return (
            <Container>
                <TimeStepDisplay
                    logType={"timeStep"}
                    solId={this.solId}
                    updateInterval={1000}
                />

                <Divider/>

                {/*<RawDisplay*/}
                {/*    logType={"minMax"}*/}
                {/*    solId={this.solId}*/}
                {/*    otherInfo={"RawDisplay: MinMax Data"}*/}
                {/*    updateInterval={1000}*/}
                {/*/>*/}

                <MinMaxDisplay
                    logType={"minMax"}
                    solId={this.solId}
                    updateInterval={1000}
                />

                <Divider/>

                {/*<RawDisplay*/}
                {/*    logType={"lineData"}*/}
                {/*    solId={this.solId}*/}
                {/*    otherInfo={"RawDisplay: Line Data"}*/}
                {/*    updateInterval={1000}*/}
                {/*/>*/}

                <LinePointsDisplay
                    logType={"lineData"}
                    solId={this.solId}
                    updateInterval={1000}
                />

                <Divider/>
                <Modal
                    trigger={
                        <Button
                            floated={'right'}
                        >Display Raw JSON Information</Button>
                    }
                    size={'large'}
                >
                    <Header icon={'code'} content={'Raw JSON Information'}/>
                    <Modal.Content>
                        {
                            this.state.solve &&
                            JSON.stringify(this.state.solve)
                        }
                        {
                            this.state.error &&
                            JSON.stringify(this.state.error)
                        }
                    </Modal.Content>
                {/*prefs: state.authentication.preferences ? state.authentication.preferences.settings : undefined}*/}
                </Modal>
                <PNModal forceOpen={false} callBackFunc={() => {}}/>

                {/*<RawDisplay*/}
                {/*    logType={"ptdata"}*/}
                {/*    solId={this.solId}*/}
                {/*    otherInfo={"RawDisplay:
                This is other information, you don't need to use this?"}*/}
                {/*/>*/}

                {/*<RawDisplay*/}
                {/*    logType={"timeStep"}*/}
                {/*    solId={this.solId}*/}
                {/*    otherInfo={"RawDisplay: Log Data"}*/}
                {/*/>*/}

                <div style={{height: '80px'}}/>
            </Container>
        )
    }

}


/**
 * Map from the global state to things we need here
 * @param state
 * @returns {{authentication: WebAuthentication}}
 */
function mapStateToProps(state:ApplicationState,myProps:LinkProps ):LinkProps {
    return {
        ...myProps,
};
}

const SolveRouterWithOutRouter = connect (
    mapStateToProps,
)(SolvePage);

//Wrap with a withRouter so we get the current location
export default withRouter((props:LinkProps) => <SolveRouterWithOutRouter {...props}/>);