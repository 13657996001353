import React from "react"
import {LinePackage} from "../../../../models/LogPackage";
import wrapLogDisplay, {InjectedLogDataProps} from "../SolveLogUpdater";
import {ChartDataSeries} from "multiple-series-line-chart"
import MultipleSeriesLineChart from "multiple-series-line-chart"
import {Tab} from "semantic-ui-react"

interface RawDisplayProps extends InjectedLogDataProps<LinePackage>{

}

interface DataPoint {
    index: number;
    physicalTime: number;
    [ptId: string]: number
}

interface TabPaneObject {
    menuItem: string;
    render(): JSX.Element;
}

// class MultipleSeriesLineChart extends React.Component<IncomingProps> {
class LinePointsDisplay extends React.Component<RawDisplayProps> {

    constructor(props: RawDisplayProps) {
        super(props)
    }

    render(): React.ReactNode {
        // convert line data into our Recharts format
        // make a map keyed by the line name for each of the chartData arrays
        let chartDataMap = new Map<string, ChartDataSeries[]>();
        let ptNamesMap = new Map<string, string[]>(); //line name is the key as well
        if (this.props.pkgList != undefined && this.props.pkgList.length > 0 && this.props.pkgList[0].data != undefined && Object.keys(this.props.pkgList[0].data).length > 0) {

            //For each line in the package
            Object.keys(this.props.pkgList[0].data).map(lineName => {

                // initialize the empty array of ChartDataSeries'
                let chartDataArray: ChartDataSeries[] = []
                // initialize an empty map of arrays for facilitating data extraction
                let dataSeriesMap = new Map<string, DataPoint[]>(); //keyed by variable name
                Object.keys(this.props.pkgList![0].data[lineName][0]).map(varName => {
                    dataSeriesMap.set(varName, [])
                });

                // create point names to identify each point for the different series data keys
                let ptNames: string[] = []
                this.props.pkgList![0].data[lineName].map((_, ptIndex) => {
                    let pointName = "Pt. " + (ptIndex+1).toString()
                    ptNames.push(pointName)
                });
                ptNamesMap.set(lineName, ptNames)

                // for each time step
                this.props.pkgList!.map(pkg => {
                    // initialize an empty map
                    let pointDatas = new Map<string, number[]>()
                    Object.keys(pkg.data[lineName][0]).map( varName => {
                        pointDatas.set(varName, [])
                    })

                    // for each point in this line at this time step
                    pkg.data[lineName].map(ptData => {
                        // for each variable recorded at the point
                        Object.keys(ptData).map( varName => {
                            pointDatas.get(varName)!.push(ptData[varName].value)
                        });
                    });

                    // for each variable name
                    pointDatas.forEach( (numberArray, varName) => {
                        let dataPt: DataPoint = {index: pkg.index, physicalTime: pkg.data[lineName][0]["Time"].value} //just grab the first point's time info
                        // for each point index
                        numberArray.map( (value, ptIndex) => {
                            let pointName = ptNames[ptIndex]
                            dataPt[pointName] = value
                        });
                        dataSeriesMap.get(varName)!.push(dataPt)
                    });
                });

                // for each variable in the dataSeriesMap
                dataSeriesMap.forEach((dataSeries, varName) => {
                    chartDataArray.push({name: varName, yUnits: this.props.pkgList![0].data[lineName][0][varName].units, data: dataSeries});
                });

                chartDataMap.set(lineName, chartDataArray)
            });
        }

        // Create tabs
        let panes: TabPaneObject[] = []
        chartDataMap.forEach((chartDataArray, lineName) => {
            let renderPane = (): JSX.Element => {
                return (
                    <Tab.Pane>
                        <MultipleSeriesLineChart xAxisDataKey={"index"} xUnits={""} yAxisDataKeys={ptNamesMap.get(lineName)!} chartData={chartDataArray} chartDataKey={lineName}/>
                    </Tab.Pane>
                );
            }
            let sanitizedLineName = "";
            switch(lineName) {
                case "CCVelocity":
                    sanitizedLineName = "Velocity";
                    break;
                case "pressurePS":
                    sanitizedLineName = "Pressure";
                    break;
                default:
                    sanitizedLineName = lineName;
            }

            panes.push({menuItem: sanitizedLineName, render: renderPane})
        });

        return (
            <div>
                <h1>Selected Location Monitor</h1>

                {/* If we have all the data */}
                {this.props.pkgList &&
                 this.props.pkgList.length > 0 &&
                 this.props.pkgList[0].data &&
                 Object.keys(this.props.pkgList[0].data).length > 0 &&
                 // chartDataMap.get("center_line") && chartDataMap.get("center_line")![0] &&
                        <Tab panes={panes} />
                }

                {/* else show loading */}
                {!this.props.pkgList &&
                    <div>Loading</div>
                }
                {/* Let the user know it failed */}
                {this.props.pkgList &&
                this.props.pkgList.length == 0  &&

                <div>No data to display</div>
                }

                {/* show any error */}
                {this.props.error &&
                    <div>Error: {this.props.error}</div>
                }

            </div>

        );
    }

}

export default wrapLogDisplay(LinePointsDisplay)
