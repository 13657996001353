import {Action} from 'action-alert'
import SimState from "../state/SimState";
import {simConstants} from "../actions/simulations.actions";
import {SimulationData} from "../models/Simulation";

/**
 * The alert reducer maintains a list of alerts
 * @param state
 * @param action
 * @returns {*}
 */
export function simulations(state:SimState= {simulations:{} as { [id: string]: SimulationData; }}, action:Action): SimState {

    //Ok, we now know that it is an alert action
    switch (action.type) {
        case simConstants.UPDATE_SIM: {
            //Get the updated sim
            const updatedSim = action.payload as SimulationData;

            //Update the sim
            let simulations = {...state.simulations}
            simulations[updatedSim.id] = updatedSim;

            //Add the new success to the list
            return {
                ...state,
                simulations,
                loading:undefined
            }
        }
        case simConstants.UPDATE_SIM_LIST: {
            //Update the sim
            let simulations = {...state.simulations};

            //Update each one
            (action.payload as SimulationData[]).forEach(newSim =>{
                simulations[newSim.id] = newSim
            })

            //Add the new success to the list
            return {
                ...state,
                simulations,
                loading:undefined

            }
        }
        case simConstants.SIM_REMOVED: {
            //Update the sim
            let simulations = {...state.simulations};

            //Update each one
            delete simulations[action.payload]

            //Add the new success to the list
            return {
                ...state,
                simulations,
                loading:undefined

            }
        }
        case simConstants.FETCH_SIM_FAILURE:
        case simConstants.FETCH_SIM_REQUEST: {

            //Add the new success to the list
            return {
                ...state,
                loading:true
            }
        }

        default:
            return state
    }
}