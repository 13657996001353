import axios from 'axios';
import {CalcData} from "../models/Calc";
import {authHeader, ServerResponseStatus} from "react-authentication";
import { apiConf } from "./apiConf"

export const calcService = {
    getCalcs,
    getCalcById,
    saveAndRunCalc,
    removeCalc,
    searchCalcs
    // update,
    // delete: _delete
};

// Create a default axios instance with the api
const apiServer = axios.create({
    baseURL: apiConf.serverURL
});

/**
 * Gets the current calcs of the user
 * @param username
 * @param password
 * @returns
 */
function getCalcs() : Promise<CalcData[]> {

    //Get the headers
    const headers =authHeader();

    console.log("headers");
    console.log(headers)

    //Now make a post request and get a promise back
    const responsePromise = apiServer.get('/calcs',  {headers:headers});

    //We need to do some work here
    return responsePromise.then(response =>
        {//When the request returns
            //Get the user
            const userData = <CalcData[]>response.data;

            //Return just the user
            return userData;
        }
    );


}

/**
 * Gets the current calcs of the user
 * @param username
 * @param password
 * @returns
 */
function searchCalcs(term:string) : Promise<CalcData[]> {

    //Get the headers
    const headers =authHeader();

    //Now make a post request and get a promise back
    const responsePromise = apiServer.get(`/calcs?search=${term}`,  {headers:headers});

    //We need to do some work here
    return responsePromise.then(response =>
        {//When the request returns
            //Get the user
            const userData = <CalcData[]>response.data;

            //Return just the user
            return userData;
        }
    );


}
/**
 * Gets the current calcs of the user
 * @param username
 * @param password
 * @returns
 */
function getCalcById(id: number) : Promise<CalcData> {

    //Now make a post request and get a promise back
    const responsePromise = apiServer.get(`/calcs/${id}`,  {headers:authHeader()});

    //We need to do some work here
    return responsePromise.then(response =>
        {//When the request returns
            //Get the user
            const userData = <CalcData>response.data;

            //Return just the user
            return userData;
        }
    );


}

/**
 * Gets the current calcs of the user
 * @param username
 * @param password
 * @returns
 */
function saveAndRunCalc(calc: CalcData) : Promise<CalcData> {

    //Now make a post request and get a promise back
    const responsePromise = apiServer.put(`/calcs`, calc, {headers:authHeader()});

    //We need to do some work here
    return responsePromise.then(response =>
        {//When the request returns
            //Get the user
            const userData = <CalcData>response.data;

            //Return just the user
            return userData;
        }
    );


}

/**
 * Gets the current calcs of the user
 * @param username
 * @param password
 * @returns
 */
function removeCalc(calc: CalcData) : Promise<ServerResponseStatus> {

    //Now make a post request and get a promise back
    const responsePromise = apiServer.delete(`/calcs/${calc.id}`,  {headers:authHeader()});

    //We need to do some work here
    return responsePromise.then(response =>
        {//When the request returns
            //Get the user
            const resposneStatus = <ServerResponseStatus>response.data;

            //Return just the user
            return resposneStatus;
        }
    );


}
//
// /**
//  * Logs out the current user from removing the local storage
//  */
// function logout() {
//     // remove user from local storage to log user out
//     localStorage.removeItem('currentUser');
// }
//
// /**
//  * This function add a new user to the service
//  */
// function registerNewUser(user: UserData): Promise<ServerResponseStatus>{
//     //Now make a post request and get a promise back
//     const responsePromise = apiServer.post('/users/new', user);
//
//     //Now convert it to a serverResponse
//     return responsePromise.then(any =>{
//         return new ServerResponseStatus(true, "blue");
//
//
//     });
//
//
// }




//
// function getAll() {
//     const requestOptions = {
//         method: 'GET',
//         headers: authHeader()
//     };
//
//     return fetch(`${config.apiUrl}/users`, requestOptions).then(handleResponse);
// }
//
// function getById(id) {
//     const requestOptions = {
//         method: 'GET',
//         headers: authHeader()
//     };
//
//     return fetch(`${config.apiUrl}/users/${id}`, requestOptions).then(handleResponse);
// }
//
// function register(user) {
//     const requestOptions = {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify(user)
//     };
//
//     return fetch(`${config.apiUrl}/users/register`, requestOptions).then(handleResponse);
// }
//
// function update(user) {
//     const requestOptions = {
//         method: 'PUT',
//         headers: { ...authHeader(), 'Content-Type': 'application/json' },
//         body: JSON.stringify(user)
//     };
//
//     return fetch(`${config.apiUrl}/users/${user.id}`, requestOptions).then(handleResponse);;
// }
//
// // prefixed function name with underscore because delete is a reserved word in javascript
// function _delete(id) {
//     const requestOptions = {
//         method: 'DELETE',
//         headers: authHeader()
//     };
//
//     return fetch(`${config.apiUrl}/users/${id}`, requestOptions).then(handleResponse);
// }
//
// function handleResponse(response) {
//     return response.text().then(text => {
//         const data = text && JSON.parse(text);
//         if (!response.ok) {
//             if (response.status === 401) {
//                 // auto logout if 401 response returned from api
//                 logout();
//                 location.reload(true);
//             }
//
//             const error = (data && data.message) || response.statusText;
//             return Promise.reject(error);
//         }
//
//         return data;
//     });
// }