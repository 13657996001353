import axios from 'axios';
import {authHeader} from "react-authentication";
import {ResourceInfo} from "../models/ResourceInfo";
import {NewSolveData, SolveData} from "../models/Solve";
import { apiConf } from "./apiConf"

export const solveLogService = {
    getAllSolveLogs,
    getSolveLogsFrom
    // register,
    // getAll,
    // getById,
    // update,
    // delete: _delete
};

// Create a default axios instance with the api
const apiServer = axios.create({
    baseURL: apiConf.serverURL
});


/**
 * Returns a list of all log packages for this solve
 * @param username
 * @param password
 * @returns
 */
function getAllSolveLogs<T>(solId:number, logType:string) : Promise<T[]> {

    //Get the headers
    const headers =authHeader();

    //Now make a post request and get a promise back
    const responsePromise = apiServer.get(`/solve/log/${solId}/${logType}`, {headers:headers});


    //We need to do some work here
    return responsePromise.then(response =>
        {//When the request returns
            //Get the user
            const data = <T[]>response.data;

            //Return just the user
            return data;
        }
    );
}

/**
 * Returns a list of log packages for this solve after the specified index
 * @param username
 * @param password
 * @returns
 */
function getSolveLogsFrom<T>(solId:number, logType:string, index:number) : Promise<T[]> {

    //Get the headers
    const headers = authHeader();

    //Now make a post request and get a promise back
    const responsePromise = apiServer.get(`/solve/log/${solId}/${logType}?method=from&from=${index}`, {headers:headers});


    //We need to do some work here
    return responsePromise.then(response =>
        {//When the request returns
            //Get the user
            const data = <T[]>response.data;

            //Return just the user
            return data;
        }
    );
}