import {SimulationPageProps} from "../SimulationPageProps";
import * as React from "react";
import {
    Container,
    Grid,
    GridColumn,
    Header,
    Segment,
    Sticky,
    Placeholder,
    Popup,
    SemanticCOLORS, Dropdown
} from "semantic-ui-react";
import {Composition, InletSpec, Selection} from "../../../../models/Simulation";
import InletSpecComponent from "./InletSpecComponent";

import {GeometryStlFile} from "react-authentication";
import {geometryService} from "../../../../services/geometry.service";
import HelpModal from "../Help";
// @ts-ignore
import StlPicker from "stl-face-selector-component"

import _ from "lodash"


interface LinkProps extends SimulationPageProps {

}

interface InletsPageState {
    // For Sticky Semantic UI component usage
    contextRef?: any;

    // For keeping track of loading geometry
    stlFile?: GeometryStlFile;
    fileLoaded: boolean;
    fileLoading: boolean;

    // For keeping track of which edit button is active
    activeIndex: number

    editPickedCellArrays?: number[][];
    editPickedAreaArrays?: number[];
}

interface InletSpecSelect extends InletSpec{
    //Name to to show under the select
    displayName:string;
    maxInstances?:number;
}

//Store a list of inlet templates
const inletTemplate:InletSpecSelect[]= [
    {
        "displayName":"Air Inlet",
        "name": "Unnamed Air Inlet",
        "flowRate": 0.0,
        "temperature":300.0,
        "angle":90,
        composition:{
            "N2":0.77,
            "O2":0.23,
        } as Composition,
        compositionType: "air",
        selection: {faces: [] as number[]} as Selection
    },
    {
        "displayName":"Fuel Inlet",
        "name": "Unnamed Fuel Inlet",
        "flowRate": 0.0,
        "temperature":300.0,
        "angle":90,
        composition:{
            "CH4":0.0
        } as Composition,
        compositionType: "fuel",
        selection: {faces: [] as number[]} as Selection
    },
    {
        "displayName":"Steam Inlet",
        "name": "Center Steam Inlet",
        "flowRate": 0.0,
        "temperature":300.0,
        "angle":0,
        composition:{
            "H2O":1.0
        } as Composition,
        compositionType: "steam",
        selection: {faces: [] as number[]} as Selection
    },
    {
        "displayName":"Upper Steam Inlet",
        "name": "Steam Inlet without detailed geometry description",
        "flowRate": 0.0,
        "temperature":300.0,
        "angle":30,
        composition:{
            "H2O":1.0
        } as Composition,
        compositionType: "detailedInlet",
        selection: {faces: [] as number[]} as Selection
    },
    {
        "displayName":"Pilot Inlet",
        "name": "Unnamed Pilot Inlet",
        "flowRate": 0.0,
        "temperature":300.0,
        "angle":90,
        composition:{
            "CO2":0.5,
            "H2O":0.5
        } as Composition,
        compositionType: "pilot",
        selection: {faces: [] as number[]} as Selection
    }
]


/**
 * This page shows the inlet details
 */
class Inlets extends React.Component<LinkProps, InletsPageState> {
    state = {
        contextRef: undefined,

        stlFile: undefined,
        fileLoaded: false,
        fileLoading: false,

        activeIndex: -1,

        editPickedCellArrays: [] as number[][],
        editPickedAreaArrays: [] as number []

    };

    private colorArrayBank = [0.0, 1.0, 0.3, 0.15, 0.8]; // Red, Blue, Yellow, Orangish, Light Blue ???
    private colorSemanticArray = ['red', 'blue', 'yellow', 'orange', 'teal'] as SemanticCOLORS[];
//    private colorSemanticArray = ['red', 'orange','yellow','olive','green', 'teal','blue','violet','purple','pink','brown','grey','black'] as SemanticCOLORS[];

    private defaultBaseColor = 0.66;

    // private pickedCellArrays: number[][] = [];

    constructor(props: LinkProps) {
        super(props);

        // Make sure the edit copy is synced with the application state at first
        let pickedCellArrays: number[][] = [];
        let pickedCellArea: number[] =[];
        for (let inlet of props.sim.parameters.inlets) {
            if (inlet && inlet.selection.faces) {
                pickedCellArrays.push(inlet.selection.faces)
            } else {
                pickedCellArrays.push([])
            }
            pickedCellArea.push(0.0)
        }

        this.state = {...this.state, editPickedCellArrays: pickedCellArrays}
        this.state = {...this.state, editPickedAreaArrays: pickedCellArea}

    }

    private startGeometryRequest = () => {
        geometryService.getUserGeometryStl(this.props.sim.parameters.geometry.data).then(file => {
            this.setState({stlFile: file, fileLoaded: true, fileLoading: false})
        })
        this.setState({fileLoading: true})
    }

    componentDidMount(): void {
        // Determine whether we can/need to fetch geometry from the server
        if (!this.state.fileLoaded && !this.state.fileLoading) {
            if(this.props.sim.parameters.geometry.data.id === undefined) {
                return
            }

            // Start the GET request for the STL file
            this.startGeometryRequest()
        }
    };

    handleContextRef = (contextRef: any) => this.setState({contextRef});

    changeActiveIndex = (newIndex: number) => {
        this.setState({activeIndex: newIndex})
    };

    updateCellList = (newCellId: number,newArea: number, prevArea: number) => {
        let {activeIndex} = this.state;

        // Get a copy of the application state inlets
        let inlets = JSON.parse(JSON.stringify(this.props.sim.parameters.inlets));
        const numberOfInlets = inlets.length;

        // Get a copy of the edit copy
        let {editPickedCellArrays} = this.state;
        let {editPickedAreaArrays} = this.state;

        // Make sure this matches the number of inlets
        if (editPickedCellArrays.length !== numberOfInlets) {
            editPickedCellArrays = []; //reset
            for (let inlet of inlets) {
                if (inlet.selection.faces) {
                    editPickedCellArrays.push(inlet.selection.faces)
                } else { // In case the inlet has no specified faces
                    editPickedCellArrays.push([])
                }
                editPickedAreaArrays.push(0.0)
            }
        }

        if (activeIndex >= 0) {
            let myActiveCellList = [...editPickedCellArrays[activeIndex]];

            console.log(myActiveCellList);

            if (_.includes(myActiveCellList, newCellId)) {
                let idx = myActiveCellList.indexOf(newCellId);
                if (idx > -1) {
                    myActiveCellList.splice(idx, 1);
                }
                editPickedAreaArrays[activeIndex]= prevArea-newArea;
                console.log("Inlets.tsx: removed cell from my active list (id: " + newCellId.toString(10) + ")");
            } else {
                myActiveCellList.push(newCellId);
                editPickedAreaArrays[activeIndex]= newArea+prevArea;
                console.log("Inlets.tsx: added cell to my active list (id: " + newCellId.toString(10) + ")");
            }

            editPickedCellArrays[activeIndex] = myActiveCellList;
            console.log(editPickedCellArrays);

            // for (let i = 0; i < numberOfInlets; i++) {
            //     inlets[i].selection.faces = [...editPickedCellArrays];
            // }

            this.setState({editPickedCellArrays: editPickedCellArrays});
            this.setState({editPickedAreaArrays: editPickedAreaArrays});

        }

    };

    resetCellSelection = () => {
        // Make sure the edit copy is synced with the application state at first
        let pickedCellArrays: number[][] = [];
        for (let inlet of this.props.sim.parameters.inlets) {
            if (inlet.selection.faces) {
                pickedCellArrays.push(inlet.selection.faces)
            } else {
                pickedCellArrays.push([])
            }
        }

        this.setState({editPickedCellArrays: pickedCellArrays})
    };

    //Add a button to submit case
    saveChanges = (newInlets: InletSpec[], removedIndex?: number) => {
        //Make a deep copy of the current app state
        let appState = JSON.parse(JSON.stringify(this.props.sim));

        //Update the inlets
        appState.parameters.inlets = newInlets;

        let {editPickedCellArrays} = this.state;
        if (removedIndex !== undefined) {
            editPickedCellArrays.splice(removedIndex, 1);
        }

        //Update the face selection of the inlets with current editCopy of selected faces
        for (let i = 0; i < editPickedCellArrays.length; i++) {
            appState.parameters.inlets[i].selection.faces = [...editPickedCellArrays[i]];
        }

        //Now Update the system
        this.props.updateSimulation(appState);

    };


    // Build button to add templated inlets
    buildAddButton = () => {
        const currentInlets = this.props.sim.parameters.inlets;

        return (
            <Popup
                trigger={
                    <Dropdown icon='add' direction="left" floated='right' button className='icon'>
                        <Dropdown.Menu>
                            <Dropdown.Header icon='tags' content='Select Inlet Template' />
                            <Dropdown.Divider />
                            {inletTemplate.map((displayInlet:InletSpecSelect) =>{
                                let canAddMore = true;

                                // Check if this inlet matches an existing one
                                currentInlets.forEach((spec:InletSpec ) =>{
                                    if (displayInlet.compositionType === spec.compositionType){
                                        // Do not allow this inlet to be added, which is disabled by zhou
                                       // canAddMore = false;
                                    }
                                });

                                return(
                                    <Dropdown.Item
                                        text={displayInlet.displayName}
                                        disabled={!canAddMore}
                                        onClick={() => this.saveChanges(
                                            [...this.props.sim.parameters.inlets,displayInlet])
                                        }
                                    />
                                );
                            })}
                        </Dropdown.Menu>
                    </Dropdown>}
                on={['hover','click']}
                position={'top right'}
                content={'Add an inlet to the simulation'}
            />
        );

    };

// <Button floated='right'
// icon='add'
// onClick={() => this.saveChanges([...inlets,
//     {
//         name: "",
//         flowRate: 0,
//         temperature: 0,
//         composition: {} as Composition, selection: {faces: [] as number[]} as Selection
//     } as InletSpec])
// }
// />

    private renderStlPicker(): React.ReactNode {
        if(this.state.fileLoading) {
            return (
                <Placeholder fluid>
                    <Placeholder.Image square />
                </Placeholder>
            )
        }
        return (
            <StlPicker stlFile={this.state.stlFile}
                       baseColor={this.defaultBaseColor}
                       colorArray={this.colorArrayBank}
                       pickedCellArrays={this.state.editPickedCellArrays}
                       activeIndex={this.state.activeIndex}
                       pickedCellCallback={this.updateCellList}
            />
        )
    }

    render(): React.ReactNode {
        const {contextRef} = this.state;

        //Get deep copy of the inlets
        let inlets = JSON.parse(JSON.stringify(this.props.sim.parameters.inlets));

        // //Make an array of picked cells that compiles each of the inlet selection faces
        // //reset each time this renders
        // this.pickedCellArrays = [];
        // for (let inlet of inlets) {
        //     if (inlet.selection.faces) {
        //         this.pickedCellArrays.push(inlet.selection.faces)
        //     } else {
        //         this.pickedCellArrays.push([])
        //     }
        // }

        // Determine whether we even have anything to load in the preview (as
        // opposed to checking whether stlFile is undefined, considering it may
        // be loaded later).
        const canShowPreview = this.props.sim.parameters.geometry.data.id !== undefined

        //Now return the table
        return (
            <Container style={{ padding: '5em 0em' }}>

                <Grid columns={2}>
                    <Grid.Column floated='left'>
                        <Header as='h2' style={{paddingLeft: '5px'}}>Inlet Specification</Header>
                    </Grid.Column>
                    <Grid.Column floated='right' textAlign='right'>
                        {this.buildAddButton()}
                        <HelpModal
                            icon="question"
                            floated='right'
                            text={<div><b>Make sure you have one of each of the following:</b><ul>
                                    <li>Air Inlet</li>
                                    <li>Fuel Inlet</li>
                                    <li>Steam Inlet</li>
                                    <li>Steam Inlet File</li>
                                    <li>Pilot Inlet</li>
                                    </ul></div>}
                        />
                    </Grid.Column>
                </Grid>

                <Grid columns={2}>
                    <GridColumn width={"six"}>
                        <div ref={this.handleContextRef}>

                            {inlets.map((inlet: InletSpec, index: number) => {

                                return (
                                    <InletSpecComponent
                                        key={index}
                                        index={index}
                                        inlet={inlet}
                                        updateInlet={(inlet?: InletSpec) => {
                                            //If an inlet is padded, updated it
                                            if (inlet) {

                                                //Make a copy of the list
                                                const list = [...inlets];

                                                //Now update the one
                                                list[index] = inlet;
                                                this.saveChanges(list);
                                            } else {
                                                //Remove it
                                                inlets.splice(index, 1);
                                                this.saveChanges(inlets, index);
                                            }
                                        }}
                                        changeActiveIndex={this.changeActiveIndex}
                                        resetSelection={this.resetCellSelection}
                                        color={this.colorSemanticArray[index]}
                                        area={this.state.editPickedAreaArrays[index]}
                                        editButtonDisabled={(this.state.activeIndex !== index) && (this.state.activeIndex !== -1)}
                                        expandable={true}
                                    />
                                );
                            })}

                            {/*<Rail position={"right"}>*/}
                            {/*<Sticky>*/}
                            {/*<Segment>*/}
                            {/*<Image src='https://react.semantic-ui.com/images/wireframe/image.png' />*/}
                            {/*</Segment>*/}
                            {/*</Sticky>*/}
                            {/*</Rail>*/}

                        </div>
                    </GridColumn>

                    <GridColumn width={"ten"}>
                        <Sticky context={contextRef} offset={47}>
                            <Segment>
                                <Header as='h3'>Specify Inlet Locations</Header>
                                {canShowPreview
                                    ? this.renderStlPicker()
                                    : <Header as='h4'>Select Geometry in the 'Geometry' tab first.</Header>
                                }
                            </Segment>
                        </Sticky>
                    </GridColumn>

                </Grid>

            </Container>
        );


    }


}

export default Inlets
