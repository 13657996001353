import axios from 'axios';
import {authHeader} from "react-authentication";
import {ResourceInfo} from "../models/ResourceInfo";
import {NewSolveData, SolveData} from "../models/Solve";
import { apiConf } from "./apiConf"

export const solveService = {
    getSolveResources,
    getSolvesForSim,
    getSolve,
    postNewSolve,
    downloadSolvePackage,
    stopSolve
    // register,
    // getAll,
    // getById,
    // update,
    // delete: _delete
};

// Create a default axios instance with the api
const apiServer = axios.create({
    baseURL: apiConf.serverURL
});


/**
 * Get the solve options
 * @param username
 * @param password
 * @returns
 */
function getSolveResources(simId:number) : Promise<ResourceInfo[]> {

    //Get the headers
    const headers =authHeader();

    //Now make a post request and get a promise back
    const responsePromise = apiServer.get(`/resources/${simId}`, {headers:headers});


    //We need to do some work here
    return responsePromise.then(response =>
        {//When the request returns
            //Get the user
            const data = response.data as ResourceInfo[];


            //Return just the user
            return data;
        }
    );
}
/**
 * Get the solve options
 * @param username
 * @param password
 * @returns
 */
function getSolvesForSim(simId:number) : Promise<SolveData[]> {

    //Get the headers
    const headers =authHeader();

    //Now make a post request and get a promise back
    const responsePromise = apiServer.get(`/solve/simulations/${simId}`, {headers:headers});


    //We need to do some work here
    return responsePromise.then(response =>
        {//When the request returns
            //Get the user
            const data = response.data as SolveData[];


            //Return just the user
            return data;
        }
    );
}

/**
 * Get the solve
 * @param username
 * @param password
 * @returns
 */
function getSolve(solId:number) : Promise<SolveData> {

    //Get the headers
    const headers =authHeader();

    //Now make a post request and get a promise back
    const responsePromise = apiServer.get(`/solve/${solId}`, {headers:headers});


    //We need to do some work here
    return responsePromise.then(response =>
        {//When the request returns
            //Get the user
            const data = response.data as SolveData;


            //Return just the user
            return data;
        }
    );
}

/**
 * Get the solve
 * @param username
 * @param password
 * @returns
 */
function stopSolve(solId:number) : Promise<SolveData> {

    //Get the headers
    const headers =authHeader();

    //Now make a post request and get a promise back
    const responsePromise = apiServer.delete(`/solve/${solId}`, {headers:headers});


    //We need to do some work here
    return responsePromise.then(response =>
        {//When the request returns
            //Get the user
            const data = response.data as SolveData;


            //Return just the user
            return data;
        }
    );
}

/**
 * post a new solve
 * @returns
 * @param solve
 */
function postNewSolve(solve:NewSolveData) : Promise<SolveData> {

    //Get the headers
    const headers =authHeader();

    //Now make a post request and get a promise back
    const responsePromise = apiServer.post(`/solve`,solve,  {headers:headers});


    //We need to do some work here
    return responsePromise.then(response =>
        {//When the request returns
            //Get the user
            const data = response.data as SolveData;


            //Return just the user
            return data;
        }
    );
}

/**
 * Download the entire solve package as a zip file
 * @param loc
 */
function downloadSolvePackage(solId:number) : Promise<Blob> {

    //Get the headers
    const headers =authHeader();

    //Now make a post request and get a promise back
    const responsePromise = apiServer.get(`/solve/bundle/${solId}`,  {headers:headers,responseType: 'blob'});


    //We need to do some work here
    return responsePromise.then(response =>
        {//When the request returns
            //Get the response header
            const contentDisposition = response.headers['content-disposition'];
            console.log("contentDisposition: " + contentDisposition);

            return response.data
        }
    );


}