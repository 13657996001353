import { calcService } from '../services';
import { success, error } from 'action-alert';
import {Action, ActionCreator, Dispatch} from 'redux';
import {ThunkAction} from 'redux-thunk';
import {CalcData} from "../models/Calc";


export const searchConstants = {
    SEARCH_CALCS: 'SEARCH_CALCS',
    SEARCH_TERM: 'SEARCH_TERM',
};

export const searchActions = {
    updateSearch,
};

/**
 * Action used to search
 * @param username
 * @param password
 * @returns {Function}
 */
function updateSearch(term:string): ThunkAction<any, any,any, any> {
    //Return a function that will be called by dispatch
    return (dispatch:Dispatch<Action>) => {
        //Dispatch the action of attempting to login
        dispatch({
            type: searchConstants.SEARCH_TERM,
            payload:term
        });

        //Ask the user service to login
        calcService.searchCalcs(term)
            .then(
                //If successful a user will be returned
                calcs  => {
                    //dispatch a login success
                    dispatch({
                        type: searchConstants.SEARCH_CALCS,
                        payload: calcs
                    });
                },
                //If there was an error, dispatch a login failure and alert the user why
                errorResponse => {
                    //Dispatch the error
                    try {
                        dispatch(error(errorResponse.response.data.message));
                    }catch(e){
                        dispatch(error(errorResponse.toString()));

                    }

                }
            );
    };

}