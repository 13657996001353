import * as React from "react";
import {LogPackageData} from "../../../models/LogPackage";
import {solveLogService} from "../../../services/solveLog.service";
import {authHeader} from "react-authentication";

//This is the basic requirements for the props for classes that are wrapped.  If the data is null it is still loading
export interface InjectedLogDataProps<T extends LogPackageData> {
    pkgList?: T[];
    logType:string;
    solId:number;
    error?:string;
    updateInterval:number;

}
const URL = "ws://localhost:9000/solve/websocket/";
var ws: WebSocket;
//this is the state of the wrapper class.  It is updated when the data is updated from the server
interface WrapLogDataState<T extends LogPackageData> {
    data?: T[];
    error?:any;
    fromIndex:number;
}

const wrapLogDisplay = <T extends LogPackageData, P extends InjectedLogDataProps<T>>(Component: React.ComponentType<P>) =>

    class MakeWrapLogger extends React.Component<P, WrapLogDataState<T>> {
        state = {data : undefined , error:undefined, fromIndex:0 }

        //Store the current timeout
        private timeOutIndex: any = -1;


        //Shared call to update the current list
        updatePackageList = (newList:T[], fallback:Boolean ) => {
            //Get the current max
            let currentFrom = this.state.fromIndex;

            //March over the new to find the max component
            for( let pkg of newList){
                currentFrom = Math.max(currentFrom, pkg.index);
            }


            //If the current data is undefined just set
            if (this.state.data !== undefined){
                //Get the current list
                const currentList = this.state.data! as T[];

                //Else append to the list
                let combinedList = [...currentList, ...newList];

                this.setState({
                    data: combinedList,
                    fromIndex: currentFrom
                })

            }else {
                this.setState({
                    data: newList,
                    fromIndex: currentFrom
                })

            }

            //If true, use the fallback
            if(fallback) {
                //Now set this to update again
                this.timeOutIndex = setTimeout(this.getNewData, this.props.updateInterval)
            }
        }

        //Update only the latest
        getNewData = () =>{
            let dataPromise = solveLogService.getSolveLogsFrom<T>(this.props.solId, this.props.logType, this.state.fromIndex)

            //When it comes back use it
            dataPromise.then(
                //If successful html will be returned
                list => {
                    //Update the state
                    this.updatePackageList(list, true)
                },
                //If there was an error, show to the user
                errorResponse => {
                    this.setState({error:errorResponse.toString()})
                }

            );

        }

        //When the component mounts pull the initial data
        componentDidMount(): void {
            // @ts-ignore
            const token = (authHeader().Authorization).replace(" ", "_Space_");
            //const header = JSON.stringify({headers: token});
            ws = new WebSocket(URL+ this.props.solId + "/" + this.props.logType, [token, "proc1"]);
            console.log("Opening web socket on ->" + URL + this.props.solId + "/" + this.props.logType);
            ws.onerror = () =>{
                //Now get all of the data
                console.log("Falling back from websocket");
                let dataPromise = solveLogService.getAllSolveLogs<T>(this.props.solId, this.props.logType);

                //When it comes back use it
                dataPromise.then(
                    //If successful html will be returned
                    list => {
                        //Update the state
                        this.updatePackageList(list, true)
                    },
                    //If there was an error, show to the user
                    errorResponse => {
                        this.setState({error:errorResponse.toString()})
                    }
                );
            }
            ws.onopen = () => {
                console.log('WEBSOCKET OPEN')
            };
            ws.onmessage = evt => {
                if(evt.data)
                console.log('MESSAGE received =>', evt.data);
                this.updatePackageList(JSON.parse(evt.data), false);//by parsing the data we get the data array we're looking for
            };
            ws.onclose = () => {
                console.log('WEBSOCKET CLOSED')
            };
        };

        componentWillUnmount(): void {

            ws.close(3005, "Unmounting component");
            clearTimeout(this.timeOutIndex);
        }



        render() {
            return (
                <Component
                    {...this.props as P}
                    pkgList={this.state.data}
                    error={this.state.error}

                />
            );
        }
    };




export default wrapLogDisplay;