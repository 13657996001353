import React from 'react';

import { Segment, Container, Header} from "semantic-ui-react";
import ApplicationState from "../../state/ApplicationState";
import {connect} from "react-redux";
import StaticComponent from "./StaticComponent";
import {UserData} from "react-authentication";

//Define the expected props
interface LinkProps  {
    //See if the user is logged in
    user?: UserData

}



class HelpViewer extends React.Component<LinkProps> {




    /**
     * Re-render every time this is called
     * @returns {*}
     */
    render() {
        // If a user is logged in
        if(this.props.user){
            return (
                <Container>
                    <Segment>
                        <StaticComponent pagePath={"simulation_inlet_help"} public={false}/>
                    </Segment>
                    <p><br/><br/></p>
                </Container>
            );

            // If a user is not logged in
        }else{
            return(
                <Container>
                    <Segment>
                        <Header as={'h1'}>Contact us</Header>
                        If you would like to use this product, please contact us at flares@reaction-eng.com
                    </Segment>
                    <p><br/><br/></p>
                </Container>
            );
        }

    }
}

/**
 * Map from the global state to things we need here
 * @param state
 * @returns {{authentication: WebAuthentication}}
 */
function mapStateToProps(state:ApplicationState,myProps:LinkProps ):LinkProps {
    return {
        ...myProps,
        user:state.authentication.loggedInUser,
    };
}


export default connect (
    mapStateToProps
)(HelpViewer);
