import {Action} from 'action-alert'
import SimState from "../state/SimState";
import {simConstants} from "../actions/simulations.actions";
import {SimulationData} from "../models/Simulation";
import SolveState from "../state/SolveState";
import {SolveData} from "../models/Solve";
import {solveConstants} from "../actions/solve.actions";

/**
 * The alert reducer maintains a list of alerts
 * @param state
 * @param action
 * @returns {*}
 */
export function solves(state:SolveState= {solves:{} as { [simId: number]: SolveData[]; }}, action:Action): SolveState {

    //Ok, we now know that it is an alert action
    switch (action.type) {
        case solveConstants.UPDATE_SOLVES: {
            //Get the updated sim
            const solveList = action.payload.list as SolveData[];
            const simId = action.payload.simId as number;

            //Update the sim
            let solves = {...state.solves} as { [simId: number]: SolveData[]; }
            solves[simId] = solveList;

            //Add the new success to the list
            return {
                ...state,
                solves
            }
        }
        case solveConstants.ADD_SOLVE: {

            //Get the payplosd as a new solve
            const newSolve = action.payload as SolveData;

            //Update the sim
            const simId = newSolve.simId;

            //Get the current solves copy
            let solves = {...state.solves} as { [simId: number]: SolveData[]; }

            //Add the item
            if(solves[simId]) {
                solves[simId] = [...solves[simId], newSolve];
            }else{
                solves[simId] = [newSolve];

            }

            //Add the new success to the list
            return {
                ...state,
                solves
            }
        }


        default:
            return state
    }
}