import {Icon} from "semantic-ui-react";
import {MenuItem} from "rei-react-ui";
import * as React from "react";
import TemplateList from "./template/TemplateList";

export const globalMenuItems: MenuItem[] =
    [
        {
            name: <TemplateList/>,
            tooltip:"Flare Case Setup"
        },
        {
            name: <Icon size='large' name='list alternate outline'/>,
            to:'/simulation',
            tooltip:"List of Previous Simulations"

        },
        {
            name:<Icon size='large' name='th large'/>,
            to:'/geometry',
            tooltip:"Library of Flare Tip Geometry"
        }
    ]






