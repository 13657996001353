import React from 'react';
import {Card, Icon, Image, Placeholder} from "semantic-ui-react";
import {TemplateData} from "../../../models/Template";
import {templatesService} from "../../../services/templates.service";



//Define the expected props
interface Props{
    //Define the props we expect
    template: TemplateData
    handleSelect:() => any;
}


//Define the expected props
interface TemplateState{
    //Define the props we expect
    html: string

}


class TemplateItem extends React.Component<Props, TemplateState> {
    state ={html:""};

    componentDidMount(){
        templatesService.getTemplateInfo(this.props.template).
        then(
            //If successful html will be returned
            html => {
                //Update the state
                this.setState({html:html})
            },
            //If there was an error, show to the user
            errorResponse => {
                //Dispatch the error
                try {
                    this.setState({html:errorResponse.response.data.message});
                }catch(e){
                    this.setState({html:errorResponse.toString()});

                }

            }
        );



    }


    render() {
        return (
            <Card onClick={() => {
                this.props.handleSelect();
            }}>
                <Image centered src={this.props.template.ImgUrls[0]}/>
                <Card.Content>
                    <Card.Header>{this.props.template.name}</Card.Header>
                    <Card.Description>
                        {/*Show a place holder if we dont' have the html yet*/}

                        {this.state.html.length == 0 &&
                        <Placeholder>
                            <Placeholder.Line/>
                            <Placeholder.Line/>
                            <Placeholder.Line/>
                            <Placeholder.Line/>
                            <Placeholder.Line/>
                        </Placeholder>
                        }

                        {this.state.html.length > 0 &&
                        <div dangerouslySetInnerHTML={{__html: this.state.html}}/>
                        }

                    </Card.Description>
                </Card.Content>
            </Card>
        );
    }
}

export default TemplateItem