import React from "react";
import {Modal} from "semantic-ui-react";
import {Button} from "semantic-ui-react";
import StaticComponent from "../../static-pages/StaticComponent";

interface LinkProps {
    icon:any;
    floated: any;
    text:any;
}

interface HelpState {
    modalOpen:boolean
}

class HelpModal extends React.Component<LinkProps, HelpState> {

    constructor(props: LinkProps) {
        super(props);
    }

    render() {
        return (
            <Modal size='mini'
                trigger={
                    <Button
                        icon={this.props.icon}
                        floated={this.props.floated}
                    />
                }
            >
                <Modal.Content scrolling>
                    {this.props.text}
                </Modal.Content>
            </Modal>
        );
    }
}

export default HelpModal;