// import {SimulationPageProps} from "../../simulation/SimulationPageProps";
import React, {Component, RefObject} from "react";

import {Stage, Layer, Circle, Text, Line, KonvaNodeEvents, Group, StageProps} from 'react-konva';
import Konva from 'konva';

import {
    Button,
    Container,
    Header,
    Input,
    InputOnChangeData,
    List,
    Segment,
    Table
} from "semantic-ui-react";

import {GeometryMetaData, Point} from "react-authentication"
import {bool} from "prop-types";

const pStyle = {
    margin: '1px',
}

interface GeometryDrawerIncomingProps {
    blueprints: Point[]
    gridSpacing: number
    isEditable: boolean
    blueprintUpdater: (newBlueprint: Point, index: number) => void
    setHighlightedPoint: (index: number) => void
}

// I think the component should be stateless if possible
interface GeometryDrawerState {
    stageSize: StageSize
    stageScale: number
    stageX: number
    stageY: number
}

interface StageSize {
    width: number
    height: number
}

/**
 * This component used Konva React to draw a side view of a shape defined by blueprints as basic 2D shapes
 */
class GeometryDrawer extends React.Component<GeometryDrawerIncomingProps, GeometryDrawerState> {

    state = {stageScale: 1, stageX: 0, stageY: 0, stageSize: {width: 300, height: 400}}

    readonly drawingDiv: RefObject<HTMLDivElement>

    private drawingMargin = 10;
    private verticalDivisions = 10;
    private pixelsPerUnit = 300; //pixels per meter


    constructor(props: GeometryDrawerIncomingProps) {
        super(props)

        //Create a reference to the drawingDiv
        this.drawingDiv = React.createRef();
    }

    getLinePointsFromBlueprints = (blueprints: Point[]): number[] => {

        //Get a deep copy to be safe
        let myPoints = JSON.parse(JSON.stringify(blueprints))

        //Negate the y values to get drawing on canvas
        myPoints = myPoints.map((value: Point) => {
            return {x: value.x, y: -value.y}
        })

        //Create the list of points how Konva.js wants it
        let konvaPoints: number[] = []
        for (let p of myPoints) {
            konvaPoints.push(p.x)
            konvaPoints.push(p.y)
        }
        return konvaPoints
    }

    handleMouseEnterStage = (event: any) => {
        document.body.style.cursor = 'move'
        console.log("Mouse Entered Stage")
    }
    handleMouseLeaveStage = (event: any) => {
        document.body.style.cursor = 'default'
        console.log("Mouse Left Stage")
    }

    handleMouseEnterAnchor = (e: any, index: number) => {
        if (this.props.isEditable) {
            document.body.style.cursor = 'pointer'
        } else {
            document.body.style.cursor = 'default'
        }
        e.target.to({
            duration: 0,
            fill: '#e542f4',
            strokeWidth: 2
        })
        this.props.setHighlightedPoint(index)
        // console.log('Moused over circle')
    }

    handleMouseLeaveAnchor = (e: any) => {
        document.body.style.cursor = 'move'
        e.target.to({
            duration: 0,
            fill: "#89b717",
            strokeWidth: 1
        })
        this.props.setHighlightedPoint(-1)
        // console.log('Moused out of circle')
    }

    handleDragAnchor = (e: any, index: number, isFirstOrLast: boolean) => {
        // console.log((e))

        let newPos = {x: 0, y: 0} as Point
        let pos;
        if (isFirstOrLast) {
            e.target.setAttrs({
                x: 0
            }); //look into setting the attribute to stop the point from getting all skeewampus.
            pos = {
                x: 0,
                y: e.target.getAttrs().y
            } as Point //force get the last point
        } else {
            pos = {
                x: e.target.getAttrs().x,
                y: e.target.getAttrs().y
            } as Point //force get the last point
        }
        // console.log(pos)

        // let stagePos = {x: e.target.getStage().getAttrs().x,
        //                 y: e.target.getStage().getAttrs().y} as Point;

        // if (stagePos.x == undefined || stagePos.y == undefined) {
        //     stagePos = {x: 0, y: 0} as Point;
        // }
        // console.log(stagePos)

        //Scale point back to physical coordinates
        newPos.x = pos.x / this.pixelsPerUnit
        newPos.y = -pos.y / this.pixelsPerUnit
        // console.log(newPos)

        this.props.blueprintUpdater(newPos, index)

    }

    computePixelConversion = (numberOfVerticalDivisions: number, stageHeight: number): number => {
        return (stageHeight - 2 * this.drawingMargin) / (this.props.gridSpacing * numberOfVerticalDivisions)
    }

    checkDrawingDivSize = () => {
        if (this.drawingDiv.current != undefined) {
            const {width, height} = this.drawingDiv.current.getBoundingClientRect()
            // if ( width != this.state.stageSize.width ) // Taken care of in shouldComponentUpdate lifecycle method now
            this.setState({stageSize: {width: width, height: height}})
        } else {
            console.log("Drawing <div> not ready")
        }
        // console.log("Window size changed")
    }

    componentDidMount() {
        this.checkDrawingDivSize()
        this.centerEditor(this.props.blueprints);
        window.addEventListener("resize", this.checkDrawingDivSize);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.checkDrawingDivSize);
    }

    shouldComponentUpdate(nextProps: GeometryDrawerIncomingProps, nextState: GeometryDrawerState) {
        return (nextState.stageScale != this.state.stageScale || nextState.stageSize.width != this.state.stageSize.width || nextProps.blueprints != this.props.blueprints || nextProps.gridSpacing != this.props.gridSpacing)
    }

    scrolling = (e: any) => {
        e.evt.preventDefault(); //stop the page from scrolling
        let scaleBy: number = 1.05; //how much we want to scale by
        const stage = e.target.getStage();
        let currentStageScale = this.state.stageScale;
        let mouseLoc = {
            x: stage.getPointerPosition().x / currentStageScale - stage.x() / currentStageScale,
            y: stage.getPointerPosition().y / currentStageScale - stage.y() / currentStageScale
        }


        let newScale = e.evt.deltaY < 0 ? currentStageScale * scaleBy : currentStageScale / scaleBy;
        stage.scale({x: newScale, y: newScale})
        this.setState({
            stageScale: newScale, stageX:
                -(mouseLoc.x - stage.getPointerPosition().x / newScale) * newScale,
            stageY:
                -(mouseLoc.y - stage.getPointerPosition().y / newScale) * newScale
        })
    }

    //centers the editor
    centerEditor = (blueprints: Point[]) => {
        console.log("Centering Editor")
        //Get a deep copy to be safe
        let myPoints = JSON.parse(JSON.stringify(blueprints));


        let Xs: number[] = myPoints.map((value: Point) => {
            return value.x
        });
        let Ys: number[] = myPoints.map((value: Point) => {
            return value.y
        });


        let largestX: number = Math.max(...Xs);
        let smallestX: number = Math.min(...Xs);
        let largestY: number = Math.max(...Ys);
        let smallestY: number = Math.min(...Ys);

        let absSumOfY: number = Math.abs(largestY) + Math.abs(smallestY);
        let absSumOfX: number = Math.abs(largestX) + Math.abs(smallestX);
        //let newScale: number = parseFloat((((absSumOfY > absSumOfX)?absSumOfY:absSumOfX)/10).toFixed(2));
        //default is that the grid scales that the height is gridspacing * 10.5
        let newScale = (this.props.gridSpacing/((absSumOfY > absSumOfX)?absSumOfY:absSumOfX))* 10; //get biggest of whatever direction has the biggest summation, and divide by girdSpacing.

        console.log("Stage: ", "x: ", this.state.stageX, "y: ", this.state.stageY)
        console.log("Stage scale: ", this.state.stageScale, "new scale: ", newScale)

        this.setState({
            stageX: (-((largestX - (absSumOfX / 2))) * (this.pixelsPerUnit) + this.state.stageSize.width * (1 - newScale)) * newScale,
            stageY: (((largestY - absSumOfY / 2) * (this.pixelsPerUnit)) - (this.state.stageSize.height / 2.0)+(this.state.stageSize.height)*(1 - newScale) + this.drawingMargin) * newScale,
            stageScale: ( newScale )
        });
    };


    // componentDidUpdate(prevProps: Readonly<GeometryDrawerIncomingProps>, prevState: Readonly<GeometryDrawerState>, snapshot?: any): void {
    //     // Start the GET request for the STL file
    //     this.setState({})
    // }

    render(): React.ReactNode {
        this.pixelsPerUnit = this.computePixelConversion(this.verticalDivisions, this.state.stageSize.height);
        const horizontalDivisions = Math.ceil((this.state.stageSize.width / 2 - this.drawingMargin) / (this.pixelsPerUnit * this.props.gridSpacing));
        const canvasSize = 3; //factor to increase the canvas area w/ gridlines by


        return (
            <Container>
                <Segment>
                    <div ref={this.drawingDiv}
                         onMouseEnter={this.handleMouseEnterStage}
                         onMouseLeave={this.handleMouseLeaveStage}
                    >
                        <Stage width={this.state.stageSize.width}
                               height={this.state.stageSize.height}
                               draggable={true}
                               scaleX={this.state.stageScale}
                               scaleY={this.state.stageScale}
                               onWheel={this.scrolling}
                               x={this.state.stageX}
                               y={this.state.stageY}
                        >

                            <Layer>
                                {/* Make the base grid */}
                                <Group>
                                    { //horizontal grid lines
                                        [...Array(this.verticalDivisions * canvasSize + 2)].map((value: Point, index: number) => {
                                            let yloc = index * this.pixelsPerUnit * this.props.gridSpacing;
                                            return (
                                                <Group key={index}>
                                                    <Line
                                                        key={index + 100}
                                                        points={[-this.state.stageSize.width * (canvasSize + 0.5), yloc, this.state.stageSize.width * (canvasSize + 0.5), yloc]}
                                                        strokeWidth={0.25}
                                                        stroke='#666'
                                                        offsetY={-this.state.stageSize.height + this.drawingMargin}
                                                    />
                                                    {(index > 0) && //Don't draw center gridline twice
                                                    <Line
                                                        key={index + 200}
                                                        points={[-this.state.stageSize.width * (canvasSize + 0.5), -yloc, this.state.stageSize.width * (canvasSize + 0.5), -yloc]}
                                                        strokeWidth={0.25}
                                                        stroke='#666'
                                                        offsetY={-this.state.stageSize.height + this.drawingMargin}
                                                    />
                                                    }
                                                </Group>
                                            )
                                        })
                                    }
                                    { //vertical grid lines
                                        [...Array(horizontalDivisions * canvasSize * 2 - 1)].map(
                                            (value: Point, index: number) => {
                                                let xloc = index * this.pixelsPerUnit * this.props.gridSpacing;
                                                return (
                                                    <Group key={index}>
                                                        <Line
                                                            key={index + 100}
                                                            points={[xloc, -this.state.stageSize.height * (canvasSize - 1), xloc, this.state.stageSize.height * (canvasSize + 1)]}
                                                            strokeWidth={0.25}
                                                            stroke='#666'
                                                            offsetX={-this.state.stageSize.width / 2}
                                                        />
                                                        {(index > 0) && //Don't draw center gridline twice
                                                        <Line
                                                            key={index + 200}
                                                            points={[-xloc, -this.state.stageSize.height * (canvasSize - 1), -xloc, this.state.stageSize.height * (canvasSize + 1)]}
                                                            strokeWidth={0.25}
                                                            stroke='#666'
                                                            offsetX={-this.state.stageSize.width / 2}
                                                        />
                                                        }
                                                    </Group>
                                                )
                                            })
                                    }
                                </Group>

                                {/*main axes*/}
                                <Group>
                                    <Line
                                        points={[-this.state.stageSize.width * (canvasSize + 0.5), 0, this.state.stageSize.width * (canvasSize + 0.5), 0]}
                                        strokeWidth={0.5}
                                        stroke='black'
                                        offsetY={-this.state.stageSize.height + this.drawingMargin}
                                    />
                                    <Line
                                        points={[0, -this.state.stageSize.height * (canvasSize - 1), 0, this.state.stageSize.height * (canvasSize + 1)]}
                                        strokeWidth={0.5}
                                        stroke='black'
                                        offsetX={-this.state.stageSize.width / 2}
                                    />
                                </Group>

                                <Line //axis of revolution
                                    points={[0, this.drawingMargin, 0, this.state.stageSize.height - this.drawingMargin]}
                                    stroke={'black'}
                                    dash={[10, 10]}
                                    strokeWidth={2}
                                    lineCap={'butt'}
                                    lineJoin={'butt'}
                                    offsetX={-this.state.stageSize.width / 2}
                                    offsetY={0}
                                />

                                <Line //user specified profile
                                    points={this.getLinePointsFromBlueprints(this.props.blueprints).map(p => {
                                        return p * this.pixelsPerUnit
                                    })}
                                    stroke={'blue'}
                                    strokeWidth={3}
                                    lineCap={'butt'}
                                    lineJoin={'butt'}
                                    offsetX={-this.state.stageSize.width / 2}
                                    offsetY={-this.state.stageSize.height + this.drawingMargin}
                                />
                                {/* Add in the points (eventually anchor points?) */}
                                {this.props.blueprints.map((value: Point, index: number) => {

                                    let isFirstOrLast: boolean = ((index == 0) || (this.props.blueprints.length - 1 == index)) ? true : false;

                                    return (
                                        <Circle
                                            key={index}
                                            x={value.x * this.pixelsPerUnit}
                                            y={-value.y * this.pixelsPerUnit}
                                            radius={5}
                                            fill="#89b717"
                                            // opacity={0.8}
                                            draggable={this.props.isEditable}
                                            offsetX={-this.state.stageSize.width / 2}
                                            offsetY={-this.state.stageSize.height + this.drawingMargin}
                                            onMouseEnter={(e: Konva.KonvaEventObject<MouseEvent>) => this.handleMouseEnterAnchor(e, index)}
                                            onMouseOut={this.handleMouseLeaveAnchor}
                                            onDragMove={(e: Konva.KonvaEventObject<MouseEvent>) => this.handleDragAnchor(e, index, isFirstOrLast)}
                                            stroke={'#666'}
                                            strokeWidth={1}
                                        />
                                    )
                                })}
                            </Layer>
                        </Stage>

                    </div>
                    <Button onClick={() =>{this.centerEditor(this.props.blueprints)}}>Reset Focus</Button>
                </Segment>
                {/* <Segment>
                    <Header as='h3'>Things will get drawn above. This is just the list of points passed in for debbugging.</Header>
                    {this.props.blueprints.map((b: Point, index: number) => { return ( <p key={index} style={pStyle}>x: {b.x}, y:{b.y}</p> ) }) }
                </Segment> */}
            </Container>
        );

    }

}

export default GeometryDrawer