import * as React from "react";
import {
    Button,
    Header,
    Input,
    InputOnChangeData,
    Label, List, Modal,
    Segment, SemanticCOLORS,
    Table, Popup
} from "semantic-ui-react";
import {InletSpec} from "../../../../models/Simulation";
import {NumberInput} from "../../../inputs/NumberInput";
import NumberTable from "../../../inputs/NumberTable";
import LimitedInputTable  from "../../../inputs/LimitedInputTable";

interface LinkProps{
    //Add an update section
    updateInlet: (inlet?: InletSpec ) => any

    //Pass in the index and key
    inlet:InletSpec;
    index: number;

    //Ability to change the active index of the parent
    changeActiveIndex: (newIndex: number) => void

    //pass in callback to clear the selected faces if cancel is pressed
    resetSelection: () => void

    color: SemanticCOLORS
    area: number

    editButtonDisabled: boolean;

    //Store if the component is expandable
    expandable:boolean;

}

//Keep a local state for the updates
interface InletSpecState {
    //Store the edit version
    editCopy?: InletSpec;
    deleteModalOpen: boolean;
}

/**
 * This card shows the animal details
 */
class InletSpecComponent extends React.Component<LinkProps, InletSpecState> {
    state = {
        editCopy: undefined,
        deleteModalOpen:false
    };

    private readonly index: number;

    constructor(props: LinkProps) {
        super(props);
        this.index = props.index
    }

    //Add a button to submit case
    saveChanges = () =>{
        if(this.state.editCopy !== undefined) {
            this.props.updateInlet(this.state.editCopy!)
        }
        //And cancel
        this.setState({editCopy:undefined});
        this.props.changeActiveIndex(-1);
    }

    cancelChanges =() =>{
        this.setState({editCopy:undefined});
        this.props.changeActiveIndex(-1);
        this.props.resetSelection();
    }

    enterEdit =() =>{
        //Set the edit mode
        this.setState({editCopy:this.props.inlet});
        this.props.changeActiveIndex(this.index);
    }

    deleteInlet = () => {
        this.props.updateInlet(undefined);
        this.setState({editCopy: undefined})
        this.props.changeActiveIndex(-1);
        this.setState({deleteModalOpen:false})
    }


    render(): React.ReactNode {

        //Get the pull copy
        let inlet =  this.props.inlet;
        if (this.state.editCopy !== undefined)
            inlet = this.state.editCopy!;

        //See if this is editable
        const isEditable = this.state.editCopy !== undefined;
        const{
            deleteModalOpen
        } = this.state

        //Now return the table
        return (
            <div>
                <Segment attached='top'>
                    {/*If this is editable, allow removing it*/}
                    <Header as='h2' >
                        <Input fluid type='text'
                               disabled={!isEditable}
                               value={inlet.name}
                               onChange={({},data: InputOnChangeData) =>
                                   this.setState({editCopy:{...inlet,name:data.value}})}
                        />

                    </Header>
                    <Label attached='bottom right' color={this.props.color}/>
                </Segment>
                <Table attached>
                    <Table.Row>
                        <Table.Cell><b>Flow Rate</b></Table.Cell>
                        <Table.Cell>
                            <NumberInput
                                fluid
                                units='kg/s'
                                value={inlet.flowRate}
                                disabled={!isEditable}
                                onChange={(v, data:InputOnChangeData) =>
                                    this.setState({editCopy:{...inlet, flowRate:parseFloat(data.value)}})}
                            />
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            <Popup
                                trigger={
                                    <b>Flow Angle</b>
                                }
                                on={['hover']}
                                position={'top left'}
                                content={'Flow Angle is relative to the up direction'}
                            />
                        </Table.Cell>
                        <Table.Cell>
                            <NumberInput
                                fluid
                                units='°'
                                value={inlet.angle}
                                disabled={!isEditable}
                                onChange={(v, data:InputOnChangeData) =>
                                    this.setState({editCopy:{...inlet, angle:parseFloat(data.value)}})}
                            />
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row
                        active={"steam" == inlet.compositionType || "detailedInlet" == inlet.compositionType}
                    >

                        <Table.Cell><b>Temperature</b></Table.Cell>
                        <Table.Cell>
                            <NumberInput
                                fluid
                                units='K'
                                value={inlet.temperature}
                                disabled={"steam" == inlet.compositionType ||
                                    "detailedInlet" == inlet.compositionType ||!isEditable}
                                onChange={(v, data:InputOnChangeData) =>
                                    this.setState({editCopy:{...inlet, temperature:parseFloat(data.value)}})}
                            />
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row active>
                        <Table.Cell><b>Selected Area</b></Table.Cell>
                        <Table.Cell>
                            <NumberInput
                                fluid
                                units='m^2'
                                value={(this.props.area != undefined) ? this.props.area.toFixed((3)) : ""}
                                disabled={true}
                            />
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell colSpan={'2'}>
                        <LimitedInputTable
                            table={inlet.composition}
                            type={inlet.compositionType}
                            paramLabel={"Species"}
                            valueLabel={"Mass Fraction"}
                            updateTable={(tbl:{ [form: string]: number; }) =>
                                this.setState({editCopy:{...inlet, composition:tbl}})}
                            editable={isEditable}
                            expandable={this.props.expandable}
                        />
                        </Table.Cell>
                    </Table.Row>
                </Table>
                <Segment attached='bottom'>
                    {/*Add a button bar to switch between the modes*/}
                    <Button.Group>
                        {isEditable &&
                        <Button secondary onClick={this.cancelChanges}>Cancel</Button>
                        }
                        {isEditable &&
                        <Button primary onClick={this.saveChanges}>Save Changes</Button>
                        }
                        {!isEditable &&
                        <Button disabled={this.props.editButtonDisabled} onClick={this.enterEdit}>Edit</Button>
                        }
                        {isEditable &&
                            <Modal
                                basic
                                size={'mini'}
                                onClose={() => this.setState({deleteModalOpen:false})}
                                onOpen={() => this.setState({deleteModalOpen:true})}
                                open={deleteModalOpen}
                                trigger={
                                    <Button negative>Delete</Button>
                                }
                            >
                                <Modal.Header>
                                    Confirm Deletion
                                </Modal.Header>
                                <Modal.Content>
                                    <Modal.Description>
                                        <p>
                                            Are you sure that you want to permanently remove this inlet?
                                        </p>
                                    </Modal.Description>
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button
                                        color={'red'}
                                        onClick={this.deleteInlet}
                                    >
                                        Yes
                                    </Button>
                                    <Button
                                        color={'black'}
                                        onClick={() => this.setState({deleteModalOpen:false})}
                                    >
                                        No
                                    </Button>
                                </Modal.Actions>
                            </Modal>
                        }
                    </Button.Group>
                </Segment>
                <br/>
            </div>
        );
    }
}

export default InletSpecComponent
