import {SimulationPageProps} from "../SimulationPageProps";
import * as React from "react";
import {Button, Container, Header, Input, InputOnChangeData, List, Segment, Table} from "semantic-ui-react";
import {NumberInput} from "../../../inputs/NumberInput";
import {Environment as EnviromentData} from "../../../../models/Simulation";

interface LinkProps extends SimulationPageProps{

}

interface EnvironmentState {

    //Store the edit version
    editCopy?: EnviromentData;


}

/**
 * This card shows the animal details
 */
class Environment extends React.Component<LinkProps, EnvironmentState> {
    state = {editCopy: undefined };


    //Add a button to submit case
    saveChanges = () =>{
        if(this.state.editCopy != undefined) {
            let submitCopy = this.props.sim;
            submitCopy.parameters.environment = this.state.editCopy!;

            this.props.updateSimulation(submitCopy)
        }
        //And cancel
        this.cancelChanges()
    };

    cancelChanges =() =>{
        this.setState({editCopy:undefined});


    };

    enterEdit =() =>{
        //Set the edit mode
        this.setState({editCopy:this.props.sim.parameters.environment});


    };


    render(): React.ReactNode {

        //Get the pull copy
        let env =  this.props.sim.parameters.environment;
        if (this.state.editCopy != undefined)
            env = this.state.editCopy!;

        //See if this is editable
        const isEditable = this.state.editCopy != undefined;


        //Now return the table
        return (
            <Container style={{ padding: '5em 0em' }}>
                <Header as='h2'>Environmental Conditions</Header>
                <Segment>
                    <Table compact collapsing>
                        <Table.Row>
                            <Table.Cell><b>Wind Speed</b></Table.Cell>
                            <Table.Cell>
                            <NumberInput units='m/s'
                                         value={env.windSpeed}
                                         disabled={!isEditable}
                                         onChange={(v, data:InputOnChangeData) => this.setState({editCopy:{...env, windSpeed:parseFloat(data.value)}})}
                            />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell><b>Ambient Temperature</b></Table.Cell>
                            <Table.Cell>
                            <NumberInput units='K'
                                         value={env.ambientTemperature}
                                         disabled={!isEditable}
                                         onChange={(v, data:InputOnChangeData) => this.setState({editCopy:{...env, ambientTemperature:parseFloat(data.value)}})}
                            />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell><b>Barometer Pressure</b></Table.Cell>
                            <Table.Cell>
                            <NumberInput units='Pa'
                                         value={env.barometerPressure}
                                         disabled={!isEditable}
                                         onChange={(v, data:InputOnChangeData) => this.setState({editCopy:{...env, barometerPressure:parseFloat(data.value)}})}
                            />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell><b>Ambient Air Humidity</b></Table.Cell>
                            <Table.Cell>
                            <NumberInput units='%'
                                         value={env.ambientAirHumidity}
                                         disabled={!isEditable}
                                         onChange={(v, data:InputOnChangeData) => this.setState({editCopy:{...env, ambientAirHumidity:parseFloat(data.value)}})}
                            />
                            </Table.Cell>
                        </Table.Row>
                    </Table>
                {/*Add a button bar to switch between the modes*/}
                <Button.Group>
                    {isEditable &&
                        <Button secondary onClick={this.cancelChanges}>Cancel</Button>
                    }
                    {isEditable &&
                        <Button primary onClick={this.saveChanges}>Save Changes</Button>
                    }
                    {!isEditable &&
                    <Button  onClick={this.enterEdit}>Edit</Button>
                    }
                </Button.Group>
                </Segment>
            </Container>
        );



    }


}

export default Environment