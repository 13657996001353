import {  error } from 'action-alert';
import {Action, Dispatch} from 'redux';
import {ThunkAction} from 'redux-thunk';
import {extractMessageFromPossibleServerResponseStatus, GeometryMetaData} from "react-authentication";
import {geometryService} from "../services/geometry.service";



export const geometryConstants = {
    GET_GEOMETRY_LIST: 'GET_GEOMETRY_LIST',
    GET_GEOMETRY_UPLOAD_START: 'GET_GEOMETRY_UPLOAD_START',
    GET_GEOMETRY_UPLOAD_END:'GET_GEOMETRY_UPLOAD_END',
    GET_GEOMETRY_ITEM: 'GET_GEOMETRY_ITEM',
    FETCH_GEOMETRY_DATA_FAILURE: 'FETCH_GEOMETRY_DATA_FAILURE',
    FETCH_GEOMETRY_DATA_REQUEST: 'FETCH_GEOMETRY_DATA_REQUEST',
};

export const geometryActions = {
    updateGeometryList,
    uploadGeometry,
    removeGeom,
    updateGeometryItem,
    uploadGeometryItem,
    // getAll,
    // delete: _delete
};

/**
 * This is the user action to try to log in
 * @param username
 * @param password
 * @returns {Function}
 */
function updateGeometryList(): ThunkAction<any, any,any, any> {
    //Return a function that will be called by dispatch
    return (dispatch:Dispatch<Action>) => {
        //Dispatch the action of attempting load geometry
        dispatch({
            type: geometryConstants.FETCH_GEOMETRY_DATA_REQUEST,
        });

        //Ask the user service to login
        geometryService.getGeometryList()
            .then(
                //If successful a user will be returned
                list => {
                    //dispatch a login success
                    dispatch({
                        type: geometryConstants.GET_GEOMETRY_LIST,
                        payload: list
                    });

                },
                //If there was an error, dispatch a login failure and alert the user why
                errorResponse => {
                    //Get the message
                    const message = extractMessageFromPossibleServerResponseStatus(errorResponse);

                    //dispatch a load failure
                    dispatch({
                        type: geometryConstants.FETCH_GEOMETRY_DATA_FAILURE,
                        payload: message
                    });

                    //Dispatch error message
                    dispatch(error(message));



                }
            );
    };
}

/**
 * This is the user action to try to log in
 * @param username
 * @param password
 * @returns {Function}
 */
function updateGeometryItem(id: number): ThunkAction<any, any,any, any> {
    //Return a function that will be called by dispatch
    return (dispatch:Dispatch<Action>) => {
        //Dispatch the action of attempting load geometry
        dispatch({
            type: geometryConstants.FETCH_GEOMETRY_DATA_REQUEST,
        });

        //Ask the user service to login
        geometryService.getGeometryData(id)
            .then(
                //If successful a user will be returned
                item => {
                    //dispatch a login success
                    dispatch({
                        type: geometryConstants.GET_GEOMETRY_ITEM,
                        payload: item
                    });

                },
                //If there was an error, dispatch a login failure and alert the user why
                errorResponse => {
                    //Get the message
                    const message = extractMessageFromPossibleServerResponseStatus(errorResponse);

                    //dispatch a load failure
                    dispatch({
                        type: geometryConstants.FETCH_GEOMETRY_DATA_FAILURE,
                        payload: message
                    });

                    //Dispatch an error message
                    dispatch(error(message));



                }
            );
    };
}

/**
 * This is the user action to try to log in
 * @param username
 * @param password
 * @returns {Function}
 */
function uploadGeometry(data: GeometryMetaData, units:string, file: File): ThunkAction<any, any,any, any> {
    //Return a function that will be called by dispatch
    return (dispatch:Dispatch<Action>) => {
        //dispatch a login success
        dispatch({
            type: geometryConstants.GET_GEOMETRY_UPLOAD_START,
        });

        //Ask the user service to login
        geometryService.uploadGeometryStl(data,units, file)
            .then(
                //If successful a user will be returned
                list => {
                    //dispatch a login success
                    dispatch({
                        type: geometryConstants.GET_GEOMETRY_LIST,
                        payload: list
                    });

                    dispatch({
                        type: geometryConstants.GET_GEOMETRY_UPLOAD_END,
                    });

                },
                //If there was an error, dispatch a login failure and alert the user why
                errorResponse => {
                    //Get the message
                    const message = extractMessageFromPossibleServerResponseStatus(errorResponse);

                    //Dispatch a sucess message
                    dispatch(error(message));



                }
            );
    };
}
/**
 * Remove the geom
 * @param username
 * @param password
 * @returns {Function}
 */
function removeGeom(data: GeometryMetaData): ThunkAction<any, any,any, any> {
    //Return a function that will be called by dispatch
    return (dispatch:Dispatch<Action>) => {


        //Ask the user service to login
        geometryService.removeGeom(data)
            .then(
                //If successful a user will be returned
                list => {
                    //dispatch a login success
                    dispatch({
                        type: geometryConstants.GET_GEOMETRY_LIST,
                        payload: list
                    });
                },
                //If there was an error, dispatch a login failure and alert the user why
                errorResponse => {
                    //Get the message
                    const message = extractMessageFromPossibleServerResponseStatus(errorResponse);

                    //Dispatch a sucess message
                    dispatch(error(message));



                }
            );
    };
}

//TODO: add pushToServerGeometryItem
/**
 * This is the calc action to get all of the calcs
 */
function uploadGeometryItem(data: GeometryMetaData, newGeomFunc?: (newGeomId: number) => any): ThunkAction<any, any,any, any> {
    //Return a function that will be called by dispatch
    return (dispatch:Dispatch<Action>) => {

        //Dispatch the action of attempting to login
        dispatch({
            type: geometryConstants.FETCH_GEOMETRY_DATA_REQUEST,
        });

        //Ask the user service to login
        geometryService.uploadGeometryItem(data)
            .then(
                //If successful a user will be returned
                geom  => {
                    //dispatch a login success
                    dispatch({
                        type: geometryConstants.GET_GEOMETRY_ITEM,
                        payload: geom
                    });

                    //Call the call back if it exists
                    if(newGeomFunc){
                        newGeomFunc(geom.id);
                    }

                },
                //If there was an error, dispatch a login failure and alert the user why
                errorResponse => {
                    //dispatch a login failure
                    dispatch({
                        type: geometryConstants.FETCH_GEOMETRY_DATA_FAILURE,
                        payload: errorResponse
                    });
                    //Dispatch the error
                    try {
                        dispatch(error(errorResponse.response.data.message));
                    }catch(e){
                        dispatch(error(errorResponse.toString()));

                    }

                }
            );
    };

}
