import React from 'react';

import {connect} from "react-redux";
import ApplicationState from "../../../../state/ApplicationState";
import { Dimmer, Header,  Loader, Segment} from "semantic-ui-react";
import {ThunkDispatch} from "redux-thunk";
import {geometryActions} from "../../../../actions/geometry.actions";
import {GeometryMetaData, GeometryStlFile} from "react-authentication"
import GeometryBlueprintsEditor from "./GeometryBlueprintsEditor"
import {geometryService} from "../../../../services/geometry.service"

// @ts-ignore
import StlPicker from "stl-face-selector-component"
import {Placeholder} from "semantic-ui-react"

/**
 * This private route renders what was specified or skips it if the user does not have authority
 * @param Component
 * @param rest
 * @returns {*}
 * @constructor
 */


//Define a private route interface for props
interface LinkProps { //Gets from the GeometryRouter
    //Send it the activeResource sim id
    geomId: number;

    //Store the basePath
    basePath: string;
}

//Define a private route interface for props
interface StateProps { //Application State Props
    //Send it the activeResource sim id
    // simData?: SimulationData;
    geomData?: GeometryMetaData
    //Waiting for sim data update
    waitingForUpdate?:boolean;
}

//Define a private route interface for props
interface DispatchProps { //Redux actions to sync with application state
    //And the actions that must be done
    updateGeometryItem: (geomId: number) => any;
    uploadGeometryItem: (geom: GeometryMetaData) => any;
}

interface ComponentState {
    stlFile?: GeometryStlFile
    fileLoaded: boolean
    fileLoading: boolean
}


/**
 * This card shows the animal details
 */
class GeometryCreatorEditor extends React.Component<LinkProps&StateProps&DispatchProps, ComponentState> {
    state = {
        stlFile: undefined,
        fileLoaded: false,
        fileLoading: false
    };

    private startGeometryRequest = () => {
        if (!this.state.fileLoaded && !this.state.fileLoading) {
            this.setState({fileLoading: true});
            geometryService.getUserGeometryStlById(this.props.geomId).then(file => {
                this.setState({stlFile:file, fileLoaded:true, fileLoading:false})
            });
        }
    };

    componentDidMount(): void {
        const {geomId} = this.props;

        //Put in the code to download the item
        this.props.updateGeometryItem(geomId) //Update the current props from application state

        // Start the GET request for the STL file
        this.startGeometryRequest();
    };

    componentDidUpdate(prevProps: Readonly<LinkProps & DispatchProps & StateProps>, prevState: Readonly<ComponentState>, snapshot?: any): void {
        // Start the GET request for the STL file
        if (prevProps.geomData !== undefined) {
            if (prevProps.geomData!.blueprints !== this.props.geomData!.blueprints) {
                this.startGeometryRequest();
            }
        }
    }

    componentWillUnmount(): void {
        //Set the side params to empty
        // this.props.updateSideMenu([]);
    }

    //TODO: Add function to upload new geometry data to the server uploadGeometryItem(geom)
    uploadGeometryItem = (geom: GeometryMetaData) => {

        this.props.uploadGeometryItem(geom);

        this.setState({fileLoaded:false, stlFile: undefined});

    }

    //Check to see if any of the routes are excluded
    render() {
        //Build
        const { geomData} = this.props;

        //Now build the base url
        //const baseUrl = basePath + "/" + geomId


        //Determine if we are loading
        const loading = geomData === undefined || this.props.waitingForUpdate; //short circuit operator


        return (
            <div>
                <Dimmer active={loading} inverted>
                    <Loader inverted content='Loading' />
                </Dimmer>

                {geomData && geomData.blueprints &&
                    // <GeometryBlueprintsEditor updateSimulation={this.updateSimulation} sim={simData}></GeometryBlueprintsEditor>
                    <GeometryBlueprintsEditor geomData={geomData} uploadGeometryItem={this.uploadGeometryItem}>
                        <Segment>
                            <Header as='h3'>STL Geometry Preview</Header>
                            {this.state.fileLoaded &&
                                <StlPicker stlFile={this.state.stlFile}/>
                            }
                            {!this.state.fileLoaded &&
                            <Placeholder fluid>
                                <Placeholder.Image square />
                            </Placeholder>
                            }
                        </Segment>
                    </GeometryBlueprintsEditor>
                }
                {geomData && !geomData.blueprints &&
                    <Header as='h2'>This Geometry cannot be edited</Header>
                }
            </div>
        )

    }

}


/**
 * Map from the global state to things we need here
 * @param state
 * @returns {{authentication: WebAuthentication}}
 */
function mapStateToProps(state:ApplicationState, myProps:LinkProps):LinkProps&StateProps {
    return {
        ...myProps,
        geomData: state.geometry.geometryList[myProps.geomId],
        waitingForUpdate: state.geometry.loading
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch<any,any, any>): DispatchProps {
    return {
        updateGeometryItem: (geomId: number) => dispatch(geometryActions.updateGeometryItem(geomId)),
        uploadGeometryItem: (geom: GeometryMetaData) => dispatch(geometryActions.uploadGeometryItem(geom))
    };

}

export default connect (
    mapStateToProps,
    mapDispatchToProps
)(GeometryCreatorEditor);

