import React from "react";
import {RouteComponentProps} from "react-router";
import VisusViewer from 'visus-viewer-component';
import {Input, InputProps, Label} from "semantic-ui-react";


//Define the expected props
interface IncomingProps extends InputProps{

    label?:React.ReactNode

    units? :React.ReactNode
}

export const NumberInput = (props:IncomingProps) => {
    //Extract the label and units
    const {label, units, ...rest} = props;


    return (
        <Input size="small" style={{marginRight:"20px"}}{...rest} labelPosition={units?'right' :undefined} type='number' >
            {label && <Label>{label}</Label>}
            <input />
            {units && <Label>{units}</Label>}

        </Input>

    )

};
