import React from 'react';
import {connect} from 'react-redux';

import { Icon, Modal, Placeholder, Card} from "semantic-ui-react";
import {ThunkDispatch} from "redux-thunk";
import {TemplateData} from "../../../models/Template";
import {templateActions} from "../../../actions/templates.actions";
import ApplicationState from "../../../state/ApplicationState";
import TemplateItem from "./TemplateItem";
import {RouteComponentProps, withRouter} from "react-router";
import {simActions} from "../../../actions/simulations.actions";


//Define the expected props
interface LinkProps extends RouteComponentProps<any>{

}


//Define the expected props
interface StateProps{
    //Define the props we expect
    templates: TemplateData[];
}


interface DispatchProps{
    //And the actions that must be done
    updateTemplateList: () => any;
    newSimulationFromTemplate: (templateId:string, newSimFunc?: (newSimId: number) => any ) => any

}

interface ModalState{
    //And the actions that must be done
    listOpen:boolean;
}


/**
 * This card shows the animal details
 */
class TemplateList extends React.Component<LinkProps&StateProps&DispatchProps,ModalState> {
    state={listOpen:false};
    /**
     * Gets called once when the page loads.  Tell the system to download or update the summary
     */
    componentDidMount(){
        // reset login status
        this.props.updateTemplateList()
    };

    handleOpen = () => this.setState({ listOpen: true })

    handleClose = () => this.setState({ listOpen: false })


    /**
     * Re-render every time this is called
     * @returns {*}
     */
    render() {
        return (
            <Modal
                size='fullscreen'
                trigger={<Icon  size='large' name='plus square outline' onClick={this.handleOpen}/>}
                open={this.state.listOpen}
                onClose={this.handleClose}

            >
                <Modal.Header>Select Template</Modal.Header>
                <Modal.Content  scrolling>
                    <Card.Group stackable >
                        {this.props.templates &&

                        this.props.templates.map(template => {
                            return <TemplateItem
                                key={template.id}
                                template={template}
                                handleSelect={
                                    ()=>{
                                        this.props.newSimulationFromTemplate(template.id, (newSimId:number) => {
                                            //Move to the new screen
                                            this.props.history.push("/simulation/" + newSimId);
                                            //Close this modal
                                            this.handleClose();

                                        })
                                    }
                                }
                            />
                        })

                        }
                        {(!this.props.templates || this.props.templates.length === 0) &&
                        <Card>
                            <Card.Content>
                                <Placeholder>
                                    <Placeholder.Image rectangular/>
                                </Placeholder>
                            </Card.Content>
                        </Card>
                        }
                    </Card.Group>
                </Modal.Content>

            </Modal>


        );

    }
}

/**
 * Map from the global state to things we need here
 * @param state
 * @returns {{authentication: WebAuthentication}}
 */
function mapStateToProps(state:ApplicationState, myProps:LinkProps):LinkProps&StateProps {
    return {
        ...myProps,
        templates: state.templates
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch<any,any, any>):DispatchProps {
    return {
        updateTemplateList:() =>  dispatch(templateActions.updateTemplateList()),
        newSimulationFromTemplate: (templateId:string, newSimFunc?: (newSimId: number) => any) => dispatch(simActions.newSimulationFromTemplate(templateId, newSimFunc))
    };

}

//https://stackoverflow.com/questions/48292707/strongly-typing-the-react-redux-connect-with-typescript
const TemplateWithOutRouter = connect (
    mapStateToProps,
    mapDispatchToProps
)(TemplateList);


//Wrap with a withRouter so we get the current location
export default withRouter(props => <TemplateWithOutRouter {...props}/>);