import React, {CSSProperties} from 'react';

//Load in the routes
import {BrowserRouter, Route} from 'react-router-dom';

//Load in the possible paths
import AlertDisplay from './AlertDisplay';
import App from './app/App';
import {Container, Grid} from "semantic-ui-react";
import HelpViewier from "./static-pages/HelpViewer";
import {connect} from "react-redux";
import {SettingGroup, UserPreferences, PasswordResetPage, ActivationPage, RegisterPage, LoginPage, PrivateRoute} from "react-authentication";
import ApplicationState from "../state/ApplicationState";

//Add the user preferences
interface AppRouterProps {
    prefs?: SettingGroup
}
//Define the class
class AppRouter extends React.Component<AppRouterProps> {


    //The app router just shows the parts of the different
    render() {

        //Check to see if we are in dark mode
        const modeStyle = {} as CSSProperties;



        //If it is in dark mode
        if (this.props.prefs) {

            const darkModeString = this.props.prefs.subgroup["view"].settings["darkMode"];


            //Convert to bool
            if (JSON.parse(darkModeString)) {
                modeStyle.backgroundColor = "black";
            }

        }

        return (
            <div>
                {/*Always show the the alerts*/}
                <AlertDisplay/>

                {/*Go to different components depending upon the path specified*/}
                <BrowserRouter>
                    <div style={modeStyle}>
                        <Container>
                            <Route path="/login" component={LoginPage}/>
                            <Route path="/register" component={RegisterPage}/>
                            <Route path="/activate" component={ActivationPage}/>
                            <Route path="/passwordreset" component={PasswordResetPage}/>
                            <Route path="/loginhelp" component={HelpViewier}/>

                        </Container>
                        <PrivateRoute to={'/login'}
                                      exclude={["/login", "/register", "/activate", "/passwordreset", "/loginhelp", "/loginhelp/"]}
                                      path="/" component={App}/>

                    </div>
                </BrowserRouter>
            </div>
        );
    }


}


//Get the states
const mapStateToProps = (state: ApplicationState): AppRouterProps => {
    return {
        prefs: state.authentication.preferences ? state.authentication.preferences.settings : undefined};
};


export default connect(
    mapStateToProps
)(AppRouter);

// export default AppRouter;