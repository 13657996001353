import {  error } from 'action-alert';
import {Action, Dispatch} from 'redux';
import {ThunkAction} from 'redux-thunk';
import {templatesService} from "../services/templates.service";


export const templateConstants = {
    FETCH_TEMPLATE_LIST: 'FETCH_TEMPLATE_LIST',

};

export const templateActions = {
    updateTemplateList,
    // getAll,
    // delete: _delete
};

/**
 * Update the template List
 * @param username
 * @param password
 * @returns {Function}
 */
function updateTemplateList(): ThunkAction<any, any,any, any> {
    //Return a function that will be called by dispatch
    return (dispatch:Dispatch<Action>) => {

        //Ask the user service to login
        templatesService.getTemplateList()
            .then(
                //If successful a user will be returned
                sum => {
                    //dispatch a login success
                    dispatch({
                        type: templateConstants.FETCH_TEMPLATE_LIST,
                        payload: sum
                    });
                },
                //If there was an error, dispatch a login failure and alert the user why
                errorResponse => {
                    //Dispatch the error
                    try {
                        dispatch(error(errorResponse.response.data.message));
                    }catch(e){
                        dispatch(error(errorResponse.toString()));

                    }

                }
            );
    };

}
