import React from "react"
import {MinMaxPackage} from "../../../../models/LogPackage";
import wrapLogDisplay, {InjectedLogDataProps} from "../SolveLogUpdater";
import {ChartDataSeries} from "multiple-series-line-chart";
import MultipleSeriesLineChart from "multiple-series-line-chart" ;  //"C:/Users/keller/WebstormProjects/flareswebui/node_modules/multiple-series-line-chart/src/components/MultipleSeriesLineChart"
import {Segment, Popup} from "semantic-ui-react";


interface RawDisplayProps extends InjectedLogDataProps<MinMaxPackage>{

}

interface DataPoint {
    index: number;
    physicalTime: number;
    min?: number;
    max?: number;

    minX?: number;
    minY?: number;
    minZ?: number;

    maxX?: number;
    maxY?: number;
    maxZ?: number;
}

const MinMaxDisplay: React.FunctionComponent<RawDisplayProps> = (props:RawDisplayProps) => {
    // convert min max data into our Recharts format
    let chartData:  ChartDataSeries[] = [];
    if (props.pkgList != undefined && props.pkgList.length > 0 && props.pkgList[0].data != undefined && props.pkgList[0].data.length > 0) {
        // create a data point for each variable and timestep in the pkg
        let dataSeries: DataPoint[][] = [];
        props.pkgList[0].data.map( (variableSeries, index) => {
            dataSeries[index] = [];
        });

        props.pkgList.map(pkg => {
            pkg.data.map( (variableSeries, index) => {
                let dataPt: DataPoint = {index: variableSeries.index, physicalTime: variableSeries.physicalTime};
                if (variableSeries.mins.length == 3) {
                    dataPt.minX = variableSeries.mins[0];
                    dataPt.minY = variableSeries.mins[1];
                    dataPt.minZ = variableSeries.mins[2];
                    dataPt.maxX = variableSeries.maxes[0];
                    dataPt.maxY = variableSeries.maxes[1];
                    dataPt.maxZ = variableSeries.maxes[2]
                } else {
                    dataPt.min = variableSeries.mins[0];
                    dataPt.max = variableSeries.maxes[0]
                }
                dataSeries[index].push(dataPt)
            });
        });

        props.pkgList[0].data.map( (variableSeries, index) => {
            let variableName = variableSeries.variableName;
            let sanitizedLineName = "";
            switch(variableName) {
                case "CCVelocity":
                    sanitizedLineName = "Velocity";
                    break;
                case "pressurePS":
                    sanitizedLineName = "Pressure";
                    break;
                default:
                    sanitizedLineName = variableName;
            }
            chartData.push({name: sanitizedLineName, yUnits: variableSeries.units, data: dataSeries[index]});
        });

    }

    return (
        <div>
            <Popup
                trigger={
                    <h1>Min-Max</h1>
                }
                hoverable
                position={'top left'}
            >
                <Popup.Content>
                    The min-max values are tracked inside the simulation domain.
                </Popup.Content>
            </Popup>
            {/* If we have all the data */}
            {props.pkgList && props.pkgList.length > 0 && props.pkgList[0].data && props.pkgList[0].data.length > 0 &&
                <Segment>
                    <MultipleSeriesLineChart
                        xAxisDataKey={"index"}
                        xUnits={""}
                        yAxisDataKeys={["min", "max", "minX", "minY", "minZ", "maxX", "maxY", "maxZ"]}
                        chartData={chartData}
                        chartDataKey={"minMax"}
                    />
                </Segment>
            }

            {/* else show loading */}
            {!props.pkgList &&
            <div>Loading</div>
            }
            {/* Let the user know it failed */}
            {props.pkgList &&
             props.pkgList.length == 0  &&

            <div>No data to display</div>
            }

            {/* show any error */}
            {props.error &&
            <div>Error: {props.error}</div>
            }

        </div>

    );

};

export default wrapLogDisplay(MinMaxDisplay)
