import {SimulationPageProps} from "../SimulationPageProps";
import * as React from "react";
import {Button, Container, Header, Input, InputOnChangeData,
    Popup, List, Segment,Grid, GridColumn, Table} from "semantic-ui-react";
import {NumberInput} from "../../../inputs/NumberInput";
import {ModelNumPara as ModelNumParaData} from "../../../../models/Simulation";
import Logo from "../../../../assets/domain.png";

import { GeometryStlFile } from "react-authentication";
import { geometryService } from "../../../../services/geometry.service";

import vtkActor from "vtk.js/Sources/Rendering/Core/Actor"
import vtkMapper from "vtk.js/Sources/Rendering/Core/Mapper"
import vtkSTLReader from "vtk.js/Sources/IO/Geometry/STLReader"

interface LinkProps extends SimulationPageProps{

}


interface ModelNumParaState {

    //Store the edit version
    editCopy?: ModelNumParaData;

    stlFile?: Blob
    geometryBounds: Array<number>
    fileLoaded: boolean
    fileLoading: boolean
}

/**
 * This card shows the animal details
 */
class ModelNumPara extends React.Component<LinkProps, ModelNumParaState> {
    state = {
        editCopy: undefined,
        stlFile: undefined,
        geometryBounds: [],
        fileLoaded: false,
        fileLoading: false,
    };

    //Add a button to submit case
    saveChanges = () =>{
        if(this.state.editCopy != undefined) {
            let submitCopy = this.props.sim;
            submitCopy.parameters.modelnumpara = this.state.editCopy!;

            this.props.updateSimulation(submitCopy)
        }
        //And cancel
        this.cancelChanges()
    };

    cancelChanges =() =>{
        this.setState({editCopy:undefined});


    };

    enterEdit =() =>{
        //Set the edit mode
        this.setState({editCopy:this.props.sim.parameters.modelnumpara});


    };

    componentDidMount(): void {
        this.checkGeometryState()
    }

    private startGeometryRequest = () => {
        geometryService.getUserGeometryStl(this.props.sim.parameters.geometry.data).then(file => {
            var f = file as unknown
            this.storeStlFileBounds(f as Blob)
        })
        this.setState({fileLoading: true})
    }

    private checkGeometryState(): void {
        // Determine whether we can/need to fetch geometry from the server
        if (!this.state.fileLoaded && !this.state.fileLoading) {
            if(this.props.sim.parameters.geometry.data.id === undefined) {
                this.setState({fileLoaded: true})
                return
            }

            // Start the GET request for the STL file
            this.startGeometryRequest()
        }
    }

    private storeStlFileBounds(file: Blob): void {
        const reader = vtkSTLReader.newInstance();
        const mapper = vtkMapper.newInstance({ scalarVisibility: false });
        const actor = vtkActor.newInstance();
        actor.setMapper(mapper)
        mapper.setInputConnection(reader.getOutputPort())

        const fr = new FileReader()
        fr.readAsArrayBuffer(file)

        fr.onload = (e) => {
            reader.parseAsArrayBuffer(fr.result)
            this.setState({geometryBounds: actor.getBounds()})
        }
    }


    private renderTotalNumberOfCells(): [string,string,string] {
        if(this.isBoundsArrayLoading()) {
            return ["Calculating..",'N/A','N/A']
        }

        let mp = this.props.sim.parameters.modelnumpara
        let geoInfo =this.props.sim.parameters.geometry
        if(mp.transvereDirScale*mp.elevatedDirScale*mp.transvereDirScale == 0) {
            return ["Mesh size must be greater than zero",'N/A','N/A']
        }

        // TODO: Implement proper calculation
        let x=this.state.geometryBounds[1] - this.state.geometryBounds[0]
        let y=this.state.geometryBounds[3] - this.state.geometryBounds[2]
        let z=this.state.geometryBounds[5] - this.state.geometryBounds[4]
        let total=Math.floor(x*y*z*geoInfo.elevatedDirScaleRatio* geoInfo.transvereDirScaleRatio*geoInfo.windDirScaleRatio /mp.elevatedDirScale/mp.transvereDirScale/mp.windDirScale)
         // Based on Phase I nimbx case, have 185361600 cell running with 14.6s, and cost about 22464$
        let estimateCost=total/185361600.0*mp.simTimeConverge/14.6*22464
        // Based on the wind-velocity and wind direction length
        let winddir=this.props.sim.parameters.geometry.windDirection
        let residenceTime=0
        let windVel =this.props.sim.parameters.environment.windSpeed
        if(winddir[0]=='x'&& windVel !==0 ){
            residenceTime=x*geoInfo.windDirScaleRatio/this.props.sim.parameters.environment.windSpeed
        }else if (winddir[0]=='y'&& windVel !==0){
            residenceTime=y*geoInfo.windDirScaleRatio/this.props.sim.parameters.environment.windSpeed
        }else if(winddir[0]=='z' && windVel!=0){
            residenceTime=z*geoInfo.windDirScaleRatio/this.props.sim.parameters.environment.windSpeed
        }


         return [total.toString(),estimateCost.toString(),residenceTime.toString()]
        // return [total.toString(),estimateCost.toString()]
    }

    private isBoundsArrayLoading(): boolean {
        return this.state.geometryBounds.length < 1
    }

    render(): React.ReactNode {

        //Get the pull copy
        let modelPara =  this.props.sim.parameters.modelnumpara;
        if (this.state.editCopy != undefined)
            modelPara = this.state.editCopy!;

        //See if this is editable
        const isEditable = this.state.editCopy != undefined;


        //Now return the table
        return (
            <Container style={{ padding: '5em 0em' }}>
                <Header as='h2'>Model Setup</Header>
                 <Grid columns={2} stackable>
                     <GridColumn width={"ten"}>
                         <Container>
                           <Segment>
                               <Table celled compact>
                                   <Popup
                                       trigger={
                                           <Table.Body>
                                               <Table.Row>
                                                   <Table.Cell collapsing>
                                                       <b>Mesh size along wind-direction</b>
                                                   </Table.Cell>
                                                   <Table.Cell>
                                                       <NumberInput fluid
                                                           units='m'
                                                           value={modelPara.windDirScale}
                                                           disabled={!isEditable}
                                                           onChange={(v, data:InputOnChangeData) =>
                                                               this.setState({editCopy:{...modelPara,
                                                                       windDirScale:parseFloat(data.value)}})}
                                                           />
                                                   </Table.Cell>
                                               </Table.Row>
                                               <Table.Row>
                                                   <Table.Cell collapsing>
                                                       <b>Mesh size along height-direction</b>
                                                   </Table.Cell>
                                                   <Table.Cell>
                                                       <NumberInput fluid
                                                           units='m'
                                                           value={modelPara.elevatedDirScale}
                                                           disabled={!isEditable}
                                                           onChange={(v, data:InputOnChangeData) =>
                                                               this.setState({editCopy:{...modelPara,
                                                                       elevatedDirScale:parseFloat(data.value)}})}
                                                       />
                                                   </Table.Cell>
                                               </Table.Row>
                                               <Table.Row>
                                                   <Table.Cell collapsing>
                                                       <b>Mesh size along transverse-direction</b>
                                                   </Table.Cell>
                                                   <Table.Cell>
                                                       <NumberInput fluid
                                                           units='m'
                                                           value={modelPara.transvereDirScale}
                                                           disabled={!isEditable}
                                                           onChange={(v, data:InputOnChangeData) =>
                                                               this.setState({editCopy:{...modelPara,
                                                                       transvereDirScale:parseFloat(data.value)}})}
                                                       />
                                                   </Table.Cell>
                                               </Table.Row>
                                           </Table.Body>
                                       }
                                       content={'Mesh size determines the Estimated Computation Cost'}
                                   />
                                   <Table.Body>
                                       <Table.Row>
                                           <Table.Cell collapsing>
                                               <b>Physical time as simultaion stops</b>
                                           </Table.Cell>
                                           <Table.Cell>
                                               <NumberInput fluid
                                                   units='s'
                                                   value={modelPara.simTimeConverge}
                                                   disabled={!isEditable}
                                                   onChange={(v, data:InputOnChangeData) =>
                                                       this.setState({editCopy:{...modelPara,
                                                               simTimeConverge:parseFloat(data.value)}})}
                                               />
                                           </Table.Cell>
                                       </Table.Row>
                                       <Table.Row>
                                           <Table.Cell collapsing>
                                               <b>Save Data Frequency</b>
                                           </Table.Cell>
                                           <Table.Cell>
                                               <NumberInput fluid
                                                            units='s'
                                                            value={modelPara.saveDataFreq}
                                                            disabled={!isEditable}
                                                            onChange={(v, data:InputOnChangeData) =>
                                                                this.setState({editCopy:{...modelPara,
                                                                        saveDataFreq:parseFloat(data.value)}})}
                                               />
                                           </Table.Cell>
                                       </Table.Row>
                                   </Table.Body>
                                    {/*Inactive cells for display purposes only */}
                                    <Popup
                                        trigger={
                                            <Table.Body>
                                                <Table.Row active>
                                                    <Table.Cell collapsing>
                                                        <b>Estimated residence time</b>
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        <NumberInput fluid
                                                                     units='s'
                                                                     value={this.renderTotalNumberOfCells()[2]}
                                                                     disabled={true}
                                                                     loading={this.isBoundsArrayLoading()}
                                                        />
                                                    </Table.Cell>
                                                </Table.Row>
                                                <Table.Row active>
                                                    <Table.Cell collapsing>
                                                        <b>Total number of cells</b>
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        <NumberInput fluid
                                                                     units={'#'}
                                                                     value={this.renderTotalNumberOfCells()[0]}
                                                                     disabled={true}
                                                                     loading={this.isBoundsArrayLoading()}
                                                        />
                                                    </Table.Cell>
                                                </Table.Row>
                                                <Table.Row active>
                                                    <Table.Cell collapsing>
                                                        <b>Estimated computation cost</b>
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        <NumberInput fluid
                                                                     units='$'
                                                                     value={this.renderTotalNumberOfCells()[1]}
                                                                     disabled={true}
                                                                     loading={this.isBoundsArrayLoading()}
                                                        />
                                                    </Table.Cell>
                                                </Table.Row>
                                            </Table.Body>
                                        }
                                        content={'These are computed values. They may take a moment to display'}
                                        position={'left center'}
                                    />
                               </Table>

                {/*Add a button bar to switch between the modes*/}
                <Button.Group>
                    {isEditable &&
                        <Button secondary onClick={this.cancelChanges}>Cancel</Button>
                    }
                    {isEditable &&
                        <Button primary onClick={this.saveChanges}>Save Changes</Button>
                    }
                    {!isEditable &&
                    <Button  onClick={this.enterEdit}>Edit</Button>
                    }
                </Button.Group>
                </Segment>
                </Container>
                     </GridColumn>

                      <GridColumn width={"four"}>
                          <Container>
                           {/*<Header as='h3'>Domain Setup Description</Header>*/}


                                  <img src={Logo} alt="website" style={{height:500} }/>

                          </Container>

                      </GridColumn>

                 </Grid>
            </Container>
        );
    }
}

export default ModelNumPara
