import React from 'react';
import { RouteComponentProps} from 'react-router-dom';
import {withRouter} from 'react-router-dom';

import GeometryCreatorEditor from "./GeometryCreatorEditor"

/**
 * This private route renders what was specified or skips it if the user does not have authority
 * @param Component
 * @param rest
 * @returns {*}
 * @constructor
 */


//Define a private route interface for props
interface LinkProps extends RouteComponentProps<any> {

}


//Define a private route interface for props
interface DispatchProps {
    //And the actions that must be done
}


/**
 * This card shows the animal details
 */
class GeometryCreatorRouter extends React.Component<LinkProps&DispatchProps, any> {

    componentWillMount(): void {


    };

    //Check to see if any of the routes are excluded
    render(): React.ReactNode {
        //Get the current url
        const url = this.props.location? this.props.location.pathname: "";

        //Get the simulation id from the the url
        const geomIdAny = this.props.match.params.geomId;

        //Convert to an int
        const geomId = parseInt(geomIdAny.toString());

        // <SimulationEditor key={url} basePath='/simulation' simId={simId}/>
        return (
            <GeometryCreatorEditor key={url} basePath='/geometry' geomId={geomId} />
        )
    }

}


// /**
//  * Map from the global state to things we need here
//  * @param state
//  * @returns {{authentication: WebAuthentication}}
//  */
// function mapStateToProps(state:ApplicationState, myProps:LinkProps):LinkProps {
//     return {
//         ...myProps,
//     };
// }
//
// function mapDispatchToProps(dispatch: Dispatch<any>): DispatchProps {
//     return {
//
//     };
//
// }
//
// const GeometryCreatorRouterWithOutRouter = connect (
//     mapStateToProps,
//     mapDispatchToProps
// )(GeometryCreatorRouter);



//Wrap with a withRouter so we get the current location
export default withRouter(props => <GeometryCreatorRouter {...props}/>);
