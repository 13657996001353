import axios from 'axios';
import {authHeader} from "react-authentication";
import {GeometryMetaData, GeometryStlFile} from "react-authentication";
import { apiConf } from "./apiConf"

export const geometryService = {
    getGeometryList,
    uploadGeometryStl,
    removeGeom,
    getUserGeometryStl,
    getUserGeometryStlById,
    getGeometryData,
    uploadGeometryItem
    // getById,
    // update,
    // delete: _delete
};

// Create a default axios instance with the api
const apiServer = axios.create({
    baseURL: apiConf.serverURL
});

const imgEndPoint = apiConf.serverURL + "/geometry/image/"

/**
 * get all possible achievements
 * @param username
 * @param password
 * @returns
 */
function getGeometryList() : Promise<GeometryMetaData[]> {

    //Get the headers
    const headers =authHeader();

    //Now make a post request and get a promise back
    const responsePromise = apiServer.get(`/geometry`,  {headers:headers});


    //We need to do some work here
    return responsePromise.then(response =>
        {//When the request returns
            //Get the user
            const data = response.data as GeometryMetaData[];

            //Now set the url for each
            data.forEach(geom =>{
                geom.imgUrl = imgEndPoint + geom.id;
            })


            //Return just the user
            return data;
        }
    );
}

/**
 * get geometry meta data based on geometry id
 */
function getGeometryData(geomId: number) : Promise<GeometryMetaData> {

    //Get the headers
    const headers = authHeader();

    //Now make a post request and get a promise back
    const responsePromise = apiServer.get(`/geometry/${geomId}`,  {headers:headers});

    //We need to do some work here
    return responsePromise.then(response =>
        {//When the request returns
            //Get the user
            const data = response.data as GeometryMetaData;

            //Return just the user
            return data;
        }
    );
}

/**
 * get all possible achievements
 * @param username
 * @param password
 * @returns
 */
function removeGeom(meta: GeometryMetaData) : Promise<GeometryMetaData[]> {

    //Get the headers
    const headers =authHeader();

    //Now make a post request and get a promise back
    const responsePromise = apiServer.delete(`/geometry/${meta.id}`,  {headers:headers});


    //We need to do some work here
    return responsePromise.then(response =>
        {//When the request returns
            //Get the user
            const data = response.data as GeometryMetaData[];

            //Now set the url for each
            data.forEach(geom =>{
                geom.imgUrl = imgEndPoint + geom.id;
            })

            //Return just the user
            return data;
        }
    );
}

/**
 * get all possible achievements
 * @param username
 * @param password
 * @returns
 */
function uploadGeometryStl(data: GeometryMetaData,units:string,  file?: File) : Promise<GeometryMetaData[]> {

    //Make a new form data
    let dataInForm = new FormData()
    dataInForm.append("metaData:name", data.name);
    dataInForm.append("metaData:notes", data.notes);
    dataInForm.append("units", units);

    if(file) {
        dataInForm.append("file", file)
    }

    //Get the headers
    const headers =authHeader();

    //Add it the type
    headers['Content-Type'] = 'multipart/form-data';

    //Now make a post request and get a promise back
    const responsePromise = apiServer.post(`/geometry/stl`, dataInForm,{headers:headers});


    //We need to do some work here
    return responsePromise.then(response =>
        {//When the request returns
            //Get the user
            const data = response.data as GeometryMetaData[];

            //Now set the url for each
            data.forEach(geom =>{
                geom.imgUrl = imgEndPoint + geom.id;
            })


            //Return just the user
            return data;
        }
    );
}

/**
 * get a user geometry
 * @returns blob
 * @param data
 */
function getUserGeometryStl(data: GeometryMetaData) : Promise<GeometryStlFile> { //TODO: maybe set app state to loading?

    // Get the headers
    const headers = authHeader();

    // Now make a GET request and get a promise back
    const url = `/geometry/stl/` + data.id;
    const responsePromise = apiServer.get(url,{headers:headers, responseType: 'blob'});

    // Just return the response as a GeometryStlFile (really just an alias for a blob)
    return responsePromise.then(response =>
        {//When the request returns
            return response.data as GeometryStlFile;
        }
    );
}

/**
 * get a user geometry by the id
 * @returns blob
 * @param data
 */
function getUserGeometryStlById(geomId: number) : Promise<GeometryStlFile> { //TODO: maybe set app state to loading?

    // Get the headers
    const headers = authHeader();

    // Now make a GET request and get a promise back
    const url = `/geometry/stl/` + geomId;
    const responsePromise = apiServer.get(url,{headers:headers, responseType: 'blob'});

    // Just return the response as a GeometryStlFile (really just an alias for a blob)
    return responsePromise.then(response =>
        {//When the request returns
            return response.data as GeometryStlFile;
        }
    );
}

//TODO: add pushToServerGeometryItem
function uploadGeometryItem(geom: GeometryMetaData) : Promise<GeometryMetaData> {

    //Get the headers
    const headers = authHeader();

    //Now make a post request and get a promise back
    const responsePromise = apiServer.post(`/geometry/blueprints`, geom, {headers:headers});


    //We need to do some work here
    return responsePromise.then(response =>
        {//When the request returns
            //Get the user
            const data = response.data as GeometryMetaData;

            //Then get the new thumbnail
            data.imgUrl = imgEndPoint + data.id;

            //Return just the user
            return data;
        }
    );

}