import {Action} from 'action-alert'
import SearchState from "../state/SearchState";
import {searchConstants} from "../actions/search.actions";

/**
 * The alert reducer maintains a list of searches and results
 * @param state
 * @param action
 * @returns {*}
 */
export function search(state:SearchState = {searchTerm:'', searchResults:[]}, action:Action): SearchState {

    //Ok, we now know that it is an alert action
    switch (action.type) {
        case searchConstants.SEARCH_TERM:
            //Add the new success to the list
            return {...state,
                searchTerm:action.payload
            }


        case searchConstants.SEARCH_CALCS:
            //Add the new success to the list
            return {
                ...state,
                searchResults:action.payload
            }




        default:
            return state
    }
}

// [...state.filter(calc => calc.id != action.payload.id)]