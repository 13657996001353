import React from 'react';
import {connect} from "react-redux";
import {Dispatch} from "redux";

//App state and actions
import ApplicationState from "../../../state/ApplicationState";

//Import other components
import SimulationListItem from "./SimulationListItem";

//Needed models
import {SimulationData} from "../../../models/Simulation";
import {Button, Container, Grid, Header, Icon, Input, List, Segment} from "semantic-ui-react";
import {simActions} from "../../../actions/simulations.actions";


//Define the expected props
interface IncomingProps{
    //Define the props we expect
    simulations: SimulationData[];

}


interface DispatchProps{
    //And the actions that must be done
    updateSimulationList: () => any;
    removeSimulation: (simulation:SimulationData) =>any;
    addSimulation:(name:string) =>any;
    duplicateSimulation: (simulation:SimulationData) =>any;

}

//My state
interface MyState{
    newName:string
}

class SimulationList extends React.Component<IncomingProps&DispatchProps, MyState> {
    state = {newName:""}

    checkIfSubscribed = () => {

    };

    /**
     * Gets called once when the page loads
     */
    componentDidMount(){
        //Request the Simulation list
        this.props.updateSimulationList();

        this.checkIfSubscribed();
    };



    duplicateSimulation = (simulation:SimulationData) => {
        this.props.duplicateSimulation(simulation)
    }

    /**
     * Function to build a list of Simulations
     */
    getListofSimulations() : JSX.Element[]{
        //Go through the list
        return this.props.simulations.map(simulationData => {
            return <SimulationListItem onClick={() => {}}
                                       simulation={simulationData}
                                       linkToRoute={ `/simulation/${simulationData.id}`}
                                       key={simulationData.id}
                                       removeSimulation={this.props.removeSimulation}
                                       duplicateSimulation={this.duplicateSimulation}
            />

        });



    }

    //Return the real div
    /**
     * Re-render every time this is called
     * @returns {*}
     */
    render() {
        return (
            <Container style={{ padding: '5em 0em'}}>
                <Grid stackable>
                    <Grid.Column floated='left' textAlign='left' width={6}>
                        {/*The headers*/}
                        <Header as='h2'>Simulation List</Header>
                    </Grid.Column>
                    <Grid.Column floated='right' textAlign='right' width={6}>

                        <Input placeholder='New Name...' value={this.state.newName} onChange={e => this.setState({newName:e.currentTarget.value})}
                              action={
                                  <Button icon
                                  data-content="Add new simulation "
                                  onClick={() => this.props.addSimulation(this.state.newName)}>
                                    <Icon name='add' />
                                  </Button>
                              }
                        />
                    </Grid.Column>
                </Grid>

                {/*And the list*/}
                <Segment padded>
                    <List>
                        {this.getListofSimulations()}
                    </List>
                </Segment>
            </Container>
        );
    }

};

/**
 * Map from the global state to things we need here
 * @param state
 * @returns {{authentication: WebAuthentication}}
 */
function mapStateToProps(state:ApplicationState): IncomingProps {
    return {
        simulations: Object.keys(state.simulations.simulations).map(key => {return state.simulations.simulations[key]})
    };
}

function mapDispatchToProps(dispatch: Dispatch<any>): {} {
    return {
        updateSimulationList:() => dispatch(simActions.getSimulations()),
        removeSimulation:(simulation:SimulationData) => dispatch(simActions.removeCalc(simulation)),
        duplicateSimulation:(simulation:SimulationData) => dispatch(simActions.duplicateSimulation(simulation)),
        addSimulation:(name:string) => {},
    };

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SimulationList);
;