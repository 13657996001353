import {Action} from 'action-alert'
import {TemplateData} from "../models/Template";
import {templateConstants} from "../actions/templates.actions";

/**
 * The alert reducer maintains a list of alerts
 * @param state
 * @param action
 * @returns {*}
 */
export function templates(state:TemplateData[] = [], action:Action): TemplateData[] {

    //Ok, we now know that it is an alert action
    switch (action.type) {
        case templateConstants.FETCH_TEMPLATE_LIST:
            //Add the new success to the list
            return [...action.payload]

        default:
            return state
    }
}