import * as React from "react"
import {LogTimeStepPackage, PtDataPackage} from "../../../../models/LogPackage";
import wrapLogDisplay, {InjectedLogDataProps} from "../SolveLogUpdater";

interface RawDisplayProps extends InjectedLogDataProps<LogTimeStepPackage>{

}

const TimeStepDisplay: React.FunctionComponent<RawDisplayProps> = (props:RawDisplayProps) => {
    let lastIndex:number
    if (props.pkgList != undefined && props.pkgList.length > 0) {
        lastIndex = props.pkgList.length - 1;
    } else {
        lastIndex = -1
    }

    return (
      <div>
          <h1>Case Status</h1>

          {/*  If we have the data*/}
          {props.pkgList && props.pkgList.length > 0 &&
              <div>
                  <h3>
                      Time Step: {props.pkgList[lastIndex].data.stepNumber},{'\u00A0'}
                      Physical Time: {props.pkgList[lastIndex].data.physicalTime}s,{'\u00A0'}
                      Next {'\u0394'}t: {props.pkgList[lastIndex].data.nextDeltaT}s,{'\u00A0'}
                      Wall Time: {props.pkgList[lastIndex].data.wallTime}s
                  </h3>
              </div>
          }

          {/* or else show loading */}
          {props.pkgList && props.pkgList.length < 1 &&
            <div>No data available from case yet</div>
          }

          {/* or else show loading */}
          {!props.pkgList &&
            <div>Loading</div>
          }

          {/* or show an error */}
          {props.error &&
            <div>Error: {props.error}</div>
          }

      </div>

    )

}


export default wrapLogDisplay(TimeStepDisplay)