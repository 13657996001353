import { SimulationPageProps } from "../SimulationPageProps";
import * as React from "react";
import {
    Button,
    Container, Confirm,
    DropdownProps, Form, Table,
    Header, Segment, Grid, GridColumn, Placeholder,
    Popup, Label, List, InputOnChangeData, Icon
} from "semantic-ui-react";
import {Composition, Geometry, InletSpec, Selection} from "../../../../models/Simulation";
import { GeometryMetaData, GeometryStlFile } from "react-authentication";
import ApplicationState from "../../../../state/ApplicationState";
import { geometryActions } from "../../../../actions/geometry.actions";
import { geometryService } from "../../../../services/geometry.service";
import { connect } from "react-redux";
import Logo from "../../../../assets/domain.png"; // Minmin's domain picture

// @ts-ignore
import StlPicker from "stl-face-selector-component"
import {ThunkDispatch} from "redux-thunk";
import {NumberInput} from "../../../inputs/NumberInput";

interface LinkProps extends SimulationPageProps {

}

interface StateProps {
    //Store the list of all geom
    allPossibleGeom: GeometryMetaData[]
}

interface GeometryPageState {
    //Store the edit version
    editCopy?: Geometry;

    stlFile?: GeometryStlFile
    fileLoaded: boolean
    fileLoading: boolean

    newGeometry: boolean
    open: boolean
}

interface DispatchProps{
    //And the actions that must be done
    updateGeometryList?: () => any;
}

/**
 * This card shows the geometry details
 */
class GeometryPage extends React.Component<LinkProps&DispatchProps&StateProps, GeometryPageState> {
    state = { editCopy: undefined,
              stlFile: undefined,
              fileLoaded: false,
              fileLoading: false,
              newGeometry: false,
              open: false
    };

    private startGeometryRequest = () => {
        geometryService.getUserGeometryStl(this.props.sim.parameters.geometry.data).then(file => {
            this.setState({stlFile: file, fileLoaded: true, fileLoading: false})
        });
        this.setState({fileLoading: true})
    };

    componentDidMount(): void {
        if(this.props.updateGeometryList)
            this.props.updateGeometryList();

        this.checkGeometryState()
    };

    componentDidUpdate(prevProps: Readonly<LinkProps & DispatchProps & StateProps>,
                       prevState: Readonly<GeometryPageState>, snapshot?: any): void {
        this.checkGeometryState()
    }

    private checkGeometryState(): void {
        // Determine whether we can/need to fetch geometry from the server
        if (!this.state.fileLoaded && !this.state.fileLoading) {
            if(this.props.sim.parameters.geometry.data.id === undefined) {
                this.setState({editCopy: this.props.sim.parameters.geometry, fileLoaded: true})
                return
            }

            // Start the GET request for the STL file
            this.startGeometryRequest()
        }
    }

    //Add a button to submit case
    saveChanges = () =>{
        if(this.state.editCopy !== undefined) {
            let submitCopy = this.props.sim;
            submitCopy.parameters.geometry = this.state.editCopy!;

            //Clear all inlet info if the geometry changes
            if(this.state.newGeometry) {
                for(let i = 0; i < submitCopy.parameters.inlets.length; i++)
                    submitCopy.parameters.inlets[i].selection.faces = []
            }

            this.props.updateSimulation(submitCopy);

            this.setState({fileLoaded:false, stlFile: undefined, newGeometry: false});
        }
        //And cancel
        this.cancelChanges()
    };

    cancelChanges =() =>{
        this.setState({editCopy:undefined});
    };

    enterEdit =() =>{
        //Set the edit mode
        this.setState({editCopy:this.props.sim.parameters.geometry});
    };

    //Update geomState
    lookUpGeomStateFromId = (id:number):GeometryMetaData|undefined  => {
        const results = this.props.allPossibleGeom
            .filter((metaData: GeometryMetaData) => metaData.id === id);

        //If we have a result
        if (results.length > 0){
            return results[0]
        }else{
            return undefined
        }

    };

    // Next 3 methods deal with the confirmation box pop up upon saving a Geometry
    show = () => this.setState({ open: true })
    handleConfirm = () => {
        this.setState({open: false})
        this.saveChanges()
    }
    handleCancel = () => {
        this.setState({ open: false })
        this.cancelChanges()
    }

    private renderStlPicker(): React.ReactNode {
        if(this.state.fileLoading) {
            return (
                <Placeholder fluid>
                    <Placeholder.Image square />
                </Placeholder>
            )
        }
        return <StlPicker stlFile={this.state.stlFile}/>
    }

    saveOnNoNewGeometry = () => {
        if(!this.state.newGeometry) {
            this.saveChanges()
        } else {
            this.show()
        }
    }

    render(): React.ReactNode {
        let modelPara =  this.props.sim.parameters.modelnumpara;
        //Get the pull copy
        let geo =  this.props.sim.parameters.geometry;
        // let stlFile = undefined;
        // let fileLoaded = false;

        if (this.state.editCopy !== undefined) {
            geo = this.state.editCopy!;
        }

        //See if this is editable
        const isEditable = this.state.editCopy !== undefined;

        //Build the list of drop down options
        const dropDownGeomOptions = this.props.allPossibleGeom.map(geom =>{
            return {
                text:geom.name,
                //description:geom.notes,
                value:geom.id,
                image:geom.imgUrl
            }

        });

        // Determine whether we even have anything to load in the preview (as
        // opposed to checking whether stlFile is undefined, considering it may
        // be loaded later).
        const canShowPreview = this.props.sim.parameters.geometry.data.id !== undefined

        //Now return the table
        return (
            <Container style={{ padding: '5em 0em' }}>
                <Header as='h2'>Geometry Selection</Header>

                {/*JSON.stringify(this.props.sim.parameters.geometry)*/}
                <Grid columns={2} equal compact>
                    <GridColumn>
                        <Container>
                            <Segment>
                                <Form>
                                    <Popup
                                        trigger={
                                            <Form.Select
                                                label="Specify the base geometry"
                                                disabled={!isEditable}
                                                fluid
                                                selection
                                                options={dropDownGeomOptions}
                                                value={geo.data.id}
                                                onChange={(event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) =>{
                                                    //Get the id
                                                    const id = data.value ? parseInt(data.value as string) : 0;

                                                    //Now get the
                                                    const metaData = this.lookUpGeomStateFromId(id);

                                                    //Now update
                                                    if(metaData) {
                                                        this.setState({
                                                            editCopy: {
                                                                ...geo,
                                                                data: metaData
                                                            }, newGeometry: true
                                                        });
                                                    }
                                                }}
                                            />
                                        }
                                    >
                                        Geometries can be viewed and created from the <Icon name='th large'/> icon located at the top of your screen
                                    </Popup>
                                    <Form.Select
                                        label='Specify the "up" direction'
                                        disabled={!isEditable}
                                        fluid
                                        selection
                                        options={[
                                            {text:"x+",value:"x+"},
                                            {text:"x-",value:"x-"},
                                            {text:"y+",value:"y+"},
                                            {text:"y-",value:"y-"},
                                            {text:"z+",value:"z+"},
                                            {text:"z-",value:"z-"},
                                        ]}
                                        value={geo.upwardDirection}
                                        onChange={(event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
                                            if (data.value) {
                                                this.setState({
                                                    editCopy: {
                                                        ...geo,
                                                        upwardDirection: data.value.toString()
                                                    }
                                                })
                                            }
                                        }}

                                    />
                                    <Form.Select
                                        label='Specify the "wind" direction'
                                        disabled={!isEditable}
                                        fluid
                                        selection
                                        options={[
                                            {text:"x+",value:"x+"},
                                            {text:"x-",value:"x-"},
                                            {text:"y+",value:"y+"},
                                            {text:"y-",value:"y-"},
                                            {text:"z+",value:"z+"},
                                            {text:"z-",value:"z-"},
                                        ]}
                                        value={geo.windDirection}
                                        onChange={(event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
                                            if (data.value) {
                                                this.setState({
                                                    editCopy: {
                                                        ...geo,
                                                        windDirection: data.value.toString()
                                                    }
                                                })
                                            }
                                        }}
                                    />
                                </Form>
                                <Table collapsing>
                                    <Table.Header fullWidth>
                                        <Table.Row>
                                            <Table.HeaderCell colSpan='2'><h5>
                                                Ratios given in proportion to flare parameters
                                            </h5></Table.HeaderCell>
                                        </Table.Row>
                                        </Table.Header>
                                    <Popup
                                        trigger={
                                            <Table.Row>
                                                <Table.Cell style={{color:"dodgerBlue"}}>
                                                    <b>Wind-direction Ratio</b>
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <NumberInput units='m/m'
                                                                 value={geo.windDirScaleRatio}
                                                                 disabled={!isEditable}
                                                                 onChange={(v, data: InputOnChangeData) => this.setState({
                                                                     editCopy: {
                                                                         ...geo,
                                                                         windDirScaleRatio: parseFloat(data.value)
                                                                     }
                                                                 })}
                                                    />
                                                </Table.Cell>
                                            </Table.Row>
                                        }
                                        hoverable
                                        position={'left center'}
                                    >
                                        <Popup.Content>
                                            <img src={Logo} alt="website" style={{height:300} }/>
                                            <p>
                                                The wind-direction ratio should be given in proportion to the flare base diameter
                                            </p>
                                        </Popup.Content>
                                    </Popup>
                                    <Popup
                                        trigger={
                                            <Table.Row>
                                                <Table.Cell style={{color:"fireBrick"}}>
                                                    <b>Height-direction Ratio</b>
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <NumberInput units='m/m'
                                                                 value={geo.elevatedDirScaleRatio}
                                                                 disabled={!isEditable}
                                                                 onChange={(v, data: InputOnChangeData) => this.setState({
                                                                     editCopy: {
                                                                         ...geo,
                                                                         elevatedDirScaleRatio: parseFloat(data.value)
                                                                     }
                                                                 })}
                                                    />
                                                </Table.Cell>
                                            </Table.Row>
                                        }
                                        hoverable
                                        position={'left center'}
                                    >
                                        <Popup.Content>
                                            <img src={Logo} alt="website" style={{height:300} }/>
                                            <p>
                                                The height-direction Ratio should be given in proportion to the flare's height
                                            </p>
                                        </Popup.Content>
                                    </Popup>
                                    <Popup
                                        trigger={
                                            <Table.Row>
                                                <Table.Cell style={{color:"darkOrange"}}>
                                                    <b>Transverse-direction Ratio</b>
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <NumberInput units='m/m'
                                                                 value={geo.transvereDirScaleRatio}
                                                                 disabled={!isEditable}
                                                                 onChange={(v, data: InputOnChangeData) => this.setState({
                                                                     editCopy: {
                                                                         ...geo,
                                                                         transvereDirScaleRatio: parseFloat(data.value)
                                                                     }
                                                                 })}
                                                    />
                                                </Table.Cell>
                                            </Table.Row>
                                        }
                                        hoverable
                                        position={'left center'}
                                    >
                                        <Popup.Content>
                                            <img src={Logo} alt="website" style={{height:300} }/>
                                            <p>
                                                The transverse-direction ratio should be given in proportion to the flare base diameter
                                            </p>
                                        </Popup.Content>
                                    </Popup>
                                </Table>

                                {/*Add a button bar to switch between the modes*/}
                                <Button.Group>
                                    {isEditable &&
                                        <Button secondary onClick={this.cancelChanges}>Cancel</Button>
                                    }
                                    {isEditable &&
                                        <Button primary onClick={this.saveOnNoNewGeometry}>Save Changes</Button>
                                    }
                                        <Confirm
                                            open = {this.state.open}
                                            header = 'Are you sure that you would like to save?'
                                            content = 'Saving will clear all currently selected inlet faces.'
                                            onCancel = {this.handleCancel}
                                            onConfirm = {this.handleConfirm}
                                        />
                                    {!isEditable &&
                                    <Button  onClick={this.enterEdit}>Edit</Button>
                                    }
                                </Button.Group>
                            </Segment>
                        </Container>
                    </GridColumn>
                    <GridColumn>
                        <Container>
                            <Segment>
                                <Header as='h3'>Geometry Preview</Header>
                                {canShowPreview
                                    ? this.renderStlPicker()
                                    : <Header as='h4'>Select the geometry to display its preview.</Header>
                                }
                            </Segment>
                        </Container>
                    </GridColumn>
                </Grid>
            </Container>
        );
    }
}

/**
 * Map from the global state to things we need here
 * @param state
 * @returns {{authentication: WebAuthentication}}
 */
function mapStateToProps(state:ApplicationState, incomingProps:LinkProps): StateProps {
    return {
        allPossibleGeom: Object.values(state.geometry.geometryList)
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch<any,any, any>, incomingProps:LinkProps): DispatchProps {
    return {
        updateGeometryList:() => dispatch(geometryActions.updateGeometryList())
    };

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GeometryPage);


