import React from 'react';
import { RouteComponentProps} from 'react-router-dom';
import {withRouter} from 'react-router-dom';

import {connect} from "react-redux";
import ApplicationState from "../../../state/ApplicationState";
import {SideMenuItem,sidebarActions} from "rei-react-ui";
import SimulationEditor from "./SimulationEditor";
import {ThunkDispatch} from "redux-thunk";

/**
 * This private route renders what was specified or skips it if the user does not have authority
 * @param Component
 * @param rest
 * @returns {*}
 * @constructor
 */


//Define a private route interface for props
interface LinkProps extends RouteComponentProps<any> {

}


//Define a private route interface for props
interface DispatchProps {
    //And the actions that must be done
    updateSideMenu: (sideMenu:SideMenuItem[]) => any;
}


/**
 * This card shows the animal details
 */
class SimulationRouter extends React.Component<LinkProps&DispatchProps, any> {

    componentWillMount(): void {




    };

    //Check to see if any of the routes are excluded
    render(): React.ReactNode {
        //Get the current url
        const url = this.props.location? this.props.location.pathname: "";

        //Get the simulation id from the the url
        const simIdAny = this.props.match.params.simId;
        //Convert to an int
        const simId = parseInt(simIdAny.toString());

        return (
            <SimulationEditor key={url} basePath='/simulation' simId={simId}/>
        )
    }

}


/**
 * Map from the global state to things we need here
 * @param state
 * @returns {{authentication: WebAuthentication}}
 */
function mapStateToProps(state:ApplicationState,myProps:LinkProps ):LinkProps {
    return {
        ...myProps,
    };
}

function mapDispatchToProps(dispatch:  ThunkDispatch<any,any, any>): DispatchProps {
    return {
        updateSideMenu:(sideMenu:SideMenuItem[]) => dispatch(sidebarActions.setSideBarMenuItems(sideMenu)),
    };

}

const SimulationRouterWithOutRouter = connect (
    mapStateToProps,
    mapDispatchToProps
)(SimulationRouter);



//Wrap with a withRouter so we get the current location
export default withRouter(props => <SimulationRouterWithOutRouter {...props}/>);