import React from "react";
import {RouteComponentProps} from "react-router";
import VisusViewer from 'visus-viewer-component';


//Define the expected props
interface IncomingProps extends RouteComponentProps<any> {

}

export const DataSelectorAndViewer = (props:IncomingProps) => {


    //Now
    const dn:number = props.match.params.dataset;

    const urls:string[] = [
        "https://visus-test.reaction-eng.com/mod_visus?action=read_dataset&dataset=CCVars_0",
        "https://atlantis.sci.utah.edu/mod_visus?action=read_dataset&dataset=3DNeurons12Nov2016_00001",
        "http://localhost:8080/mod_visus?action=read_dataset&dataset=CCVars_0"
    ]

    return (
        <VisusViewer key={dn} dataUrl={urls[dn]}/>
        );
};
