import axios from 'axios';
import {authHeader} from "react-authentication";
import {TemplateData} from "../models/Template";
import { apiConf } from "./apiConf"

export const templatesService = {
    getTemplateList,
    getTemplateInfo
    // getAll,
    // getById,
    // update,
    // delete: _delete
};

// Create a default axios instance with the api
const apiServer = axios.create({
    baseURL: apiConf.serverURL
});

/**
 * get all possible achievements
 * @param username
 * @param password
 * @returns
 */
function getTemplateList() : Promise<TemplateData[]> {

    //Get the headers
    const headers =authHeader();

    //Now make a post request and get a promise back
    const responsePromise = apiServer.get(`/templates/`,  {headers:headers});


    //We need to do some work here
    return responsePromise.then(response =>
        {//When the request returns
            //Get the user
            const data = <TemplateData[]>response.data;

            //Now set the url for each
            data.forEach(tmp =>{
                tmp.ImgUrls = tmp.ImgIds.map(imgId =>{
                    return apiConf.serverURL + "/templates/image/" + imgId;
                })

            })

            //Return just the user
            return data;
        }
    );
}

/**
 * get all possible achievements
 * @param username
 * @param password
 * @returns
 */
function getTemplateInfo(template:TemplateData) : Promise<string> {

    //Get the headers
    const headers =authHeader();

    //Now make a post request and get a promise back
    const responsePromise = apiServer.get(`/templates/${template.id}`,  {headers:headers});


    //We need to do some work here
    return responsePromise.then(response =>
        {//When the request returns
            //Get the user
            const data = <string>response.data;

            //Return just the user
            return data;
        }
    );
}