import {Action} from 'action-alert'
import {SidebarState, sidebarConstants} from "rei-react-ui";


/**
 * The alert reducer maintains a list of alerts
 * @param state
 * @param action
 * @returns {*}
 */
export function sidebar(state:SidebarState = {sideBarMenuOpen: true, sideItemList:[]} , action:Action): SidebarState {


    //Ok, we now know that it is an alert action
    switch (action.type) {
        case sidebarConstants.SIDEBAR_TOGGLE:
            return {
                ...state,
                sideBarMenuOpen:!state.sideBarMenuOpen
            }
        case sidebarConstants.SET_SIDEBAR_ITEMS:
            return {
                ...state,
                sideItemList:action.payload
            }
        default:
            return state
    }
}