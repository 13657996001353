import {  error } from 'action-alert';
import {Action, Dispatch} from 'redux';
import {ThunkAction} from 'redux-thunk';
import {simulationService} from "../services/simulation.service";
import {SimulationData} from "../models/Simulation";


export const simConstants = {
    UPDATE_SIM: 'UPDATE_SIM',
    UPDATE_SIM_LIST: 'UPDATE_SIM_LIST',
    FETCH_SIM_REQUEST: 'FETCH_SIM_REQUEST',
    FETCH_SIM_FAILURE: 'FETCH_SIM_FAILURE',
    SIM_REMOVED: 'SIM_REMOVED'

};

export const simActions = {
    newSimulationFromTemplate,
    getSimulation,
    getSimulations,
    updateSimulation,
    removeCalc,
    duplicateSimulation
};


/**
 * This is the calc action to get all of the calcs
 */
function newSimulationFromTemplate(templateId:string, newSimFunc?: (newSimId: number) => any): ThunkAction<any, any,any, any> {
    //Return a function that will be called by dispatch
    return (dispatch:Dispatch<Action>) => {
        //Dispatch the action of attempting to login
        dispatch({
            type: simConstants.FETCH_SIM_REQUEST,
        });

        //Ask the user service to login
        simulationService.newSimulationFromTemplate(templateId)
            .then(
                //If successful a user will be returned
                sim  => {
                    //dispatch a login success
                    dispatch({
                        type: simConstants.UPDATE_SIM,
                        payload: sim
                    });

                    //Call the call back
                    if(newSimFunc){
                        newSimFunc(sim.id);
                    }

                },
                //If there was an error, dispatch a login failure and alert the user why
                errorResponse => {
                    //dispatch a login failure
                    dispatch({
                        type: simConstants.FETCH_SIM_FAILURE,
                        payload: errorResponse
                    });
                    //Dispatch the error
                    try {
                        dispatch(error(errorResponse.response.data.message));
                    }catch(e){
                        dispatch(error(errorResponse.toString()));

                    }

                }
            );
    };

}


/**
 * This is the calc action to get all of the calcs
 */
function duplicateSimulation(data:SimulationData, newSimFunc?: (newSimId: number) => any): ThunkAction<any, any,any, any> {
    //Return a function that will be called by dispatch
    return (dispatch:Dispatch<Action>) => {
        //Dispatch the action of attempting to login
        dispatch({
            type: simConstants.FETCH_SIM_REQUEST,
        });

        //Ask the user service to login
        simulationService.duplicateSimulation(data.id)
            .then(
                //If successful a user will be returned
                sim  => {
                    //dispatch a login success
                    dispatch({
                        type: simConstants.UPDATE_SIM,
                        payload: sim
                    });

                    //Call the call back
                    if(newSimFunc){
                        newSimFunc(sim.id);
                    }

                },
                //If there was an error, dispatch a login failure and alert the user why
                errorResponse => {
                    //dispatch a login failure
                    dispatch({
                        type: simConstants.FETCH_SIM_FAILURE,
                        payload: errorResponse
                    });
                    //Dispatch the error
                    try {
                        dispatch(error(errorResponse.response.data.message));
                    }catch(e){
                        dispatch(error(errorResponse.toString()));

                    }

                }
            );
    };

}

/**
 * This is the calc action to get all of the calcs
 */
function getSimulation(simulationId:number): ThunkAction<any, any,any, any> {
    //Return a function that will be called by dispatch
    return (dispatch:Dispatch<Action>) => {
        //Dispatch the action of attempting to login
        dispatch({
            type: simConstants.FETCH_SIM_REQUEST,
        });

        //Ask the user service to login
        simulationService.getSimulation(simulationId)
            .then(
                //If successful a user will be returned
                sim  => {
                    //dispatch a login success
                    dispatch({
                        type: simConstants.UPDATE_SIM,
                        payload: sim
                    });


                },
                //If there was an error, dispatch a login failure and alert the user why
                errorResponse => {
                    //dispatch a login failure
                    dispatch({
                        type: simConstants.FETCH_SIM_FAILURE,
                        payload: errorResponse
                    });
                    //Dispatch the error
                    try {
                        dispatch(error(errorResponse.response.data.message));
                    }catch(e){
                        dispatch(error(errorResponse.toString()));

                    }

                }
            );
    };

}

/**
 * This is the calc action to get all of the calcs
 */
function getSimulations(): ThunkAction<any, any,any, any> {
    //Return a function that will be called by dispatch
    return (dispatch:Dispatch<Action>) => {


        //Ask the user service to login
        simulationService.getSimulations()
            .then(
                //If successful a user will be returned
                sim  => {
                    //dispatch a login success
                    dispatch({
                        type: simConstants.UPDATE_SIM_LIST,
                        payload: sim
                    });



                },
                //If there was an error, dispatch a login failure and alert the user why
                errorResponse => {
                    //dispatch a login failure
                    dispatch({
                        type: simConstants.FETCH_SIM_FAILURE,
                        payload: errorResponse
                    });
                    //Dispatch the error
                    try {
                        dispatch(error(errorResponse.response.data.message));
                    }catch(e){
                        dispatch(error(errorResponse.toString()));

                    }

                }
            );
    };

}

/**
 * This is the calc action to get all of the calcs
 */
function updateSimulation(data:SimulationData): ThunkAction<any, any,any, any> {
    //Return a function that will be called by dispatch
    return (dispatch:Dispatch<Action>) => {

        //Dispatch the action of attempting to login
        dispatch({
            type: simConstants.FETCH_SIM_REQUEST,
        });

        //Ask the user service to login
        simulationService.updateSimulation(data)
            .then(
                //If successful a user will be returned
                sim  => {
                    //dispatch a login success
                    dispatch({
                        type: simConstants.UPDATE_SIM,
                        payload: sim
                    });


                },
                //If there was an error, dispatch a login failure and alert the user why
                errorResponse => {
                    //dispatch a login failure
                    dispatch({
                        type: simConstants.FETCH_SIM_FAILURE,
                        payload: errorResponse
                    });
                    //Dispatch the error
                    try {
                        dispatch(error(errorResponse.response.data.message));
                    }catch(e){
                        dispatch(error(errorResponse.toString()));

                    }

                }
            );
    };

}
function removeCalc(sim: SimulationData): ThunkAction<any, any,any, any> {
    //Return a function that will be called by dispatch
    return (dispatch:Dispatch<Action>) => {


        //Ask the user service to login
        simulationService.deleteSimulation(sim.id)
            .then(
                //If successful a user will be returned
                status  => {
                    //dispatch a login success
                    dispatch({
                        type: simConstants.SIM_REMOVED,
                        payload: sim.id
                    });
                },
                //If there was an error, dispatch a login failure and alert the user why
                errorResponse => {
                    //Dispatch the error
                    try {
                        dispatch(error(errorResponse.response.data.message));
                    }catch(e){
                        dispatch(error(errorResponse.toString()));

                    }

                }
            );
    };

}
