import {Action} from 'action-alert'
import GeometryState from "../state/GeometryState";
import {geometryActions, geometryConstants} from "../actions/geometry.actions";
import {GeometryMetaData} from "react-authentication"
import {simConstants} from "../actions/simulations.actions"

/**
 * The alert reducer maintains a list of alerts
 * @param state
 * @param action
 * @returns {*}
 */
export function geometry(state:GeometryState= {geometryList:{}}, action:Action): GeometryState {

    //Ok, we now know that it is an alert action
    switch (action.type) {
        case geometryConstants.GET_GEOMETRY_LIST: {

            //Add the new success to the list
            let geomList = {} as { [id: number]: GeometryMetaData; }

            action.payload.forEach((v: GeometryMetaData) => {
                geomList[v.id] = v
            })

            return {
                ...state,
                geometryList: geomList,
                loading: undefined
            }
        }

        case geometryConstants.GET_GEOMETRY_ITEM: {

            //Add the new success to the list
            let geomList = {...state.geometryList} as { [id: number]: GeometryMetaData; }

            geomList[action.payload.id] = action.payload

            return {
                ...state,
                geometryList: geomList,
                loading: undefined
            }
        }

        case geometryConstants.GET_GEOMETRY_UPLOAD_START: {

            //Add the new success to the list
            return {
                ...state,
                uploadingGeom:true
            }
        }

        case geometryConstants.GET_GEOMETRY_UPLOAD_END: {

            //Add the new success to the list
            return {
                ...state,
                uploadingGeom:false
            }
        }

        case geometryConstants.FETCH_GEOMETRY_DATA_FAILURE:
        case geometryConstants.FETCH_GEOMETRY_DATA_REQUEST: {

            //Set state to loading
            return {
                ...state,
                loading: true
            }
        }

        default:
            return state
    }



}