import React from 'react';

//Import custom styling
import {Image, Card, Header, Icon, Modal, Label, Button, Placeholder} from "semantic-ui-react";
import {GeometryMetaData} from "react-authentication";

import { Link } from 'react-router-dom';


//Define the expected props
interface IncomingProps{
    //Define the props we expect
    geom?: GeometryMetaData;

    //Provide function to delete
    removeSim?: (calc?:GeometryMetaData) => any

    //Provide a hook for another action on click
    onClick?: () => any;
}



const GeometryListItem = (props:IncomingProps) => {

    //Return the list
    if(props.geom) {
        return (
            <Card onClick={props.onClick} key={props.geom.id}>
                {props.removeSim && props.geom.deletable &&
                    <Modal
                        trigger={
                            <Label corner='right' icon='delete'>

                            </Label>
                        }
                        basic
                        size='small'
                    >
                        <Header icon='trash alternate' content='Remove Geometry'/>
                        <Modal.Content>
                            <p>
                                Are you sure you want to remove the geometry.
                            </p>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button color='green' inverted onClick={() => {
                                if (props.removeSim) {
                                    props.removeSim(props.geom)
                                }
                            }}
                            >
                                <Icon name='trash alternate'/>
                            </Button>
                        </Modal.Actions>
                    </Modal>
                }
                {props.geom.blueprints &&
                    <Link to={"geometry/"+props.geom.id.toString()}><Label corner='left' icon='edit' /></Link> //TODO: add callback to route to the geometry item
                }

                {/*Now for the rest of the card*/}
                {props.geom.imgUrl &&
                <Image src={props.geom.imgUrl}/>
                }
                <Card.Content>
                    <Card.Header>{props.geom.name}</Card.Header>
                    <Card.Description>{props.geom.notes}</Card.Description>
                </Card.Content>


            </Card>
        );
    }else{
        return (
            <Card onClick={props.onClick} key={"loading"}>
                <Placeholder>
                    <Placeholder.Image rectangular />
                </Placeholder>
                <Card.Content>
                    <Card.Header><Placeholder.Line length='very short' /></Card.Header>
                    <Card.Description>
                        <Placeholder.Paragraph>
                            <Placeholder.Line length='short' />
                        </Placeholder.Paragraph>
                    </Card.Description>
                </Card.Content>
            </Card>
        );

    }


};

export default GeometryListItem;