import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { alerts } from './alerts.reducer';
import { search } from './search.reducer';

import ApplicationState from '../state/ApplicationState';
import {sidebar} from "./sidebar.reducer";
import {templates} from "./templates.reducer";
import {simulations} from "./simulations.reducer";
import {geometry} from "./geometry.reducer";
import {solves} from "./solves.reducer";

//Define the root reducer
const rootReducer = combineReducers<ApplicationState>({
    authentication,
    alerts,
    search,
    sidebar,
    templates,
    simulations,
    geometry,
    solves
});

export default rootReducer;